import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import {
  LINE_ITEM_SHIPPING_FEE,
  LINE_ITEM_SHIPPING_TRANSFER_FEE,
  propTypes,
} from '../../util/types';

import css from './OrderBreakdown.module.css';
import { PriceDataWithDifferentCurrency } from '../../util/PriceDataWithDifferentCurrency';
import { ensureListing, ensureTransaction } from '../../util/data';
import { deliveryMethodOptions } from '../../marketplace-custom-config';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

const { Money } = sdkTypes;

const LineItemShippingFeeMaybe = props => {
  const {
    isProvider,
    lineItems,
    intl,
    selectedCurrencyExchangeCode,
    selectedCurrency,
    transaction,
  } = props;
  const ensuredTransaction = ensureTransaction(transaction);
  const listing = ensureListing(ensuredTransaction.listing);
  const {
    attributes: {
      publicData: { shippingType },
    },
  } = listing;

  const shippingFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_FEE && !item.reversal
  );

  const shippingTransferFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_TRANSFER_FEE && !item.reversal
  );

  const isPlatformShipping =
    shippingType === deliveryMethodOptions.automaticShipping.value;

  const shippingAmount = shippingFeeLineItem?.lineTotal?.amount || 0;
  const transferFee = isProvider
    ? shippingTransferFeeLineItem?.lineTotal?.amount || 0
    : 0;

  const shippingTotal = new Money(
    shippingAmount + transferFee,
    shippingFeeLineItem?.lineTotal?.currency || config.currency
  );

  const shouldShowShipping = shippingFeeLineItem
    ? isPlatformShipping
      ? convertMoneyToNumber(shippingTotal) > 0
      : true
    : false;

  return shippingFeeLineItem && shouldShowShipping ? (
    <React.Fragment>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.shippingFee" />
        </span>
        <span className={css.itemValue}>
          {
            PriceDataWithDifferentCurrency(
              shippingTotal,
              intl,
              selectedCurrency,
              selectedCurrencyExchangeCode
            )?.formattedPrice
          }
        </span>
      </div>
      {/* {shippingTransferFeeLineItem && shouldShowShippingTransfer ? (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="OrderBreakdown.shippingTransferFee" />
          </span>
          <span className={css.itemValue}>
            {
              PriceDataWithDifferentCurrency(
                shippingTransferFeeLineItem.lineTotal,
                intl,
                selectedCurrency,
                selectedCurrencyExchangeCode
              )?.formattedPrice
            }
          </span>
        </div>
      ) : null} */}
    </React.Fragment>
  ) : null;
};

LineItemShippingFeeMaybe.propTypes = {
  // lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippingFeeMaybe;
