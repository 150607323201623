import { convertUnitToSubUnit, formatMoney, unitDivisor } from './currency';
import { types as sdkTypes } from '../util/sdkLoader';
import config from '../config';
const { Money } = sdkTypes;

export const PriceDataWithDifferentCurrency = (
  price,
  intl,
  currency,
  exchangeCode
) => {
  if (typeof window !== 'undefined') {
    if (price && price.currency === config.currency) {
      if (currency && exchangeCode) {
        const priceAct = (price.amount / unitDivisor(currency)) * exchangeCode;
        const price2digit = parseFloat(priceAct.toFixed(2));
        let priceData = new Money(
          convertUnitToSubUnit(price2digit, unitDivisor(currency)),
          currency
        );
        const formattedPrice = formatMoney(intl, priceData);
        return { formattedPrice, priceTitle: formattedPrice };
      } else {
        const formattedPrice = formatMoney(intl, price);
        return { formattedPrice, priceTitle: formattedPrice };
      }
    } else if (price) {
      return {
        formattedPrice: intl.formatMessage(
          { id: 'ManageListingCard.unsupportedPrice' },
          { currency: price.currency }
        ),
        priceTitle: intl.formatMessage(
          { id: 'ManageListingCard.unsupportedPriceTitle' },
          { currency: price.currency }
        ),
      };
    }
    return {};
  }
};
