import React from 'react';
import css from './TransactionPanel.module.css';

function ShippingAddressSection(props) {
  const { shippingAddress, title = 'Shipping Address' } = props;
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    countryName,
    postal,
  } = shippingAddress;
  return (
    <div className={css.shippingAddressDiv}>
      <h3 className={css.feedHeading}>{title}</h3>
      <div className={css.addressDetailDiv}>
        <span>{shippingAddress?.name}</span>
        <span>{shippingAddress?.phoneNumber}</span>

        <span>{addressLine1}</span>
        {addressLine2 ? <span>{addressLine2}</span> : null}
        <span>
          {city}, {state},
        </span>
        <span>
          {countryName} - {postal}
        </span>
      </div>
    </div>
  );
}

export default ShippingAddressSection;
