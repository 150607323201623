import axios from 'axios';
import { fetchCurrentUser, updateFavCountSuccess } from '../../ducks/user.duck';
import { apiBaseUrl } from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
const { UUID } = sdkTypes;
const RESULT_PAGE_SIZE = 12;

// ============Action Type =================
export const FETCH_FAVOURITE_LISTINGS_REQUEST =
  'app/FavouritesPage/FETCH_FAVOURITE_LISTINGS_REQUEST';
export const FETCH_FAVOURITE_LISTINGS_SUCCESS =
  'app/FavouritesPage/FETCH_FAVOURITE_LISTINGS_SUCCESS';
export const FETCH_FAVOURITE_LISTINGS_ERROR =
  'app/FavouritesPage/FETCH_FAVOURITE_LISTINGS_ERROR';

//=============Reducer=================
const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: null,
  queryListingsError: null,
  currentPageResultIds: [],
};
const resultIds = data => data.data.map(l => l.id);

const favouritesPageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_FAVOURITE_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_FAVOURITE_LISTINGS_SUCCESS:
      // console.log(100000, payload);
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data.data),
        pagination: payload.data.data.meta,
        queryInProgress: false,
      };
    case FETCH_FAVOURITE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, queryInProgress: false, queryListingsError: payload };
    default:
      return { ...state };
  }
};
export default favouritesPageReducer;
//=======ActionCreators==============
export const queryListingsRequest = currentUser => ({
  type: FETCH_FAVOURITE_LISTINGS_REQUEST,
  payload: { currentUser },
});

export const queryListingsSuccess = response => {
  return {
    type: FETCH_FAVOURITE_LISTINGS_SUCCESS,
    payload: { data: response },
  };
};

export const queryListingsError = e => ({
  type: FETCH_FAVOURITE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

//==========fetch from database ===========
export const getFavouriteListings = page => async (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const totalFavCount =
    currentUser?.attributes?.profile?.privateData?.favourites?.length;
  // console.log(123, totalFavCount);
  // console.log(456, currentUser);
  if (!!totalFavCount) {
    dispatch(updateFavCountSuccess(totalFavCount));
  } else {
    dispatch(updateFavCountSuccess(0));
  }
  dispatch(queryListingsRequest(currentUser));
  const { favourites } = currentUser?.attributes?.profile?.privateData;

  if (!favourites) {
    return dispatch(queryListingsError('No Favourite Listing!'));
  }
  const newL = await sdk.listings.query({
    ids: favourites,
    per_page: 12,
    page,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'vairants.default',
      'variants.square-small',
      'variants.square-small2x',
    ],
  });
  // console.log(414, newL);
  // dispatch(addMarketplaceEntities(final));
  // dispatch(queryListingsSuccess(final));
  // return final;
  dispatch(addMarketplaceEntities(newL));
  dispatch(queryListingsSuccess(newL));
  return newL;
};

export const loadData = (param, search) => async (dispatch, getState) => {
  const queryParams = parse(search);
  const queryPage = queryParams.page || 1;
  // const page = queryParams.page || 1;
  // const queryPage = page && Object.keys(page).length === 0 ? 0 : page;
  await dispatch(fetchCurrentUser());
  return dispatch(getFavouriteListings(queryPage));
};
