import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { bool, object, string } from 'prop-types';
import config from '../../config';
import * as validators from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import { FieldTextInput, FieldSelect } from '../../components';

import css from './StripePaymentAddress.module.css';
import classNames from 'classnames';
import Select, { components } from 'react-select';

import { ReactComponent as ArrowDown } from '../../assets/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/ArrowUp.svg';

const countryCodes = getCountryCodes(config.locale);
const countryOPtions = countryCodes.map(country => ({
  key: country.code,
  value: country.code,
  label: country.name,
}));

export const DropdownIndicator = ({ children, ...props }) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? (
      <ArrowUp
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    ) : (
      <ArrowDown
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    )}
  </components.DropdownIndicator>
);

export const ClearIndicator = ({ children, ...props }) => (
  <components.ClearIndicator {...props}>
    <IoMdClose className={css.selectClearBtn} />
  </components.ClearIndicator>
);
export const Menu = ({ children, ...props }) => (
  <components.Menu
    {...props}
    className={classNames(css.menu, {
      // [css.noValueMenu]: props.selectProps.id === 'country' || !props.hasValue,
    })}
  >
    {children}
  </components.Menu>
);
export const getValueContainer = (
  placeholder,
  defaultPlaceholder,
  callback,
  className,
  valueSpanClassName
) => ({ children, ...props }) => {
  if (typeof callback === 'function') callback(props.selectProps.menuIsOpen);

  return (
    <components.ValueContainer {...props}>
      <div
        className={classNames(css.valueContainer, className, {
          [css.multiValueContainer]: props.isMulti,
        })}
      >
        {/* {console.log(666, props.hasValue, props.selectProps.menuIsOpen)} */}
        <span
          className={classNames(css.placeholder, {
            [css.selectedPlaceholder]:
              props.hasValue || props.selectProps.menuIsOpen,
            // [css.selectedSCPlaceholder]:
            //   props.hasValue || props.selectProps.menuIsOpen || values.productSubCategory,

            [css.emptyPlaceholder]: props.isMulti && !props.hasValue,
            [valueSpanClassName]:
              !!valueSpanClassName &&
              (props.hasValue || props.selectProps.menuIsOpen),
          })}
        >
          {!props.hasValue ? defaultPlaceholder : placeholder}
        </span>
        <div
          className={classNames(css.value, {
            [css.nonEmptyValue]: props.hasValue,
            [css.blurredValue]: props.hasValue && props.selectProps.menuIsOpen,
          })}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};
const StripePaymentAddress = props => {
  const {
    className,
    intl,
    disabled,
    form,
    fieldId,
    card,
    touched,
    errors,
    values,
    active,
    fieldName,
  } = props;

  const { country } = fieldName ? values[fieldName] || {} : values;

  const optionalText = intl.formatMessage({
    id: 'StripePaymentAddress.optionalText',
  });

  const addressLine1Label = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Label',
  });
  const addressLine1Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Placeholder',
  });
  const addressLine1Required = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.addressLine1Required',
    })
  );

  const addressLine2Label = intl.formatMessage(
    { id: 'StripePaymentAddress.addressLine2Label' },
    { optionalText: optionalText }
  );

  const addressLine2Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({
    id: 'StripePaymentAddress.postalCodeLabel',
  });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({
    id: 'StripePaymentAddress.cityLabel',
  });
  const cityPlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.cityPlaceholder',
  });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.cityRequired',
    })
  );

  const stateLabel = intl.formatMessage(
    { id: 'StripePaymentAddress.stateLabel' },
    { optionalText: optionalText }
  );
  const statePlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.statePlaceholder',
  });

  const countryLabel = intl.formatMessage({
    id: 'StripePaymentAddress.countryLabel',
  });
  const countryPlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.countryPlaceholder',
  });
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.countryRequired',
    })
  );

  const handleOnChange = event => {
    const value = event.target.value;
    const prefix = fieldName ? `${fieldName}.` : '';
    const postalLabel = `${prefix}postal`;
    form.change(postalLabel, value);
    if (card && card.update && typeof card.update === 'function') {
      card.update({ value: { postalCode: value } });
    }
  };
  const handleCountryChange = country => {
    if (!country) return;
    const prefix = fieldName ? `${fieldName}.` : '';
    const countryNameLabel = `${prefix}countryName`;
    const countryLabel = `${prefix}country`;
    form.change(countryNameLabel, country.label);
    form.change(countryLabel, country.value);
  };

  const isInvalidInput = name => {
    const prefixedName = fieldName ? `${fieldName}.${name}` : name;
    const modifiedErrs = fieldName ? errors[fieldName] || {} : errors;
    return touched[prefixedName] && !!modifiedErrs[name];
  };

  const isNonEmptyInput = name => {
    const modifiedValues = fieldName ? values[fieldName] || {} : values;
    const modifiedName = fieldName ? `${fieldName}.${name}` : name;
    return !!modifiedValues[name] || active === modifiedName;
  };

  // Use tha language set in config.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(config.locale);
  return (
    <div className={className ? className : css.root}>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.addressLine1`}
          name={fieldName ? `${fieldName}.addressLine1` : 'addressLine1'}
          disabled={disabled}
          // className={css.field}
          className={classNames(css.inputs, {
            [css.invalidInputs]: isInvalidInput('addressLine1'),
            [css.fnNonEmptyInputs]: isNonEmptyInput('addressLine1'),
          })}
          type="text"
          autoComplete="billing address-line1"
          label={addressLine1Label}
          // placeholder={addressLine1Placeholder}
          validate={addressLine1Required}
          onUnmount={() => form.change('addressLine1', undefined)}
        />

        <FieldTextInput
          id={`${fieldId}.addressLine2`}
          name={fieldName ? `${fieldName}.addressLine2` : 'addressLine2'}
          disabled={disabled}
          // className={css.field}
          className={classNames(css.inputs, {
            [css.invalidInputs]: isInvalidInput('addressLine2'),
            [css.fnNonEmptyInputs]: isNonEmptyInput('addressLine2'),
          })}
          type="text"
          autoComplete="billing address-line2"
          label={addressLine2Label}
          // placeholder={addressLine2Placeholder}
          onUnmount={() => form.change('addressLine2', undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.postalCode`}
          name={fieldName ? `${fieldName}.postal` : 'postal'}
          disabled={disabled}
          // className={css.field}
          className={classNames(css.inputs, {
            [css.invalidInputs]: isInvalidInput('postal'),
            [css.fnNonEmptyInputs]: isNonEmptyInput('postal'),
          })}
          type="text"
          autoComplete="billing postal-code"
          label={postalCodeLabel}
          // placeholder={postalCodePlaceholder}
          validate={postalCodeRequired}
          onUnmount={() => form.change('postal', undefined)}
          onChange={event => handleOnChange(event)}
        />

        <FieldTextInput
          id={`${fieldId}.city`}
          name={fieldName ? `${fieldName}.city` : 'city'}
          disabled={disabled}
          // className={css.field}
          className={classNames(css.inputs, {
            [css.invalidInputs]: isInvalidInput('city'),
            [css.fnNonEmptyInputs]: isNonEmptyInput('city'),
          })}
          type="text"
          autoComplete="billing address-level2"
          label={cityLabel}
          // placeholder={cityPlaceholder}
          validate={cityRequired}
          onUnmount={() => form.change('city', undefined)}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.state`}
          name={fieldName ? `${fieldName}.state` : 'state'}
          disabled={disabled}
          // className={css.field}
          className={classNames(css.inputs, {
            [css.invalidInputs]: isInvalidInput('state'),
            [css.fnNonEmptyInputs]: isNonEmptyInput('state'),
          })}
          type="text"
          autoComplete="billing address-level1"
          label={stateLabel}
          // placeholder={statePlaceholder}
          onUnmount={() => form.change('state', undefined)}
        />
        <Select
          className={classNames(css.categoryReactSelect, css.cateSelect, {})}
          // menuIsOpen={true}
          isSearchable={true}
          name={fieldName ? `${fieldName}.country` : 'country'}
          id={fieldId ? `${fieldId}.country` : 'country'}
          options={countryOPtions}
          placeholder={null}
          hideSelectedOptions={true}
          components={{
            DropdownIndicator,
            Menu,
            ValueContainer: getValueContainer(
              countryPlaceholder,
              countryPlaceholder,
              () => {}
              // css.catSelectedPlaceholder
            ),
          }}
          validate={countryRequired}
          onChange={handleCountryChange}
          value={countryOPtions.find(optn => optn.value === country)}
          // value={countryName}
        />
        {/* <FieldSelect
          id={`${fieldId}.country`}
          name="country"
          disabled={disabled}
          className={css.field}
          label={countryLabel}
          validate={countryRequired}
        >
          <option disabled value="">
            {countryPlaceholder}
          </option>
          {countryCodes.map(country => {
            return (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            );
          })}
        </FieldSelect> */}
      </div>
    </div>
  );
};
StripePaymentAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

StripePaymentAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,

  // from injectIntl
  intl: intlShape.isRequired,
  fieldName: string,
};

export default StripePaymentAddress;
