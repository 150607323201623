import axios from 'axios';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { apiBaseUrl } from '../../util/api';
import { storableError } from '../../util/errors';
import { showListing } from '../ListingPage/ListingPage.duck';
import { types } from '../../util/sdkLoader';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  addMarketplaceEntities,
  getListingsById,
} from '../../ducks/marketplaceData.duck';
import { ensureCurrentUser } from '../../util/data';
import config from '../../config';
import { parse } from '../../util/urlHelpers';
import {
  deliveryMethodOptions,
  shippingByOptions,
  shippingTypes,
} from '../../marketplace-custom-config';

const { UUID } = types;

const listingParams = {
  include: ['images', 'author', 'author.profileImage', 'currentStock'],
  'fields.listing': [
    'price',
    'title',
    'publicData',
    'description',
    'state',
    'geolocation',
  ],
  'fields.user': [
    'profile.abbreviatedName',
    'profile.displayName',
    'profile.publicData',
  ],
  'fields.image': [
    'variants.default',
    'variants.square-small',
    'variants.square-small2x',
  ],
  'limit.images': 1,
};

const defaulCartTotal = {
  itemsTotalCost: { amount: 0, currency: config.currency },
  shippingCost: { amount: 0, currency: config.currency },
};
const validDeliveyMethods = [
  deliveryMethodOptions.shipping.value,
  deliveryMethodOptions.pickup.value,
];

const validShippingOption = [
  shippingTypes.domestic.value,
  shippingTypes.international.value,
];

const validShippingByOption = [
  shippingByOptions.manualShipping.value,
  shippingByOptions.automaticShipping.value,
];

export const FETCH_CART_LISTING_REQUEST = 'app/cart/FETCH_CART_LISTING_REQUEST';
export const FETCH_CART_LISTING_SUCCESS = 'app/cart/FETCH_CART_LISTING_SUCCESS';
export const FETCH_CART_LISTING_ERROR = 'app/cart/FETCH_CART_LISTING_ERROR';

export const ADD_CART_REQUEST = 'app/cart/ADD_CART_REQUEST';
export const ADD_CART_SUCCESS = 'app/cart/ADD_CART_SUCCESS';
export const ADD_CART_ERROR = 'app/cart/ADD_CART_ERROR';

export const REMOVE_CARTS_REQUEST = 'app/cart/REMOVE_CARTS_REQUEST';
export const REMOVE_CARTS_SUCCESS = 'app/cart/REMOVE_CARTS_SUCCESS';
export const REMOVE_CARTS_ERROR = 'app/cart/REMOVE_CARTS_ERROR';

export const UPDATE_CART_COUNT_SUCCESS = 'app/cart/UPDATE_CART_COUNT_SUCCESS';

export const REMOVE_FROM_CART_REQUEST = 'app/cart/REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'app/cart/REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_WITHOUT_UPDATE_SUCCESS =
  'app/cart/REMOVE_FROM_CART_WITHOUT_UPDATE_SUCCESS';
export const REMOVE_FROM_CART_ERROR = 'app/cart/REMOVE_FROM_CART_ERROR';

export const FETCH_CART_TOTAL_REQUEST = 'app/cart/FETCH_CART_TOTAL_REQUEST';
export const FETCH_CART_TOTAL_SUCCESS = 'app/cart/FETCH_CART_TOTAL_SUCCESS';
export const FETCH_CART_TOTAL_ERROR = 'app/cart/FETCH_CART_TOTAL_ERROR';

export const INITIATE_CART_PAGE_LOAD = 'app/cart/INITIATE_CART_PAGE_LOAD';
export const DEINITIATE_CART_PAGE_LOAD = 'app/cart/DEINITIATE_CART_PAGE_LOAD';

export const SHIPPING_TYPE_CHANGE_REQUEST =
  'app/cart/SHIPPING_TYPE_CHANGE_REQUEST';
export const SHIPPING_TYPE_CHANGE_SUCCESS =
  'app/cart/SHIPPING_TYPE_CHANGE_SUCCESS';
export const SHIPPING_TYPE_CHANGE_ERROR = 'app/cart/SHIPPING_TYPE_CHANGE_ERROR';

export const DELIVERY_METHOD_CHANGE_REQUEST =
  'app/cart/DELIVERY_METHOD_CHANGE_REQUEST';
export const DELIVERY_METHOD_CHANGE_SUCCESS =
  'app/cart/DELIVERY_METHOD_CHANGE_SUCCESS';
export const DELIVERY_METHOD_CHANGE_ERROR =
  'app/cart/DELIVERY_METHOD_CHANGE_ERROR';

export const SHIPPING_OPTIONS_CHANGE_REQUEST =
  'app/cart/SHIPPING_OPTIONS_CHANGE_REQUEST';
export const SHIPPING_OPTIONS_CHANGE_SUCCESS =
  'app/cart/SHIPPING_OPTIONS_CHANGE_SUCCESS';
export const SHIPPING_OPTIONS_CHANGE_ERROR =
  'app/cart/SHIPPING_OPTIONS_CHANGE_ERROR';

const initailState = {
  cartFetchInProgress: true,
  cartList: [],
  cartAuthor: null,
  cartFetchError: null,
  lastPageId: null,
  cartAddInProgress: false,
  cartAddError: null,
  cartCount: 0,
  cartRemoveInProgress: false,
  cartRemoveError: null,
  cartTotal: { ...defaulCartTotal },
  cartTotalFetchError: null,
  cartTotalFetchInProgress: true,
  cartPageLoadInitiated: false,
  removeCartsInProgress: false,
  removeCartsError: null,
  cartDeliveryMethod: deliveryMethodOptions.pickup.value,
  cartShippingType: shippingTypes.domestic.value,
  cartShippingOption: shippingByOptions.manualShipping.value,
  cartShippingTypeChangeInProgress: false,
  cartShippingTypeChangeError: null,
  cartDeliveryMethodChangeInProgress: false,
  cartDeliveryMethodChangeError: null,
  cartShippingOptionsChangeInProgress: false,
  cartShippingOptionsChangeError: null,
};

export default function(state = initailState, action = {}) {
  const { type, payload } = action;
  let oldCartList, newCartList, cartAuthor;
  switch (type) {
    case FETCH_CART_LISTING_REQUEST:
      return {
        ...state,
        cartFetchInProgress: true,
        cartFetchError: null,
        cartList: payload ? [...state.cartList] : [],
      };

    case FETCH_CART_LISTING_SUCCESS:
      cartAuthor =
        payload.data.length > 0
          ? payload.data[0].listing.author
          : state.cartAuthor;
      newCartList = payload.isUpdate ? [...state.cartList] : [];
      newCartList = [
        ...newCartList,
        ...payload.data.map(d => {
          const { listing, ...rest } = d;
          const { author, ...restListing } = listing;
          return { ...rest, listing: { ...restListing } };
        }),
      ];
      return {
        ...state,
        cartList: newCartList,
        cartFetchInProgress: false,
        lastPageId: payload.hasMore
          ? payload.data[payload.data.length - 1]?.mId
          : null,
        cartAuthor,
      };

    case FETCH_CART_LISTING_ERROR:
      return {
        ...state,
        cartFetchInProgress: false,
        cartFetchError: payload,
      };

    case ADD_CART_REQUEST:
      return {
        ...state,
        cartAddInProgress: true,
        cartAddError: null,
      };

    case ADD_CART_SUCCESS:
      return {
        ...state,
        cartAddInProgress: false,
        cartAddError: null,
      };

    case ADD_CART_ERROR:
      return {
        ...state,
        cartAddInProgress: false,
        cartAddError: payload,
      };

    case REMOVE_CARTS_REQUEST:
      return {
        ...state,
        removeCartsInProgress: true,
        removeCartsError: null,
      };

    case REMOVE_CARTS_SUCCESS:
      return {
        ...state,
        removeCartsInProgress: false,
        cartList: [],
        cartAuthor: null,
        lastPageId: null,
        cartCount: 0,
        cartTotal: { ...defaulCartTotal },
        cartDeliveryMethod: deliveryMethodOptions.pickup.value,
        cartShippingType: shippingTypes.domestic.value,
      };

    case REMOVE_CARTS_ERROR:
      return {
        ...state,
        removeCartsInProgress: false,
        removeCartsError: payload,
      };

    case UPDATE_CART_COUNT_SUCCESS:
      return {
        ...state,
        cartCount: payload,
      };

    case REMOVE_FROM_CART_REQUEST:
      return {
        ...state,
        cartRemoveInProgress: true,
        cartRemoveError: null,
      };

    case REMOVE_FROM_CART_SUCCESS:
      oldCartList = state.cartList;
      newCartList = oldCartList.filter(cart => cart.id !== payload.id);
      return {
        ...state,
        cartRemoveInProgress: false,
        cartRemoveError: null,
        cartList: newCartList,
        lastPageId: newCartList[newCartList.length - 1]?.mId,
        cartCount: payload.count,
      };

    case REMOVE_FROM_CART_WITHOUT_UPDATE_SUCCESS:
      return {
        ...state,
        cartRemoveInProgress: false,
        cartRemoveError: null,
      };

    case REMOVE_FROM_CART_ERROR:
      return {
        ...state,
        cartRemoveInProgress: false,
        cartRemoveError: payload,
      };

    case FETCH_CART_TOTAL_REQUEST:
      return {
        ...state,
        cartTotalFetchInProgress: true,
        cartTotalFetchError: null,
      };

    case FETCH_CART_TOTAL_SUCCESS:
      return {
        ...state,
        cartTotalFetchInProgress: false,
        cartTotalFetchError: null,
        cartTotal: payload.total,
        ...(validDeliveyMethods.includes(payload.cartDeliveryMethod)
          ? { cartDeliveryMethod: payload.cartDeliveryMethod }
          : { cartDeliveryMethod: deliveryMethodOptions.pickup.value }),
        ...(validShippingOption.includes(payload.cartShippingType)
          ? { cartShippingType: payload.cartShippingType }
          : { cartShippingType: shippingTypes.domestic.value }),
        ...(validShippingByOption.includes(payload.metadata?.shippingOption)
          ? { cartShippingOption: payload.metadata.shippingOption }
          : { cartShippingOption: shippingByOptions.manualShipping.value }),
      };

    case FETCH_CART_TOTAL_ERROR:
      return {
        ...state,
        cartTotalFetchInProgress: false,
        cartTotalFetchError: payload,
        cartTotal: { ...defaulCartTotal },
      };

    case INITIATE_CART_PAGE_LOAD:
      return {
        ...state,
        cartPageLoadInitiated: true,
      };

    case DEINITIATE_CART_PAGE_LOAD:
      return {
        ...state,
        cartPageLoadInitiated: false,
      };

    case SHIPPING_TYPE_CHANGE_REQUEST:
      return {
        ...state,
        cartShippingTypeChangeInProgress: true,
        cartShippingTypeChangeError: null,
      };

    case SHIPPING_TYPE_CHANGE_SUCCESS:
      return {
        ...state,
        cartShippingTypeChangeInProgress: false,
        cartTotal: payload.total,
        cartShippingType: payload.shippingType,
      };

    case SHIPPING_TYPE_CHANGE_ERROR:
      return {
        ...state,
        cartShippingTypeChangeInProgress: false,
        cartShippingTypeChangeError: payload,
      };

    case DELIVERY_METHOD_CHANGE_REQUEST:
      return {
        ...state,
        cartDeliveryMethodChangeInProgress: true,
        cartDeliveryMethodChangeError: null,
      };
    case DELIVERY_METHOD_CHANGE_SUCCESS:
      return {
        ...state,
        cartDeliveryMethodChangeInProgress: false,
        cartTotal: payload.total,
        cartDeliveryMethod: payload.deliveryMethod,
        cartShippingOption: payload.cartShippingOption,
      };
    case DELIVERY_METHOD_CHANGE_ERROR:
      return {
        ...state,
        cartDeliveryMethodChangeInProgress: false,
        cartDeliveryMethodChangeError: payload,
      };

    case SHIPPING_OPTIONS_CHANGE_REQUEST:
      return {
        ...state,
        cartShippingOptionsChangeInProgress: true,
        cartShippingOptionsChangeError: null,
      };
    case SHIPPING_OPTIONS_CHANGE_SUCCESS:
      return {
        ...state,
        cartShippingOptionsChangeInProgress: false,
        cartTotal: payload.total,
        cartShippingOption: payload.shippingOption,
      };
    case SHIPPING_OPTIONS_CHANGE_ERROR:
      return {
        ...state,
        cartShippingOptionsChangeInProgress: false,
        cartShippingOptionsChangeError: payload,
      };

    default:
      return state;
  }
}

//=========== Action creators =================================//

export function fetchCartListingRequest(payload) {
  return {
    type: FETCH_CART_LISTING_REQUEST,
    payload,
  };
}

export function fetchCartListingSuccess(payload, hasMore, isUpdate) {
  return {
    type: FETCH_CART_LISTING_SUCCESS,
    payload: { data: payload, isUpdate: isUpdate, hasMore },
  };
}

export function fetchCartListingError(payload) {
  return {
    type: FETCH_CART_LISTING_ERROR,
    payload,
  };
}

export function addCartRequest() {
  return {
    type: ADD_CART_REQUEST,
  };
}

export function addCartSuccess() {
  return {
    type: ADD_CART_SUCCESS,
  };
}

export function addCartError(payload) {
  return {
    type: ADD_CART_ERROR,
    payload,
  };
}

export function removeCartsRequest() {
  return {
    type: REMOVE_CARTS_REQUEST,
  };
}

export function removeCartsSuccess() {
  return {
    type: REMOVE_CARTS_SUCCESS,
  };
}

export function removeCartsError(payload) {
  return {
    type: REMOVE_CARTS_ERROR,
    payload,
  };
}

export function updateCartCountSuccess(payload) {
  return {
    type: UPDATE_CART_COUNT_SUCCESS,
    payload,
  };
}

export function removeFromCartRequest() {
  return {
    type: REMOVE_FROM_CART_REQUEST,
  };
}

export function removeFromCartSuccess(payload) {
  return {
    type: REMOVE_FROM_CART_SUCCESS,
    payload,
  };
}

export function removeFromCartWithoutUpdateSuccess() {
  return {
    type: REMOVE_FROM_CART_WITHOUT_UPDATE_SUCCESS,
  };
}

export function removeFromCartError(payload) {
  return {
    type: REMOVE_FROM_CART_ERROR,
    payload,
  };
}

export function fetchCartTotalRequest() {
  return {
    type: FETCH_CART_TOTAL_REQUEST,
  };
}

export function fetchCartTotalSuccess(payload) {
  return {
    type: FETCH_CART_TOTAL_SUCCESS,
    payload,
  };
}

export function fetchCartTotalError(payload) {
  return {
    type: FETCH_CART_TOTAL_ERROR,
    payload,
  };
}

export function initiateCartPageLoad() {
  return { type: INITIATE_CART_PAGE_LOAD };
}

export function deinitiateCartPageLoad() {
  return { type: DEINITIATE_CART_PAGE_LOAD };
}

export function cartShippingTypeChangeRequest() {
  return { type: SHIPPING_TYPE_CHANGE_REQUEST };
}

export function cartShippingTypeChangeSuccess(payload) {
  return { type: SHIPPING_TYPE_CHANGE_SUCCESS, payload };
}

export function cartShippingTypeChangeError(payload) {
  return { type: SHIPPING_TYPE_CHANGE_ERROR, payload };
}

export function cartDeliveryMethodChangeRequest() {
  return { type: DELIVERY_METHOD_CHANGE_REQUEST };
}

export function cartDeliveryMethodChangeSuccess(payload) {
  return { type: DELIVERY_METHOD_CHANGE_SUCCESS, payload };
}

export function cartDeliveryMethodChangeError(payload) {
  return { type: DELIVERY_METHOD_CHANGE_ERROR, payload };
}

export function cartShippingOptionChangeRequest() {
  return { type: SHIPPING_OPTIONS_CHANGE_REQUEST };
}

export function cartShippingOptionChangeSuccess(payload) {
  return { type: SHIPPING_OPTIONS_CHANGE_SUCCESS, payload };
}

export function cartShippingOptionChangeError(payload) {
  return { type: SHIPPING_OPTIONS_CHANGE_ERROR, payload };
}

//=========================thunks =========================//

export const fetchCartListing = (isUpdate = true) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchCartListingRequest(isUpdate));
  let apiData,
    hasMoreCarts,
    listingsId = [];
  const url = `${apiBaseUrl()}/api/cart`;
  const { currentUser } = getState().user;
  const { lastPageId } = getState().CartPage;
  if (!currentUser || !currentUser.id) {
    dispatch(fetchCartListingSuccess([], false));
    return Promise.resolve();
  }
  const {
    id: { uuid: userId },
  } = currentUser;
  return dispatch(fetchUserCartCount())
    .then(_ => axios.post(url, { userId, page: lastPageId }))
    .then(resp => {
      const {
        data: { carts, hasMore },
      } = resp;

      hasMoreCarts = hasMore;
      apiData = carts.reduce((pre, curnt) => {
        if (typeof pre[curnt.listingId] === 'undefined') {
          pre[curnt.listingId] = {};
        }
        pre[curnt.listingId][curnt.id] = curnt;
        return pre;
      }, {});

      listingsId = Object.keys(apiData).map(id => new UUID(id));
      return Promise.all(
        listingsId.map(id => sdk.listings.show({ id, ...listingParams }))
      );
      //use above instead of sdk.listings.query as it does not return closed listing and
      // in UI i also have to show closed listings.
    })
    .then(resp => {
      const initialRespData = {
        status: 200,
        statusText: '',
        data: { data: [], included: [] },
      };

      const modifiedResp = resp.reduce((pre, curnt) => {
        const { data, included = [] } = curnt.data;
        pre.data.data.push(data);
        pre.data.included.push(...included);
        return pre;
      }, initialRespData);

      dispatch(addMarketplaceEntities(modifiedResp));
    })
    .then(_ => {
      const listingDetails = getListingsById(getState(), listingsId);
      const updatedData = listingDetails.reduce((pre, listing) => {
        const listingId = listing.id.uuid;
        const cartDetails = apiData[listingId];
        const carDetailsValues = Object.values(cartDetails).map(value => ({
          ...value,
          listing,
        }));
        pre.push(...carDetailsValues);
        return pre;
      }, []);
      dispatch(fetchCartListingSuccess(updatedData, hasMoreCarts, isUpdate));
      return Promise.resolve();
    })
    .catch(err => {
      console.error(err);
      dispatch(fetchCartListingError(storableError(err)));
      return Promise.reject(err);
    });
};

export const fetchCartTotal = () => (dispatch, getState, sdk) => {
  dispatch(fetchCartTotalRequest());
  const { currentUser } = getState().user;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const userId = ensuredCurrentUser.id?.uuid;
  const url = `${apiBaseUrl()}/api/cart/total?id=${userId}`;
  return axios
    .get(url)
    .then(resp => {
      if (resp.data.metadata && !resp.data.metadata.shippingOption) {
        resp.data.metadata.shippingOption =
          ensuredCurrentUser.attributes.profile.protectedData
            ?.cartShippingOption || deliveryMethodOptions.manualShipping.value;
      }
      dispatch(fetchCartTotalSuccess(resp.data));
    })
    .catch(err => {
      return dispatch(fetchCartTotalError(storableError(err)));
    });
};

export const fetchUserCartCount = () => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const userId = ensuredCurrentUser.id?.uuid;
  const url = `${apiBaseUrl()}/api/cart/count?id=${userId}`;
  return axios
    .get(url)
    .then(resp => dispatch(updateCartCountSuccess(parseInt(resp.data))))
    .catch(err => dispatch(updateCartCountSuccess(0)));
};

export const addCart = (cart, isFromCartPage = false) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(addCartRequest());
  const url = `${apiBaseUrl()}/api/cart/add`;
  const listingId = new UUID(cart.listingId);
  const updateUserData = {
    protectedData: {
      cartProviderId: cart.providerId,
      cartShippingOption:
        cart?.metadata?.shippingOption ||
        deliveryMethodOptions.manualShipping.value,
    },
  };

  return axios
    .post(url, cart)
    .then(_ => {
      const promiseArr = [
        dispatch(updateProfile(updateUserData)),
        dispatch(fetchUserCartCount()),
      ];
      if (isFromCartPage) {
        promiseArr.push(dispatch(fetchCartTotal()));
      } else {
        promiseArr.push(dispatch(showListing(listingId)));
      }

      return Promise.all(promiseArr);
    })
    .then(_ => dispatch(addCartSuccess()))
    .catch(err => {
      dispatch(addCartError(storableError(err)));
      return Promise.reject(err);
    });
};

export const emptyUserCart = userId => (dispatch, getState, sdk) => {
  if (!userId) return;
  dispatch(removeCartsRequest());
  const url = `${apiBaseUrl()}/api/cart/remove/all?id=${userId}`;
  const updateData = { protectedData: { cartProviderId: null } };

  return axios
    .get(url)
    .then(_ => dispatch(updateProfile(updateData)))
    .then(_ => dispatch(removeCartsSuccess()))
    .catch(err => {
      dispatch(removeCartsError(storableError(err)));
      return Promise.reject(err);
    });
};

export const changeShippingType = (userId, shippingType) => (
  dispatch,
  getState,
  sdk
) => {
  if (!userId) return;
  dispatch(cartShippingTypeChangeRequest());
  const url = `${apiBaseUrl()}/api/cart/updatest`;
  const body = { userId, shippingType };
  return axios
    .post(url, body)
    .then(resp => {
      dispatch(
        cartShippingTypeChangeSuccess({ total: resp.data, shippingType })
      );
      return Promise.resolve('success');
    })
    .catch(err => {
      console.error(err);
      dispatch(cartShippingTypeChangeError(storableError(err)));
      return Promise.reject(err);
    });
};

export const changeDeliveryMethod = (userId, deliveryMethod, metadata) => (
  dispatch,
  getState,
  sdk
) => {
  if (!userId) return;
  dispatch(cartDeliveryMethodChangeRequest());
  const url = `${apiBaseUrl()}/api/cart/updatedm`;
  const body = { userId, deliveryMethod, metadata };
  const { currentUser } = getState().user;

  return axios
    .post(url, body)
    .then(resp => {
      dispatch(
        cartDeliveryMethodChangeSuccess({
          total: resp.data,
          deliveryMethod,
          metadata,
          cartShippingOption:
            currentUser.attributes.profile.protectedData?.cartShippingOption ||
            deliveryMethodOptions.manualShipping.value,
        })
      );
      return Promise.resolve('success');
    })
    .catch(err => {
      console.error(err);
      dispatch(cartDeliveryMethodChangeError(storableError(err)));
      return Promise.reject(err);
    });
};

export const changeShippingOption = (userId, shippingOption, metadata) => (
  dispatch,
  getState,
  sdk
) => {
  if (!userId) return;
  dispatch(cartShippingOptionChangeRequest());
  const url = `${apiBaseUrl()}/api/cart/updateso`;

  const body = { userId, shippingOption, metadata };
  return axios
    .post(url, body)
    .then(resp => {
      dispatch(
        cartShippingOptionChangeSuccess({
          total: resp.data,
          shippingOption,
          metadata,
        })
      );
      return Promise.resolve('success');
    })
    .catch(err => {
      console.error(err);
      dispatch(cartShippingOptionChangeError(storableError(err)));
      return Promise.reject(err);
    });
};

export const removeFromCart = cart => (dispatch, getState, sdk) => {
  dispatch(removeFromCartRequest());
  let cartCount;
  const url = `${apiBaseUrl()}/api/cart/remove`;
  const { id, ...rest } = cart;
  return axios
    .post(url, rest)
    .then(resp => {
      cartCount = parseInt(resp.data);

      if (cartCount > 0) return;
      const updateUserData = { protectedData: { cartProviderId: null } };
      return dispatch(updateProfile(updateUserData));
    })
    .then(_ => dispatch(fetchCartTotal()))
    .then(_ => {
      dispatch(removeFromCartSuccess({ id, count: cartCount }));
      return Promise.resolve(id);
    })
    .catch(err => {
      console.error(err);
      dispatch(removeFromCartError(storableError(err)));
      return Promise.reject(err);
    });
};

export const moveToFavorite = cart => (dispatch, getState, sdk) => {
  dispatch(removeFromCartRequest());
  const { currentUser } = getState().user;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  if (!ensuredCurrentUser.id) {
    dispatch(removeFromCartWithoutUpdateSuccess());
    return;
  }
  const id = cart.id;
  const {
    attributes: {
      profile: {
        privateData: { favourites = [] },
      },
    },
  } = ensuredCurrentUser;
  favourites.push(cart.listingId);
  const uniqueFavorites = [...new Set(favourites)];
  const updateUserData = { privateData: { favourites: uniqueFavorites } };
  return dispatch(updateProfile(updateUserData))
    .then(() => {
      dispatch(removeFromCart(cart));
      return Promise.resolve(id);
    })
    .catch(err => {
      console.error(err);
      dispatch(removeFromCartError(storableError(err)));
      return Promise.reject(err);
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { cartPageLoadInitiated } = getState().CartPage;
  if (cartPageLoadInitiated) return Promise.resolve();
  dispatch(initiateCartPageLoad());
  dispatch(fetchCartListingRequest(false));
  return dispatch(fetchCurrentUser()).then(
    () =>
      Promise.all[
        (dispatch(fetchCartListing(false)), dispatch(fetchCartTotal()))
      ]
  );
};
