import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userFetchInProgress: false,
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsInProgress: false,
  queryListingsError: null,
  reviews: [],
  queryReviewsInProgress: false,
  queryReviewsError: null,
  paginationforListing: null,
  paginationforReviews: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return {
        ...state,
        userShowError: null,
        userId: payload.userId,
        userFetchInProgress: true,
      };
    case SHOW_USER_SUCCESS:
      return { ...state, userFetchInProgress: false };
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload, userFetchInProgress: false };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsInProgress: true,

        // Empty listings only when user id changes
        userListingRefs:
          payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        queryListingsInProgress: false,

        userListingRefs: payload.listingRefs,
        paginationforListing: payload.listingPaginationData,
      };
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        queryListingsInProgress: false,
        userListingRefs: [],
        queryListingsError: payload,
      };
    case QUERY_REVIEWS_REQUEST:
      return {
        ...state,
        queryReviewsInProgress: true,
        queryReviewsError: null,
      };
    case QUERY_REVIEWS_SUCCESS:
      return {
        ...state,
        queryReviewsInProgress: false,
        reviews: payload.reviews,
        paginationforReviews: payload.reviewPaginationData,
      };
    case QUERY_REVIEWS_ERROR:
      return {
        ...state,
        queryReviewsInProgress: false,
        reviews: [],
        queryReviewsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = (listingRefs, listingPaginationData) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs, listingPaginationData },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = (reviews, reviewPaginationData) => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: { reviews, reviewPaginationData },
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = (userId, queryPage) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(queryListingsRequest(userId));
  return sdk.listings
    .query({
      per_page: 20,
      page: queryPage,
      author_id: userId,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({
        id,
        type,
      }));

      const listingPaginationData = response.data.meta;
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs, listingPaginationData));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = (userId, queryPage) => (
  dispatch,
  getState,
  sdk
) => {
  sdk.reviews
    .query({
      per_page: 10,
      page: queryPage,
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',
        'variants.facebook',
        'variants.twitter',
      ],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      const reviewPaginationData = response.data.meta;

      dispatch(queryReviewsSuccess(reviews, reviewPaginationData));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',
        'variants.facebook',
        'variants.twitter',
      ],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);
  const queryParams = parse(search);
  const queryPage = queryParams.page || 1;
  const tab = queryParams.tab || 'listings';

  const onlyFilterValues = {
    listings: 'listings',
    reviews: 'reviews',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for ProfilePage: ${tab}`));
  }
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId, tab === 'listings' ? queryPage : 1)),
    dispatch(queryUserReviews(userId, tab === 'reviews' ? queryPage : 1)),
  ]);
};

export const ProfilePageByUserName = (params, search) => (
  dispatch,
  getState,
  sdk
) => {
  const queryParams = parse(search);
  const queryPage = queryParams.page || 1;
  const tab = queryParams.tab || 'listings';

  const slug = params.slug;
  const onlyFilterValues = {
    listings: 'listings',
    reviews: 'reviews',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for ProfilePage: ${tab}`));
  }
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());
  dispatch(queryListingsRequest());
  dispatch(queryReviewsRequest());

  return axios.get(`${apiBaseUrl()}/api/fetchByUserName/${slug}`).then(resp => {
    const userId = new UUID(resp.data[0]?.id.uuid);

    // Clear state so that previously loaded data is not visible
    // in case this page load fails.

    return Promise.all([
      dispatch(fetchCurrentUser()),
      dispatch(showUser(userId)),
      dispatch(queryUserListings(userId, tab === 'listings' ? queryPage : 1)),
      dispatch(queryUserReviews(userId, tab === 'reviews' ? queryPage : 1)),
    ]);
  });
  // return Promise.all([]);
};
