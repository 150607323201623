import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldCheckbox } from '../../components';
import css from './EditListingStockForm.module.css';
import config from '../../config';
import arrayMutators from 'final-form-arrays';

import { withRouter } from 'react-router-dom';
import { composeValidators } from '../../util/validators';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FieldArray } from 'react-final-form-arrays';
import { shoeSizes } from '../../marketplace-custom-config';

const EditListingStockFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          filterConfig,
          form,
          initialValues,
          values,
          history,
          touched,
          errors,
          active,
          listing,
          isClothingCategory,
          clothingSize,
          isShoeCategory,
          shoeSize,
        } = formRenderProps;

        const {
          updateListingError,
          createListingDraftError,
          showListingsError,
        } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const intStock =
          isClothingCategory || isShoeCategory ? 1 : parseInt(values.stock);

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid ||
          disabled ||
          submitInProgress ||
          isNaN(intStock) ||
          !intStock;
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}

            <div>
              <span className={css.divTitle}>Product Quantity</span>
              <span className={css.divLabel}>Put your product quantity.</span>
            </div>
            {isClothingCategory ? (
              clothingSize?.map(size => (
                <FieldTextInput
                  id={size}
                  name={size}
                  key={size}
                  className={classNames(css.inputs, {
                    [css.invalidInputs]: touched[size] && !!errors[size],
                    [css.fnNonEmptyInputs]: !!values[size] || active === size,
                  })}
                  type="text"
                  label={size}
                  onChange={e => {
                    const value = e.target.value;
                    const regex = /^[0-9\b]+$/;
                    if (value.match(regex) || value === '')
                      form.change(`${size}`, value);
                  }}
                />
              ))
            ) : isShoeCategory ? (
              shoeSize?.map(size => (
                <FieldTextInput
                  id={size}
                  key={size}
                  name={size}
                  className={classNames(css.inputs, {
                    [css.invalidInputs]: touched[size] && !!errors[size],
                    [css.fnNonEmptyInputs]: !!values[size] || active === size,
                  })}
                  type="text"
                  label={`Size - ${
                    shoeSizes.find(({ key }) => key === size).label_UK
                  }`}
                  onChange={e => {
                    const value = e.target.value;
                    const regex = /^[0-9\b]+$/;
                    if (value.match(regex) || value === '')
                      form.change(`${size}`, value);
                  }}
                />
              ))
            ) : (
              <FieldTextInput
                id="stock"
                name="stock"
                // className={css.title}
                className={classNames(css.inputs, {
                  [css.invalidInputs]: touched.stock && !!errors.stock,
                  [css.fnNonEmptyInputs]: !!values.stock || active === 'stock',
                })}
                type="text"
                label={'Quantity*'}
                onChange={e => {
                  const value = e.target.value;
                  const regex = /^[0-9\b]+$/;
                  if (value.match(regex) || value === '')
                    form.change('stock', value);
                }}
                // autoFocus
              />
            )}

            <span className={css.tip}>Enter the quantity in digits only.</span>

            <div className={css.lowerDiv}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {/* {saveActionMsg} */}
                Save & Next
              </Button>
              <button
                className={css.cancelButton}
                type="button"
                onClick={() => {
                  form.reset;
                  history.push(
                    createResourceLocatorString(
                      'LandingPage',
                      routeConfiguration(),
                      {},
                      {}
                    )
                  );
                }}
              >
                Cancel
              </button>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingStockFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingStockFormComponent.propTypes = {
  //   className: string,
  //   intl: intlShape.isRequired,
  //   onSubmit: func.isRequired,
  //   saveActionMsg: string.isRequired,
  //   disabled: bool.isRequired,
  //   ready: bool.isRequired,
  //   updated: bool.isRequired,
  //   updateInProgress: bool.isRequired,
  //   fetchErrors: shape({
  //     createListingDraftError: propTypes.error,
  //     showListingsError: propTypes.error,
  //     updateListingError: propTypes.error,
  //   }),
  //   certificateOptions: arrayOf(
  //     shape({
  //       key: string.isRequired,
  //       label: string.isRequired,
  //     })
  //   ),
  //   filterConfig: propTypes.filterConfig,
};

export default compose(withRouter, injectIntl)(EditListingStockFormComponent);
