import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_PROVIDER_COMMISSION } from '../../util/types';

import css from './BookingBreakdown.module.css';
import { PriceDataWithDifferentCurrency } from '../../util/PriceDataWithDifferentCurrency';

const LineItemProviderCommissionRefundMaybe = props => {
  const {
    transaction,
    isProvider,
    intl,
    selectedCurrencyExchangeCode,
    selectedCurrency,
  } = props;

  const refund = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && item.reversal
  );

  return isProvider && refund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.refundProviderFee" />
      </span>
      <span className={css.itemValue}>
        {
          PriceDataWithDifferentCurrency(
            refund.lineTotal,
            intl,
            selectedCurrency,
            selectedCurrencyExchangeCode
          )?.formattedPrice
        }
      </span>
    </div>
  ) : null;
};

LineItemProviderCommissionRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderCommissionRefundMaybe;
