import React, { useEffect, useRef, useState } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';
import Slider from 'react-slick';
import css from './Reviews.module.css';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';

const MOBILE_WIDTH = 576;
const getParsedContent = content => {
  try {
    return JSON.parse(content);
  } catch (e) {
    return { content, urls: [] };
  }
};

const Review = props => {
  const { review, intl, viewport } = props;
  const [slideCount, setSlideCount] = useState(1);
  const previewWrapperRef = useRef(null);
  const imgsRef = useRef([]);
  const { width } = viewport;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  const { content, urls } = getParsedContent(review.attributes.content);
  const settings = {
    // dots: width < MOBILE_WIDTH,
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: slideCount,
    slidesToShow: urls.length < 4 ? urls.length : 4,

    slidesToScroll: 2,
    arrows: width >= MOBILE_WIDTH,
    autoplay: false,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          autoplay: false,
        },
      },
      {
        breakpoint: 1073,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          autoplay: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          // centerMode: true,
          // centerPadding: '190px',
        },
      },
    ],
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 4,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 575,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //       arrows: false,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       arrows: false,
    //       dots: true,
    //     },
    //   },
    // ],
  };

  const previewWrapperRefCb = el => {
    if (el && imgsRef.current.length > 0) {
      previewWrapperRef.current = el;
      const offsetWidth = el.offsetWidth;
      const maxAllowedCount = imgsRef.current.length;
      const imgWidth = imgsRef.current[0].offsetWidth;
      const widthWiseCount = parseFloat((offsetWidth / imgWidth).toFixed(1));
      const count = Math.min(maxAllowedCount, widthWiseCount);
      setSlideCount(count);
    }
  };

  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} disableProfileLink />
      <div className={css.ReviewWrap}>
        <ReviewRating
          rating={review.attributes.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />

        {urls && Array.isArray(urls) && urls.length > 0 && (
          <div className={css.previewImgWrapper} ref={previewWrapperRefCb}>
            <Slider {...settings}>
              {urls.map((url, indx) => (
                <div
                  className={css.SmallImg}
                  ref={el => (imgsRef.current[indx] = el)}
                  key={indx}
                >
                  <img src={url} key={indx} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        )}

        <p className={css.reviewContent}>{content}</p>
        <p className={css.reviewInfo}>
          <UserDisplayName user={review.author} intl={intl} />

          <span className={css.separator}>•</span>
          {dateString}
          <span className={css.desktopSeparator}>•</span>
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, viewport } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {reviews.map(r => {
        return (
          <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
            <Review review={r} intl={intl} viewport={viewport} />
          </li>
        );
      })}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = compose(withViewport, injectIntl)(ReviewsComponent);

export default Reviews;
