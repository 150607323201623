import React, { useEffect, useRef, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import * as validators from '../../util/validators';
import axios from 'axios';
import Select, { components } from 'react-select';

import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldTextInput,
  FieldCurrencyInput,
  SearchMap,
  LocationAutocompleteInput,
  IconSpinner,
} from '../../components';
import { ReactComponent as BoxUnchecked } from '../../assets/BoxUnchecked.svg';
import { ReactComponent as BoxChecked } from '../../assets/BoxChecked.svg';
import { ReactComponent as ArrowDown } from '../../assets/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/ArrowUp.svg';
import css from './EditListingLocationForm.module.css';
import config from '../../config';
import {
  categoriesWithListingType,
  deliveryMethodOptions,
} from '../../marketplace-custom-config';
import { types as sdkTypes } from '../../../src/util/sdkLoader';
import CustomSearchMap from '../../components/SearchMap/CustomSearchMap';
import { locationBounds } from '../../components/LocationAutocompleteInput/GeocoderMapbox';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { withRouter } from 'react-router-dom';
import getCountryCodes from '../../translations/countryCodes';
import { ensureListing } from '../../util/data';

const streetNumberSet = new Set([
  'room',
  'floor',
  'plus_code',
  'street_number',
  'street_address',
  'premise',
  'subpremise',
]);

const landmarkSet = new Set([
  'route',
  'landmark',
  'neighborhood',
  'locality',
  'sublocality',
]);

const citySet = new Set([
  'administrative_area_level_3',
  'administrative_area_level_4',
  'administrative_area_level_5',
  'administrative_area_level_6',
  'administrative_area_level_7',
]);
const extraCitySet = new Set(['administrative_area_level_2']);
const stateSet = new Set(['administrative_area_level_1']);

const countrySet = new Set(['country']);

const postalCodeSet = new Set(['postal_code']);

const apiKey = process.env.REACT_APP_GOOGLE_MAP_API;

const { LatLng } = sdkTypes;
const identity = v => v;
const LOCATION_BOUNDS = 1000;
const ausCoordinates = { lat: -25.2744, lng: 133.7751 };
const defaultBound = 1000000;

const countryCodes = getCountryCodes(config.locale);
const countryOPtions = countryCodes.map(country => ({
  key: country.code,
  value: country.code,
  label: country.name,
}));

const getValueContainer = (
  placeholder,
  defaultPlaceholder,
  callback,
  className,
  valueSpanClassName
) => ({ children, ...props }) => {
  if (typeof callback === 'function') callback(props.selectProps.menuIsOpen);

  return (
    <components.ValueContainer {...props}>
      <div
        className={classNames(css.valueContainer, className, {
          [css.multiValueContainer]: props.isMulti,
        })}
      >
        <span
          className={classNames(css.placeholder, {
            [css.selectedPlaceholder]:
              props.hasValue || props.selectProps.menuIsOpen,
            // [css.selectedSCPlaceholder]:
            //   props.hasValue || props.selectProps.menuIsOpen || values.productSubCategory,

            [css.emptyPlaceholder]: props.isMulti && !props.hasValue,
            [valueSpanClassName]:
              !!valueSpanClassName &&
              (props.hasValue || props.selectProps.menuIsOpen),
          })}
        >
          {!props.hasValue ? defaultPlaceholder : placeholder}
        </span>
        <div
          className={classNames(css.value, {
            [css.nonEmptyValue]: props.hasValue,
            [css.blurredValue]: props.hasValue && props.selectProps.menuIsOpen,
          })}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};

const DropdownIndicator = ({ children, ...props }) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? (
      <ArrowUp
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    ) : (
      <ArrowDown
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    )}
  </components.DropdownIndicator>
);

const ClearIndicator = ({ children, ...props }) => (
  <components.ClearIndicator {...props}>
    <IoMdClose className={css.selectClearBtn} />
  </components.ClearIndicator>
);
const Menu = ({ children, ...props }) => (
  <components.Menu
    {...props}
    className={classNames(css.menu, {
      // [css.noValueMenu]: props.selectProps.id === 'country' || !props.hasValue,
    })}
  >
    {children}
  </components.Menu>
);

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        initialValues,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        errors,
        touched,
        active,
        form,
        listing,
        location,
        history,
        isMobile,
        geolocation,
      } = formRenderProps;

      const {
        publicData: { shippingType },
      } = ensureListing(listing).attributes;

      // for map
      const origin = useRef(null);
      const bounds = useRef(null);
      const localListing = useRef([listing]);
      const [initialMapUpdate, setInitialMapUpdate] = useState(false);
      // map---

      const {
        pickupAddress,
        propPredications = {
          ...ausCoordinates,
          predictions: [],
        },
        selectionInProgress = false,
      } = values;

      // const titleRequiredMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.address',
      // });
      // const addressPlaceholderMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.addressPlaceholder',
      // });
      // const addressRequiredMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.addressRequired',
      // });
      // const addressNotRecognizedMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.addressNotRecognized',
      // });

      // const addressLine1Title = intl.formatMessage({
      //   id: 'EditListingLocationForm.addressLine1Title',
      // });
      // const addressLine1Placeholder = intl.formatMessage({
      //   id: 'EditListingLocationForm.addressLine1Placeholder',
      // });
      // const addressLine1RequiredMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.addressLine1Required',
      // });

      // const addressLine1Required = validators.required(
      //   addressLine1RequiredMessage
      // );

      // const stateTitle = intl.formatMessage({
      //   id: 'EditListingLocationForm.stateTitle',
      // });
      // const statePlaceholder = intl.formatMessage({
      //   id: 'EditListingLocationForm.statePlaceholder',
      // });
      // const stateRequiredMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.stateRequired',
      // });

      // const stateRequired = validators.required(stateRequiredMessage);

      // const cityTitle = intl.formatMessage({
      //   id: 'EditListingLocationForm.cityTitle',
      // });
      // const cityPlaceholder = intl.formatMessage({
      //   id: 'EditListingLocationForm.cityPlaceholder',
      // });
      // const cityRequiredMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.cityRequired',
      // });

      // const cityRequired = validators.required(cityRequiredMessage);

      // const countryTitle = intl.formatMessage({
      //   id: 'EditListingLocationForm.countryTitle',
      // });
      // const countryPlaceholder = intl.formatMessage({
      //   id: 'EditListingLocationForm.countryPlaceholder',
      // });
      // const countryRequiredMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.countryRequired',
      // });

      // const countryRequired = validators.required(countryRequiredMessage);

      // const postalCodeTitle = intl.formatMessage({
      //   id: 'EditListingLocationForm.postalCodeTitle',
      // });
      // const postalCodePlaceholder = intl.formatMessage({
      //   id: 'EditListingLocationForm.postalCodePlaceholder',
      // });
      // const postalCodeRequiredMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.postalCodeRequired',
      // });

      // const postalCodeRequired = validators.required(postalCodeRequiredMessage);

      // const optionalText = intl.formatMessage({
      //   id: 'EditListingLocationForm.optionalText',
      // });

      // const buildingMessage = intl.formatMessage(
      //   { id: 'EditListingLocationForm.building' },
      //   { optionalText: optionalText }
      // );
      // const buildingPlaceholderMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.buildingPlaceholder',
      // });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const locationRequired = validators.required('This feild is required.');
      // const [location,setLocation]=useState({search: "", selectedPlace:{address:"" , origin: {}}})

      const getAddressFromLatLng = async (lng, lat) => {
        try {
          form.change('selectionInProgress', true);
          const { results } = (
            await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=${apiKey}`
            )
          ).data;
          for (let result of results) {
            result.description = result.formatted_address;
          }
          origin.current = new LatLng(lat, lng);
          bounds.current = locationBounds(
            origin.current,
            config.maps.search.currentLocationBoundsDistance
          );
          form.batch(() => {
            form.change('selectionInProgress', false);
            form.change('propPredications', { lat, lng, predictions: results });
          });
        } catch (error) {}
      };

      const propSelectionInProgress = status => {
        form.change('selectionInProgress', status);
      };

      const getPickupAddress = (addressComponents = []) => {
        let streetAddress = '',
          landmarks = '',
          cityLngName = '',
          cityShrtName = '',
          extraCityLngName = '',
          extraCityShrtName = '',
          stateLngName = '',
          stateShrtName = '',
          countryLngName = '',
          countryShrtName = '',
          postalCode = '';
        for (let addressComp of addressComponents) {
          const { types, long_name, short_name } = addressComp;
          let isCityFounded = false;
          for (let type of types) {
            if (streetNumberSet.has(type)) {
              streetAddress += long_name;
            }
            if (landmarkSet.has(type)) {
              landmarks += (type === 'route' ? short_name : long_name) + ' ';
            }
            if (!isCityFounded && citySet.has(type)) {
              isCityFounded = true;
              cityLngName = long_name;
              cityShrtName = short_name;
            }
            if (extraCitySet.has(type)) {
              extraCityLngName = long_name;
              extraCityShrtName = short_name;
            }
            if (stateSet.has(type)) {
              stateLngName = long_name;
              stateShrtName = short_name;
            }

            if (countrySet.has(type)) {
              countryLngName = long_name;
              countryShrtName = short_name;
            }

            if (postalCodeSet.has(type)) {
              postalCode = long_name;
            }
          }
        }
        const addressLine1 = (streetAddress + ' ' + landmarks).trim();
        const pickupAddress = {
          addressLine1,
          city: cityLngName.trim() || extraCityLngName.trim(),
          cityShrtName: cityShrtName.trim() || extraCityShrtName.trim(),
          state: stateLngName.trim(),
          stateShrtName: stateShrtName.trim(),
          country: countryLngName.trim(),
          countryShrtName: countryShrtName.trim(),
          postalCode: postalCode.trim(),
        };
        return pickupAddress;
      };

      const isPickupAddressValid = pickupAddress => {
        return !!(
          pickupAddress &&
          pickupAddress.addressLine1 &&
          typeof pickupAddress.addressLine1 === 'string' &&
          pickupAddress.postalCode &&
          typeof pickupAddress.postalCode === 'string' &&
          pickupAddress.city &&
          typeof pickupAddress.city === 'string' &&
          pickupAddress.cityShrtName &&
          typeof pickupAddress.cityShrtName === 'string' &&
          pickupAddress.state &&
          typeof pickupAddress.state === 'string' &&
          pickupAddress.stateShrtName &&
          typeof pickupAddress.stateShrtName === 'string' &&
          pickupAddress.country &&
          typeof pickupAddress.country === 'string' &&
          pickupAddress.countryShrtName &&
          typeof pickupAddress.countryShrtName === 'string'
        );
      };

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const categories = listing?.attributes?.publicData?.productCategory;
      const listingType = listing?.attributes?.publicData?.listingType;
      const showDeliveryMethod = categoriesWithListingType.includes(categories);
      const isPlatformShipping =
        shippingType === deliveryMethodOptions.automaticShipping.value;
      const isPickupAddresValid = isPlatformShipping
        ? isPickupAddressValid(pickupAddress)
        : true;
      const submitDisabled =
        invalid || disabled || submitInProgress || selectionInProgress;
      // !isPickupAddresValid;

      const showDeliveryMethodCondition =
        showDeliveryMethod || categories === 'businessServices';
      const additionalItemsDisabled =
        listingType === 'oneItem' || listingType === 'buffet';

      //for map
      useEffect(() => {
        try {
          setInitialMapUpdate(true);
          if (geolocation) {
            const org = new LatLng(geolocation.lat, geolocation.lng);
            origin.current = org;
            bounds.current = locationBounds(
              org,
              config.maps.search.currentLocationBoundsDistance
            );
          } else {
            const org = new LatLng(ausCoordinates.lat, ausCoordinates.lng);
            origin.current = org;
            bounds.current = locationBounds(org, defaultBound);
          }
          setTimeout(() => setInitialMapUpdate(false), 10);
        } catch (e) {}
      }, []);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          {/* {showDeliveryMethodCondition ? (
            <div>
              <div className={css.dmDiv}>
                <span className={css.divTitle}>Delivery Method</span>
                <span className={css.divLabel}>
                  Choose the delivery options you provide to your customer.
                </span>
              </div>
              <div className={css.boxDiv}>
                <label htmlFor="shipping" className={css.termLabel}>
                  {deliveryMethod.includes('shipping') ? (
                    <BoxChecked className={css.checkBox} />
                  ) : (
                    <BoxUnchecked className={css.checkBox} />
                  )}
                  Shipping
                </label>
                <input
                  type="checkbox"
                  id="shipping"
                  className={css.inputBox}
                  name="deliveryMethod"
                  value="shipping"
                  onChange={handleDeliveryMethod}
                  checked={deliveryMethod.includes('shipping')}
                />
                <label htmlFor="pickup" className={css.termLabel}>
                  {deliveryMethod.includes('pickup') ? (
                    <BoxChecked className={css.checkBox} />
                  ) : (
                    <BoxUnchecked className={css.checkBox} />
                  )}
                  Pickup
                </label>
                <input
                  type="checkbox"
                  id="pickup"
                  name="deliveryMethod"
                  className={css.inputBox}
                  value="pickup"
                  onChange={handleDeliveryMethod}
                  checked={deliveryMethod.includes('pickup')}
                />
              </div>
              {deliveryMethod.includes('shipping') ? (
                <div className={css.chargeDiv}>
                  <FieldCurrencyInput
                    id="shippingCharge"
                    name="shippingCharge"
                    // className={classNames(css.inputs, {
                    //   [css.invalidInputs]:
                    //     touched.shippingCharge && !!errors.shippingCharge,
                    //   [css.nonEmptyInputs]:
                    //     !!values.shippingCharge || active === 'shippingCharge',
                    //   [css.inputLabel]:
                    //     !!values.shippingCharge || active === 'shippingCharge',
                    // })}
                    className={classNames(css.inputs, {
                      [css.invalidInputs]:
                        touched.shippingCharge && !!errors.shippingCharge,
                      [css.fnNonEmptyInputs]:
                        !!values.shippingCharge || active === 'shippingCharge',
                    })}
                    // autoFocus={autoFocus}
                    label={'Shipping Price'}
                    placeholder={' '}
                    // placeholder={intl.formatMessage({
                    //   id: 'EditListingPricingForm.priceInputPlaceholder',
                    // })}
                    currencyConfig={config.currencyConfig}
                    validate={composeValidators(
                      validators.required('You need to add a shipping price.')
                    )}
                  />
                  <FieldCurrencyInput
                    id="additionalItems"
                    name="additionalItems"
                    className={classNames(css.inputs, {
                      [css.invalidInputs]:
                        touched.additionalItems && !!errors.additionalItems,
                      [css.fnNonEmptyInputs]:
                        !!values.additionalItems ||
                        active === 'additionalItems',
                    })}
                    // autoFocus={autoFocus}
                    label={'Additional Items*'}
                    placeholder={' '}
                    // placeholder={intl.formatMessage({
                    //   id: 'EditListingPricingForm.priceInputPlaceholder',
                    // })}
                    currencyConfig={config.currencyConfig}
                    disabled={additionalItemsDisabled}
                    validate={
                      !additionalItemsDisabled &&
                      composeValidators(
                        validators.required(
                          'You need to add additional item price.'
                        )
                      )
                    }
                  />
                </div>
              ) : null}
            </div>
          ) : null} */}
          <div>
            <div className={css.titleDiv}>
              {/* <span className={css.divTitle}>Location</span> */}
              <span className={css.divLabel}>
                You can provide either your street address or only a city or
                zip/postal code. It’s good to also add your country when adding
                your location. Examples: "Sydney, NSW, Australia and Auckland,
                New Zealand".
              </span>
            </div>
            <div className={css.locationWrapper}>
              <div className={css.locationDiv}>
                <div
                  className={classNames(css.loaderDiv, {
                    [css.showLoaderDiv]: selectionInProgress,
                  })}
                >
                  <IconSpinner className={css.loader} />
                </div>
                <Field
                  name="location"
                  className={css.locationText}
                  format={identity}
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;

                    // Merge the standard onChange function with custom behaviur. A better solution would
                    // be to use the FormSpy component from Final Form and pass this.onChange to the
                    // onChange prop but that breaks due to insufficient subscription handling.
                    // See: https://github.com/final-form/react-final-form/issues/159
                    const searchOnChange = value => {
                      const { selectedPlace } = value;
                      const {
                        address_components,
                        origin: sOrigin,
                      } = selectedPlace || {
                        address_components: [],
                      };
                      if (isPlatformShipping) {
                        const pickupAddress = getPickupAddress(
                          address_components
                        );
                        origin.current = sOrigin;
                        bounds.current = locationBounds(
                          sOrigin,
                          config.maps.search.currentLocationBoundsDistance
                        );
                        localListing.current = [
                          {
                            ...localListing.current[0],
                            attributes: {
                              ...localListing.current[0].attributes,
                              geolocation: sOrigin,
                            },
                          },
                        ];
                        onChange(value);
                        form.change('pickupAddress', pickupAddress);
                      } else {
                        onChange(value);
                      }
                    };

                    const searchInput = {
                      ...restInput,
                      onChange: searchOnChange,
                    };
                    return (
                      <LocationAutocompleteInput
                        // className={css.locationAddress}
                        className={css.locationText}
                        inputClassName={css.locationAutocompleteInput}
                        iconClassName={css.locationAutocompleteInputIcon}
                        predictionsClassName={css.predictionsRoot}
                        validClassName={css.validLocation}
                        predictionsAttributionClassName={
                          isMobile ? css.mobilePredictionsAttribution : null
                        }
                        placeholder={intl.formatMessage({
                          id:
                            'EditListingLocationForm.locationSearchPlaceholder',
                        })}
                        closeOnBlur={!isMobile}
                        input={searchInput}
                        meta={meta}
                        propPredications={propPredications}
                        propSelectionInProgress={propSelectionInProgress}
                      />
                    );
                  }}
                />

                {/* <FieldTextInput
                className={css.locationText}
                type="text"
                name="shippingAddress"
                id="shippingAddress"
                label={'Location*'}
                placeholder={'Enter your location'}
                validate={locationRequired}
                onChange={async e => {
                  const val = e.target.value;

                  setPostalCode({ postalCode: val.toUpperCase() });
                  form.change('shippingAddress', val.toUpperCase());
                  if (val.length === 6) {
                    getAddress(val);
                  } // form.change('shippingAddress', e.target.value);

                  // const res = await axios.get(
                  //   `https://maps.googleapis.com/maps/api/geocode/json?address=${e.target.value}&sensor=true&key=${apiKey}`
                  // );
                }}
              /> */}
                <CustomSearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={null}
                  bounds={bounds.current}
                  center={origin.current}
                  isSearchMapOpenOnMobile={true}
                  location={location}
                  listings={localListing.current}
                  onMapMoveEnd={() => {}}
                  messages={intl.messages}
                  setPickupAddress={getAddressFromLatLng}
                  initialMapUpdate={false}
                  showCustomMap={true}
                  updateMapPinOnClick={false}
                />
              </div>
              {/* {isPickupAddresValid && isPlatformShipping ? (
                <div>
                  <div>Pickup Address:</div>
                  <div className={css.pickupDiv}>
                    <div>
                      <span>Street Address:</span>{' '}
                      <span>{pickupAddress.addressLine1}</span>
                    </div>
                    <div>
                      <span>City/Suburb:</span>{' '}
                      <span>{pickupAddress.city}</span>
                    </div>
                    <div>
                      <span>State:</span> <span>{pickupAddress.state}</span>
                    </div>
                    <div>
                      <span>Country:</span> <span>{pickupAddress.country}</span>
                    </div>
                    <div>
                      <span>Postal Code:</span>{' '}
                      <span>{pickupAddress.postalCode}</span>
                    </div>
                  </div>
                </div>
              ) : null} */}
            </div>
            {/* <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              autoFocus
              name="location"
              label={titleRequiredMessage}
              placeholder={addressPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <FieldTextInput
              className={css.building}
              type="text"
              name="building"
              id="building"
              label={buildingMessage}
              placeholder={buildingPlaceholderMessage}
            /> */}
            {/*<FieldTextInput
              id="pickupAddress.addressLine1"
              name="pickupAddress.addressLine1"
              label={addressLine1Title}
              placeholder={addressLine1Placeholder}
              validate={addressLine1Required}
              type="text"
            />

            <FieldTextInput
              id="pickupAddress.state"
              name="pickupAddress.state"
              label={stateTitle}
              placeholder={statePlaceholder}
              validate={stateRequired}
              type="text"
            />

            <FieldTextInput
              id="pickupAddress.city"
              name="pickupAddress.city"
              label={cityTitle}
              placeholder={cityPlaceholder}
              validate={cityRequired}
              type="text"
            />
            <FieldTextInput
              id="pickupAddress.postalCode"
              name="pickupAddress.postalCode"
              label={postalCodeTitle}
              placeholder={postalCodePlaceholder}
              validate={postalCodeRequired}
              type="text"
            />
            <Select
              className={classNames(
                css.categoryReactSelect,
                css.cateSelect,
                {}
              )}
              // menuIsOpen={true}
              isSearchable={true}
              name="pickupAddress.country"
              id="pickupAddress.country"
              options={countryOPtions}
              placeholder={null}
              hideSelectedOptions={true}
              components={{
                DropdownIndicator,
                Menu,
                ValueContainer: getValueContainer(
                  countryPlaceholder,
                  countryPlaceholder,
                  () => {}
                  // css.catSelectedPlaceholder
                ),
              }}
              validate={countryRequired}
              onChange={handleCountryChange}
              value={{ key: country, value: country, label: countryName }}
            />*/}
          </div>
          {/* {!isPickupAddresValid ? (
            <p className={css.error}>
              Pickup address is not valid. Please use exact address for pickup
              by click on map.
            </p>
          ) : null} */}
          <div className={css.lowerDiv}>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {/* {saveActionMsg} */}
              {!props.isPublished ? 'Save & Next' : 'Save Changes'}
            </Button>
            <button
              className={css.cancelButton}
              type="button"
              onClick={() => {
                // form.reset;
                history.push(
                  createResourceLocatorString(
                    'LandingPage',
                    routeConfiguration(),
                    {},
                    {}
                  )
                );
              }}
            >
              Cancel
            </button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(
  withRouter,
  injectIntl
)(EditListingLocationFormComponent);
