import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';
import { PriceDataWithDifferentCurrency } from '../../util/PriceDataWithDifferentCurrency';
import { bool } from 'prop-types';

const LineItemBasePriceMaybe = props => {
  const {
    transaction,
    unitType,
    intl,
    selectedCurrencyExchangeCode,
    selectedCurrency,
    isProductType,
  } = props;
  console.log(transaction);
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
    ? 'BookingBreakdown.baseUnitDay'
    : isProductType
    ? 'BookingBreakdown.baseUnitQuantity'
    : 'BookingBreakdown.baseUnitHour';

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  // const { formattedPrice, priceTitle } = PriceDataWithDifferentCurrency(
  //   price,
  //   intl,
  //   selectedCurrency,
  //   selectedCurrencyExchangeCode
  // );

  const unitPrice = unitPurchase
    ? PriceDataWithDifferentCurrency(
        unitPurchase.unitPrice,
        intl,
        selectedCurrency,
        selectedCurrencyExchangeCode
      )?.formattedPrice
    : null;

  const total = unitPurchase
    ? PriceDataWithDifferentCurrency(
        unitPurchase.lineTotal,
        intl,
        selectedCurrency,
        selectedCurrencyExchangeCode
      )?.formattedPrice
    : null;

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id={translationKey}
          values={{ unitPrice, quantity }}
        />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.defaultProps = {
  isProductType: false,
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
  isProductType: bool.isRequired,
};

export default LineItemBasePriceMaybe;
