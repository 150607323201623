import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';
import NamedLink from '../NamedLink/NamedLink';
import ExternalLink from '../ExternalLink/ExternalLink';
import { InlineTextButton } from '../Button/Button';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  // prettier-ignore

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated 27/02/2024</p>
      <p>
        Siuhuu Pty Ltd ACN 663 566 747 (“Siuhuu” or “We”) manages the
        information that We collect from you in accordance with all applicable
        privacy legislation in Australia.{' '}
      </p>
      <p>
        This Privacy Policy describes how Siuhuu handles your personal
        information, and sets out the rights and obligations that both you and
        Siuhuu have in relation to your personal information.
      </p>
      <p>
        By accessing www.siuhuu.com (the “Site”) you accept and agree to the
        terms and conditions of Siuhuu’s general terms (“Siuhuu General Terms”),
        which includes your consent to, and authorization of, the collection,
        use and disclosure of your personal information in accordance with this
        Privacy Policy. Note that under Siuhuu’s terms and conditions of use,
        you must not use the Site if you are under 16 years old.
      </p>
      <div>
        <h2 className={css.heading}>Collecting and Using Your Personal Data</h2>
        <span className={css.subHeadings}>Types of Data Collected</span>
        <div>
          <span className={css.boldTitle}>Personal Data</span>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <li className={css.listBulletsOutside}>Email address</li>
          <li className={css.listBulletsOutside}>First name and last name</li>
          <li className={css.listBulletsOutside}>Phone number</li>
          <li className={css.listBulletsOutside}>
            Address, State, Province, ZIP/Postal code, City
          </li>
          <li className={css.listBulletsOutside}>Usage Data</li>
        </div>
        <div>
          <span className={css.boldTitle}>Usage Data</span>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>

          <p>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
        </div>
        <div>
          <span className={css.boldTitle}>
            Information from Third-Party Social Media Services
          </span>
          <p>
            Siuhuu allows You to create an account and log in to use the Service
            through the following Third-party Social Media Services:
          </p>
          <li className={css.listBulletsOutside}>Google</li>
          <li className={css.listBulletsOutside}>Facebook</li>
          <p>
            If You decide to register through or otherwise grant us access to a
            Third-Party Social Media Service, We may collect Personal data that
            is already associated with Your Third-Party Social Media Service's
            account, such as Your name, Your email address, Your activities or
            Your contact list associated with that account.
          </p>
          <p>
            You may also have the option of sharing additional information with
            Siuhuu through Your Third-Party Social Media Service's account. If
            You choose to provide such information and Personal Data, during
            registration or otherwise, You are giving Siuhuu permission to use,
            share, and store it in a manner consistent with this Privacy Policy.
          </p>
        </div>
        <div>
          <span className={css.subHeadings}>
            Cookies and Anonymous Identifiers
          </span>
          <p>
            If you have registered on the Site then your computer or device may
            store an identifying cookie or anonymous identifier, which can save
            you time each time you re-visit the Site, by accessing your
            information when you sign-in to Siuhuu.
          </p>
          <p>
            Siuhuu may use cookies and anonymous identifiers for a number of
            purposes including to access your information when you sign in, keep
            track of your preferences, direct specific content to you, report on
            Siuhuu’s user base, and to improve Siuhuu’s services. We may also
            use cookies or anonymous identifiers when you interact with our
            affiliate service providers (for example, when you integrate your
            Siuhuu account with your Facebook profile).
          </p>
          <p>
            You agree that if you change the settings on your internet browser
            to block or restrict cookies (including cookies associated with
            Siuhuu’s services), or to indicate when a cookie is being set by
            Siuhuu, the Siuhuu Services may not work as intended. You should
            remember that, while you may still be able to use the Site if your
            cookies are disabled, our services may not function properly on your
            device and you may not be able to take advantage of certain Siuhuu
            features.
          </p>
        </div>
        <div>
          <span className={css.subHeadings}>Use of Your Personal Data</span>
          <p>Siuhuu may use Personal Data for the following purposes:</p>
          <p>
            <b className={css.boldText}>
              {' '}
              To provide and maintain our Service,
            </b>{' '}
            including to monitor the usage of our Service. To manage Your
            Account: to manage Your registration as a user of the Service. The
            Personal Data You provide can give You access to different
            functionalities of the Service that are available to You as a
            registered user.
          </p>
          <p>
            <b className={css.boldText}> For the performance of a contract:</b>{' '}
            the development, compliance and undertaking of the purchase contract
            for the products, items or services You have purchased or of any
            other contract with Us through the Service.
          </p>
          <p>
            <b className={css.boldText}>To contact You:</b> To contact You by
            email, telephone calls, SMS, or other equivalent forms of electronic
            communication, such as a mobile application's push notifications
            regarding updates or informative communications related to the
            functionalities, products or contracted services, including the
            security updates, when necessary or reasonable for their
            implementation.
          </p>
          <p>
            <b className={css.boldText}>To provide You</b> with news, special
            offers and general information about other goods, services and
            events which we offer that are similar to those that you have
            already purchased or enquired about unless You have opted not to
            receive such information.
          </p>
          <p>
            <b className={css.boldText}>To manage Your requests: </b>To attend
            and manage Your requests to Us.
          </p>
        </div>
        <div>
          <span className={css.boldTitle}>
            We may share your personal information in the following situations:
          </span>
          <p>
            <b className={css.boldText}>With Service Providers:</b> We may share
            Your personal information with Service Providers to monitor and
            analyze the use of our Service, for payment processing, to contact
            You.
          </p>
          <p>
            <b className={css.boldText}>For Business transfers:</b> We may share
            or transfer Your personal information in connection with, or during
            negotiations of, any merger, sale of Siuhuu assets, financing, or
            acquisition of all or a portion of our business to another company.
          </p>{' '}
          <p>
            <b className={css.boldText}>With Affiliates:</b> We may share Your
            information with Our affiliates, in which case we will require those
            affiliates to honor this Privacy Policy.
          </p>{' '}
          <p>
            <b className={css.boldText}>With Business partners:</b> We may share
            Your information with Our business partners to offer You certain
            products, services or promotions.
          </p>{' '}
          <p>
            <b className={css.boldText}>With other users:</b> when You share
            personal information or otherwise interact in the public areas with
            other users, such information may be viewed by all users and may be
            publicly distributed outside. If You interact with other users or
            register through a Third-Party Social Media Service, Your contacts
            on the Third-Party Social Media Service may see Your name, profile,
            pictures and description of Your activity. Similarly, other users
            will be able to view descriptions of Your activity, communicate with
            You and view Your profile.
          </p>
        </div>
        <div>
          <span className={css.subHeadings}>
            Retention of Your Personal Data
          </span>
          <p>
            Siuhuu will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            Siuhuu will also retain Usage Data for internal analysis purposes.
            Usage Data is generally retained for a shorter period of time,
            except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
        </div>
        <div>
          <span className={css.subHeadings}>
            Transfer of Your Personal Data
          </span>
          <p>
            Your information, including Personal Data, is processed at the
            Siuhuu's operating offices and in any other places where the parties
            involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            Siuhuu will take all steps reasonably necessary to ensure that Your
            data is treated securely and in accordance with this Privacy Policy
            and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
        </div>
        <div>
          <span className={css.subHeadings}>
          Delete Your Personal Data
          </span>
          <p>
          You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.
          </p>
          <p>
          Our Service may give You the ability to amend certain information about You from within the Service.
          </p>
          <p>
          You may update, or amend your information at any time by signing in to Your Account if you have one and visiting the account settings section that allows you to manage Your personal information.
          </p>
          <p>
          You may also contact Us at contact@siuhuu.com to request access to, correct, or delete any personal information that you have provided to Us.
          </p>
        </div>
        <div>
          <span className={css.subHeadings}>
            Disclosure of Your Personal Data
          </span>
          <span className={css.boldTitle}>Business Transactions</span>
          <p>
            If Siuhuu is involved in a merger, acquisition or asset sale, Your
            Personal Data may be transferred. We will provide notice before Your
            Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <span className={css.boldTitle}>Law enforcement</span>
          <p>
            Under certain circumstances, Siuhuu may be required to disclose Your
            Personal Data if required to do so by law or in response to valid
            requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <span className={css.boldTitle}>Other legal requirements</span>
          <p>
            Siuhuu may disclose Your Personal Data in the good faith belief that
            such action is necessary to:
          </p>

          <li className={css.noBulletList}>Comply with a legal obligation</li>
          <li className={css.noBulletList}>
            Protect and defend the rights or property of Siuhuu
          </li>
          <li className={css.noBulletList}>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li className={css.noBulletList}>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li className={css.noBulletList}>Protect against legal liability</li>
        </div>
        <div>
          <span className={css.subHeadings}>
            Security of Your Personal Data
          </span>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
        </div>
        <div>
          <h2 className={css.heading}>
            Detailed Information on the Processing of Your Personal Data
          </h2>
          <p>
            Service Providers have access to Your Personal Data only to perform
            their tasks on Our behalf and are obligated not to disclose or use
            it for any other purpose.
          </p>
          <div>
            <span className={css.subHeadings}>Analytics</span>
            <p>
              We may use third-party Service providers to monitor and analyze
              the use of our Service.
            </p>
            <li>
              Google Analytics
              <p className={css.noBulletListWithMargin}>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its
                own advertising network.
              </p>
              <p className={css.noBulletListWithMargin}>
                You can opt-out of having made your activity on the Service
                available to Google Analytics by installing the Google Analytics
                opt-out browser add-on. The add-on prevents the Google Analytics
                JavaScript (ga.js, analytics.js and dc.js) from sharing
                information with Google Analytics about visits activity.
              </p>
              <p className={css.noBulletListWithMargin}>
                For more information on the privacy practices of Google, please
                visit the Google Privacy & Terms web page:
                <ExternalLink href="https://policies.google.com/privacy">
                  {' '}
                  https://policies.google.com/privacy
                </ExternalLink>
              </p>
            </li>
          </div>
          <div>
            <span className={css.subHeadings}>Payments</span>
            <p>
              We may provide paid products and/or services within the Service.
              In that case, we may use third-party services for payment
              processing (e.g. stripe, PayPal).
            </p>
            <p>
              We will not store or collect Your payment card details. That
              information is provided directly to Our third-party payment
              processors whose use of Your personal information is governed by
              their Privacy Policy. These payment processors adhere to the
              standards set by PCI-DSS as managed by the PCI Security Standards
              Council, which is a joint effort of brands like Visa, Mastercard,
              American Express. PCI-DSS requirements help ensure the secure
              handling of payment information.
            </p>
            <li>
              Stripe
              <p className={css.noBulletListWithMargin}>
                Their Privacy Policy can be viewed at
                <ExternalLink href=" https://stripe.com/au/privacy">
                  {' '}
                  https://stripe.com/au/privacy
                </ExternalLink>
              </p>
            </li>
            <li>
              PayPal
              <p className={css.noBulletListWithMargin}>
                Their Privacy Policy can be viewed at
                <ExternalLink href="https://www.paypal.com/au/webapps/mpp/ua/privacy-full">
                  {' '}
                  https://www.paypal.com/au/webapps/mpp/ua/privacy-full
                </ExternalLink>
              </p>
            </li>
          </div>
          <div>
            <span className={css.subHeadings}>Email Marketing</span>
            <p>
              We may use Your Personal Data to contact You with newsletters,
              marketing or promotional materials and other information that may
              be of interest to You. You may opt-out of receiving any, or all,
              of these communications from Us by following the unsubscribe link
              or instructions provided in any email We send or by contacting Us.
            </p>
            <p>
              We may use Email Marketing Service Providers to manage and send
              emails to You.
            </p>
          </div>
          <div>
            <span className={css.subHeadings}>
              Administrative Communications
            </span>
            <p>
              Siuhuu reserves the right to send you administrative and
              account-related messages that you may not opt-out of. To cease all
              communications to you from Siuhuu you must close down your Siuhuu
              account.
            </p>
          </div>
          <div>
            <span className={css.subHeadings}>
              Accessing Personal Information
            </span>
            <p>
              We will allow you, at any time, to access, edit, update and/ or
              delete the personal information that We hold about you, unless:
            </p>
            <li className={css.listBulletsOutside}>
              We are legally permitted or required to deny you access to, and/
              or to retain, the information; or
            </li>
            <li className={css.listBulletsOutside}>
              you make request(s) that are unreasonably repetitive, require
              Siuhuu to make a disproportionate technical effort (for example,
              to develop an entirely new system), risk the privacy of others, or
              would be highly impractical to comply with.
            </li>
            <p>
              If you wish to access the personal information We hold about you,
              or to delete your Siuhuu account, you should contact us.
            </p>
            <p>
              We need to prevent information in our systems from being
              accidentally or maliciously destroyed. This means that, where you
              delete information from our services, residual copies of that
              information on our active servers, as well as any corresponding
              information on our back-up systems, may not be immediately
              deleted.
            </p>
          </div>
          <div>
            <span className={css.subHeadings}>Privacy Concerns</span>

            <p>
              If you have concerns about how We handle your personal information
              or require further information, please email Siuhuu using the
              contact form provided on the Site. If you make a formal written
              complaint in relation to our compliance with this Privacy Policy,
              We will contact you to follow up on that complaint. Other than
              material that you voluntarily choose to post on the Site (such as
              Sender postings, bids and comments on other users), the only
              information about you that We will post publicly is your Siuhuu
              public profile.
            </p>
            <p>
              Siuhuu may, from time to time, modify this Privacy Policy (and
              update the web page on which it is displayed). Siuhuu will send
              notification of any such modification to your Siuhuu account
              and/or your registered email address. You should check that
              account regularly.
            </p>
          </div>
        </div>
        <div>
          <h2 className={css.heading}>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us by visiting our contact page.
          </p>
        </div>
      </div>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

// export default PrivacyPolicy;
export default compose(withRouter, injectIntl)(PrivacyPolicy);
