import React, { useRef, useEffect, useState, Suspense, lazy } from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { FormattedMessage, injectIntl, useIntl } from '../../util/reactIntl';
import { createInstance } from '../../util/sdkLoader';

import { propTypes } from '../../util/types';
import {
  composeValidators,
  maxAllowedNumber,
  numberAtLeast,
  numberWithIn,
  required,
} from '../../util/validators';

import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
  SecondaryButton,
  FieldDateRangeInput,
  Button,
  Modal,
} from '../../components';
import { ReactComponent as CrossIcon } from '../../assets/Cross.svg';

import css from './ProductOrderForm.module.css';
import { compose } from 'redux';
import EstimatedCustomerBreakdownMaybe from '../../components/BookingPanel/EstimatedCustomerBreakdownMaybe';

const TextEditor = lazy(() =>
  import('../../components/RichTextEditor/RichTextEditor')
);

const debounceTimeout = 100;

const lineItemUnitType = 'line-item/units';
import {
  dateField,
  deliveryMethodOptions,
  MAX_PER_USER_STOCK_BOOKING,
  oneItem,
  ONE_SIZE,
  shippingTypes,
  sizeLable,
  stockField,
  textField,
} from '../../marketplace-custom-config';
import classNames from 'classnames';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import { calculateQuantityFromDays } from '../../util/dates';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';

const identity = v => v;

const getMaxQtyPersize = (
  listing,
  isMultiSizeType,
  isClothingCategory,
  defaultSize = ONE_SIZE
) => {
  const {
    attributes: { publicData },
    currentStock,
  } = listing;

  const {
    listingType,
    shoeStockPerSize,
    clothingStockPerSize,
    shoeSize,
    clothingSize,
  } = publicData;

  const {
    attributes: { quantity: stock },
  } = currentStock ?? { attributes: { quantity: 0 } };
  const isSingleItemType = listingType === oneItem;
  if (!isMultiSizeType) {
    if (stock > 0) {
      return { [defaultSize]: parseInt(isSingleItemType ? 1 : stock) };
    }
    return { [defaultSize]: 0 };
  }
  const stockPerSize =
    (isClothingCategory ? clothingStockPerSize : shoeStockPerSize) ?? {};
  const defaultSizes = (isClothingCategory ? clothingSize : shoeSize) || [];
  return defaultSizes.reduce((pre, curnt) => {
    const defaultSize = parseInt(stockPerSize[curnt]);
    if (isNaN(defaultSize)) {
      pre[curnt] = 0;
    } else {
      pre[curnt] = defaultSize > 0 ? (isSingleItemType ? 1 : defaultSize) : 0;
    }
    return pre;
  }, {});
};

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    listingId,
    isOwnListing,
    onFetchProductTransactionLineItems,
    onContactUser,
    lineItems,
    lineItemLabel,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
    isClothingCategory,
    clothingStockPerSize,
    clothingSize,
    isShoeCategory,
    shoeStockPerSize,
    shoeSize,
    currentUser,
    cartAddInProgress,
    cartAddError,
    onAddCart,
    listing,
    form,
    deliveryMethods,
    shippingOptions,
    history,
    location,
    showOneProviderModal,
    setShowOneProviderModal,
    showOneShippingOptionModal,
    setShowOneShippingOptionModal,
    maxAllowedQtyPerSize,
    isMultiSizeType,
    isSubmitError,
    isSubmittingForm,
    pricingType,
    touched,
    errors,
    active,
    isSingleItemType,
    setFocusedInput,
    focusedInput,
    timeZone,
    isBuyTypeListings,
    onManageDisableScrolling,
  } = formRenderProps;

  const { photos = [] } = values;

  const [blockStartDate, setBlockStartDate] = useState();
  const [blockEndDate, setBlockEndDate] = useState();
  const sdkInstance = useRef(createInstance({ clientId: config.sdk.clientId }));
  const photoRef = useRef(null);
  const [rtqForm, setRtqForm] = useState(false);

  const [rtqSubmitSuccess, setRtqSubmitSuccess] = useState(false);

  const [quoteSubmitInProgress, setQuoteSubmitInProgress] = useState(false);
  const [quoteSubmitReady, setQuoteSubmitReady] = useState(false);

  const [multiplephotos, setMultiplePhotos] = useState([]);
  const [photoAfterUpload, setPhotoAfterUpload] = useState([]);

  const [editorText, setEditorText] = useState({});
  const [editorContent, setEditorContent] = useState({});

  useEffect(() => {
    const listingId = listing?.id?.uuid;
    axios
      .post(`${apiBaseUrl()}/api/getBookingDatesController`, {
        listingId,
        currentDate: new Date().toString(),
      })
      .then(res => {
        const getDates = res.data.reduce((acc, curr) => {
          acc.push({
            bookingStartDate: curr.bookingStartDate,
            bookingEndDate: curr.bookingEndDate,
          });
          return acc;
        }, []);
        setBlockStartDate(getDates);
      });
  }, []);

  const productCategory = listing.attributes.publicData.productCategory;
  const {
    size,
    deliveryMethod,
    quantity,
    shippingType,
    bookingDates = {},
    hasInitialized = false,
    unit,
  } = values || {};

  const {
    attributes: {
      publicData: { cart = {}, allowCustomQuote = [] },
    },
  } = listing;

  const isCustomQuoteAllowed = allowCustomQuote.includes('yes');

  const handleOnChange = formValues => {
    const {
      quantity: quantityRaw,
      deliveryMethod,
      shippingType,
      size,
      unit,
      typedQuantity: typedQuantityRaw,
      message,
    } = formValues.values;

    const nonTypedQuantity = Number.parseInt(quantityRaw, 10);
    const typedQuantity = Number.parseInt(typedQuantityRaw || 0, 10);
    const isTypedQuantity =
      typeof quantityRaw === 'string' && quantityRaw.includes('+');
    const quantity = isTypedQuantity
      ? typedQuantity > 10
        ? typedQuantity
        : 0
      : nonTypedQuantity;
    const shouldFetch =
      quantity &&
      !isNaN(quantity) &&
      !!size &&
      !!deliveryMethod &&
      !!shippingType &&
      !fetchLineItemsInProgress &&
      !!unit &&
      !message;
    if (shouldFetch) {
      onFetchProductTransactionLineItems({
        bookingData: {
          quantity,
          deliveryMethod,
          size,
          shippingType,
          unitType: unit,
        },
        listingId,
        isOwnListing,
      });
    }
  };

  const debouncedHandleChange = debounce(handleOnChange, debounceTimeout);

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    const Category = listing?.attributes?.publicData?.productCategory;

    // if (Category === 'carRentals' || Category === 'accommodation') {
    //   const startDate = values?.bookingDates?.startDate;
    //   const endDate = values?.bookingDates?.endDate;
    //   const listingId = listing?.id?.uuid;
    //   axios.post(`${apiBaseUrl()}/api/createBookingDatesController`, {
    //     listingId,
    //     startDate,
    //     endDate,
    //   });
    // }
    const { quantity, deliveryMethod } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else if (!deliveryMethod) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('deliveryMethod');
      formApi.focus('deliveryMethod');
    } else {
      handleSubmit(e);
    }
  };

  const handleSizeChange = size => {
    // form.change('quantity', '');
    if (isEmpty(cart)) return;
    // let initialMethod = undefined,
    //   initialQty = undefined,
    //   initialShippingType = undefined;

    let initialQty = undefined;
    if (cart[size]) {
      const {
        // deliveryMethod: cartDm,
        // shippingType: cartSt,
        quantity: cartQty,
      } = cart[size] || {};
      //const isDeliveryMethodExist = deliveryMethods.includes(cartDm);
      const isQtyAllowed = maxAllowedQtyPerSize[size] >= cartQty;

      // const isShippingTypeAllowd =
      //   cartDm === deliveryMethodOptions.pickup.value ||
      //   shippingOptions.includes(cartSt);
      initialQty = isQtyAllowed ? cartQty : undefined;
      // initialMethod = isDeliveryMethodExist ? cartDm : undefined;
      //initialShippingType = isShippingTypeAllowd ? cartSt : undefined;
    }
    form.change('quantity', initialQty);
    //form.batch(() => {
    //form.change('quantity', initialQty);
    //form.change('deliveryMethod', initialMethod);
    // form.change('shippingType', initialShippingType);
    // });
  };

  const handleOnAddCart = e => {
    e.preventDefault();
    if (!currentUser || !currentUser.id) {
      const path = {
        pathname: '/login',
        state: {
          from: `${location.pathname}${location.search}${location.hash}`,
        },
      };
      return history.replace(path);
    }
    const { quantity, unit: unitType, shippingOption, ...rest } = values;
    const providerId = listing.author.id.uuid;
    const {
      attributes: {
        profile: {
          protectedData: { cartProviderId, cartShippingOption },
        },
      },
    } = currentUser;

    if (!!cartProviderId && cartProviderId !== providerId) {
      setShowOneProviderModal(true);
      return;
    }
    // if (
    //   typeof cartShippingOption === 'string' &&
    //   cartShippingOption &&
    //   cartShippingOption !== shippingOption
    // ) {
    //   setShowOneShippingOptionModal(true);
    //   return;
    // }
    const cartDetails = {
      userId: currentUser.id.uuid,
      listingId: listing.id.uuid,
      providerId,
      quantity:
        values.quantity === '10+'
          ? parseInt(values.typedQuantity)
          : parseInt(quantity),
      unitType,
      metadata: { shippingOption },
      ...rest,
    };
    cartDetails.size = cartDetails.size || ONE_SIZE;
    onAddCart(cartDetails);
  };

  const handleDateChange = dates => {
    const { startDate, endDate } = dates;
    let quantity = 0;
    if (!!startDate && !!endDate) {
      quantity = calculateQuantityFromDays(startDate, endDate);
    }
    form.batch(() => {
      form.change('bookingDates', dates);
      form.change('unit', pricingType);
      form.change('quantity', quantity);
    });
  };

  const handleDeliveryMethodChange = dm => {
    if (dm === deliveryMethodOptions.pickup.value) {
      form.change('shippingType', shippingTypes.domestic.key);
    }
  };

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({
    id: 'ProductOrderForm.quantityRequired',
  });

  const notAllowedMsg = intl.formatMessage({
    id: 'ProductOrderForm.notAllowedMsg',
  });

  const sizeRequiredMsg = intl.formatMessage({
    id: 'ProductOrderForm.sizeRequired',
  });

  const outOfStockMsg = intl.formatMessage({
    id: 'ProductOrderForm.outOfStock',
  });

  const hasStock = currentStock && currentStock > 0;

  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;

  // const hasSelectedBookingOptions = false;
  const hasDeliveryMehod =
    typeof deliveryMethods === 'object' &&
    Array.isArray(deliveryMethods) &&
    deliveryMethods.length > 0;

  const submitInProgress = fetchLineItemsInProgress;
  const hasValidData =
    (isMultiSizeType ? !!size : true) &&
    !isNaN(parseInt(quantity)) &&
    deliveryMethod &&
    shippingType &&
    !!unit &&
    (dateField.includes(unit)
      ? !!bookingDates.startDate && !!bookingDates.endDate
      : true);

  const submitDisabled =
    !hasStock ||
    (isMultiSizeType && !size) ||
    isNaN(parseInt(quantity)) ||
    !deliveryMethod ||
    (!!deliveryMethod &&
      deliveryMethod === deliveryMethodOptions.shipping.value &&
      !shippingType) ||
    cartAddInProgress ||
    submitInProgress ||
    !hasValidData;

  const breakdownData = {};
  const showBreakdown =
    hasValidData &&
    breakdownData &&
    lineItems &&
    lineItemLabel &&
    !fetchLineItemsInProgress &&
    !fetchLineItemsError;

  const breakdown = showBreakdown ? (
    <div
      className={classNames(css.breakdownWrapper, {
        [css.marginLessBreakdown]: isBuyTypeListings,
      })}
    >
      <h3 className={css.title}>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
        lineItemLabel={lineItemLabel}
        timeZone={timeZone}
        formValues={{ ...values, listingId: listing.id.uuid }}
      />
    </div>
  ) : null;

  const availableStock = isMultiSizeType
    ? parseInt((maxAllowedQtyPerSize || {})[size] ?? 0)
    : maxAllowedQtyPerSize[ONE_SIZE] ?? currentStock;

  const listingQuantity = Math.min(MAX_PER_USER_STOCK_BOOKING, availableStock);

  const typedQuantityRequiredMsg =
    availableStock === 11
      ? intl.formatMessage({
          id: 'ProductOrderForm.typedQuantityMsg',
        })
      : intl.formatMessage(
          {
            id: 'ProductOrderForm.typedQuantityRequiredMsg',
          }
          // { availableStock }
        );
  // const typedQuantityMsg = intl.formatMessage({
  //   id: 'ProductOrderForm.typedQuantityMsg',
  // });
  const quantities = hasStock
    ? [...Array(listingQuantity).keys()].map(i => i + 1)
    : [];
  const hasQuantityDisabled =
    !hasStock || (isClothingCategory && !size) || hasOneItemLeft;

  const shouldShowOtherOptions =
    isClothingCategory || isShoeCategory ? values && size : true;

  if (
    !hasInitialized &&
    listingQuantity === 1 &&
    isMultiSizeType &&
    !!size &&
    isNaN(parseInt(quantity))
  ) {
    form.batch(() => {
      form.change('quantity', listingQuantity);
      form.change('hasInitialized', true);
    });
  }

  if (
    !hasInitialized &&
    !isMultiSizeType &&
    hasOneItemLeft &&
    isNaN(parseInt(quantity))
  ) {
    form.batch(() => {
      form.change('quantity', 1);
      form.change('hasInitialized', true);
    });
  }

  if (!hasInitialized && !isMultiSizeType && !!size && !isEmpty(cart)) {
    form.change('hasInitialized', true);
    handleSizeChange(size);
  }

  const selectedCart = (cart || {})[size] || {};
  // const isDeliveryMethodSelected =
  //selectedCart.deliveryMethod === deliveryMethod;

  // const isShippingTypeSelected = selectedCart.shippingType === shippingType;

  const isQuantitySelected = !(
    isNaN(parseInt(selectedCart.quantity)) && isNaN(parseInt(quantity))
  )
    ? parseInt(selectedCart.quantity) === parseInt(quantity)
    : false;

  const isAlreadyAddToCart = !!size && isQuantitySelected;
  // isDeliveryMethodSelected &&
  //isShippingTypeSelected;

  const isMultiType =
    (isClothingCategory && typeof clothingStockPerSize === 'object') ||
    (isShoeCategory && typeof shoeStockPerSize === 'object');

  // const stockPerSize =
  //   (isMultiSizeType
  //     ? isClothingCategory
  //       ? clothingStockPerSize
  //       : isShoeCategory
  //       ? shoeStockPerSize
  //       : {}
  //     : {}) || {};

  const stockSizes = isMultiSizeType
    ? isClothingCategory
      ? clothingSize
      : isShoeCategory
      ? shoeSize
      : []
    : [] || [];

  const onFocusChange = focusedInput => {
    // DateRangePicker requires 'onFocusChange' function and 'focusedInput'
    // but Fields of React-Form deals with onFocus & onBlur instead
    setFocusedInput(focusedInput);

    // if (focusedInput) {
    //   window.clearTimeout(this.blurTimeoutId);
    //   this.props.onFocus(focusedInput);
    // } else {
    //   window.clearTimeout(this.blurTimeoutId);
    //   this.blurTimeoutId = window.setTimeout(() => {
    //     this.props.onBlur();
    //   }, BLUR_TIMEOUT);
    // }
  };
  const dayBlock = date => {
    const dateToBlock =
      blockStartDate?.length > 0 &&
      blockStartDate?.reduce((acc, curr) => {
        const numberOfDays = moment(curr.bookingEndDate).diff(
          moment(curr.bookingStartDate),
          'days'
        );
        const days = Array.from({ length: numberOfDays }).map((d, i) =>
          moment(curr.bookingStartDate).add(i, 'd')
        );

        return [...acc, ...days];
      }, []);
    const isDateInArray =
      dateToBlock?.length > 0 &&
      dateToBlock?.some(blockDate => {
        return blockDate.isSame(moment(date), 'd'); // Change 'day' to other units if needed
      });
    if (isDateInArray) {
      return true;
    } else return false;
  };

  const onUploadSingleDocument = (file, form, name) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = function(e) {
        let imageOrVideoFile;
        if (/image\/.+|video\/.+/.test(file.type)) {
          const [fileTypePrefix, fileExt] = file.type.split('/');
          const regEx = new RegExp(`.${fileExt}`, 'gi');
          const fileName = file.name.replace(regEx, '');
          const newName = `${fileName}-${Date.now()}.${fileExt}`;
          imageOrVideoFile = new File([file], newName, {
            type: file.type,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
          });
        }

        if (name === 'photos') {
          setMultiplePhotos(prevFiles => [...prevFiles, imageOrVideoFile]);
        }
      };

      reader.readAsDataURL(file);
    }
  };
  const isuploaded = !!values?.photos?.some(f => 'key' in f);

  useEffect(() => {
    if (
      isuploaded &&
      values?.photos?.length > 0 &&
      values?.photos?.length !== photoAfterUpload?.length &&
      photoAfterUpload?.length === 0
    ) {
      setPhotoAfterUpload(values?.photos);
    }
  }, [values?.photos]);

  return (
    <React.Fragment>
      <Form onSubmit={handleFormSubmit}>
        <FormSpy
          subscription={{ values: true }}
          onChange={debouncedHandleChange}
        />
        {hasNoStockLeft ? null : isMultiType ? (
          <React.Fragment>
            <FieldSelect
              id={`${formId}.size`}
              className={css.selectField}
              name="size"
              disabled={!hasStock}
              label={intl.formatMessage({ id: 'ProductOrderForm.sizeLabel' })}
              validate={composeValidators(required(sizeRequiredMsg))}
              onChange={handleSizeChange}
            >
              <option disabled hidden value="">
                {intl.formatMessage({
                  id: 'ProductOrderForm.selectsizeOption',
                })}
              </option>
              {stockSizes.map(size => (
                <option key={size} value={size}>
                  {sizeLable[size]}
                </option>
              ))}
            </FieldSelect>
          </React.Fragment>
        ) : null}
        {!hasNoStockLeft && shouldShowOtherOptions && (
          <React.Fragment>
            {/*hasNoStockLeft || !hasDeliveryMehod ? null : (
              <React.Fragment>
                <FieldSelect
                  id={`${formId}.deliveryMethod`}
                  className={css.selectField}
                  name="deliveryMethod"
                  disabled={!hasStock}
                  label={intl.formatMessage({
                    id: 'ProductOrderForm.deliveryMethodLabel',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id: 'ProductOrderForm.deliveryMethodRequired',
                    })
                  )}
                  onChange={handleDeliveryMethodChange}
                >
                  <option disabled hidden value="">
                    {intl.formatMessage({
                      id: 'ProductOrderForm.selectDeliveryMethodOption',
                    })}
                  </option>
                  {deliveryMethods.map(deliveryMethod => (
                    <option key={deliveryMethod} value={deliveryMethod}>
                      {deliveryMethodOptions[deliveryMethod]?.label ||
                        deliveryMethodOptions.noOption.label}
                    </option>
                  ))}
                </FieldSelect>
                {deliveryMethod === deliveryMethodOptions.shipping.value && (
                  <FieldSelect
                    id={`${formId}.shippingType`}
                    className={css.selectField}
                    name="shippingType"
                    disabled={!hasStock}
                    label={intl.formatMessage({
                      id: 'ProductOrderForm.shippingOptionLabel',
                    })}
                    validate={required(
                      intl.formatMessage({
                        id: 'ProductOrderForm.shippingOptionRequired',
                      })
                    )}
                  >
                    <option disabled hidden value="">
                      {intl.formatMessage({
                        id: 'ProductOrderForm.selectShippingOption',
                      })}
                    </option>
                    {shippingOptions.map(shippingOption => (
                      <option key={shippingOption} value={shippingOption}>
                        {shippingTypes[shippingOption].label}
                      </option>
                    ))}
                  </FieldSelect>
                )}
              </React.Fragment>
                    )*/}
            {textField.includes(pricingType) && (
              <FieldTextInput
                id={`${formId}.${pricingType}`}
                className={classNames(css.inputs, {
                  [css.invalidInputs]:
                    touched[pricingType] && !!errors[pricingType],
                  [css.fnNonEmptyInputs]:
                    !!values[pricingType] || active === `${pricingType}`,
                })}
                name={`${pricingType}`}
                disabled={hasQuantityDisabled}
                label={intl.formatMessage({
                  id: `ProductOrderForm.${pricingType}Label`,
                })}
                validate={composeValidators(
                  maxAllowedNumber(
                    notAllowedMsg,
                    isSingleItemType ?? listingQuantity
                  ),
                  numberAtLeast(quantityRequiredMsg, 1)
                )}
                onChange={e => {
                  const value = e.target.value;
                  const regex = /^[0-9\b]+$/;
                  if (value.match(regex) || value === '') {
                    form.change('quantity', value);
                    form.change(`${pricingType}`, value);
                    form.change(`unit`, pricingType);
                  }
                }}
              />
            )}
            {dateField.includes(pricingType) && (
              // <DateRangePicker
              //   // {...datePickerProps}
              //   focusedInput={focusedInput}
              //   onFocusChange={onFocusChange}
              //   startDate={startDate}
              //   endDate={endDate}
              //   minimumNights={pricingType ? 0 : 1}
              //   // onDatesChange={this.onDatesChange}
              //   startDatePlaceholderText={'start date'}
              //   endDatePlaceholderText={'end date'}
              //   // screenReaderInputMessage={screenReaderInputText}
              //   // phrases={{ closeDatePicker: closeDatePickerText, clearDate: clearDateText }}
              //   // isDayBlocked={isDayBlocked}
              //   // isOutsideRange={isOutsideRange}
              // />

              <FieldDateRangeInput
                className={css.bookingDates}
                name="bookingDates"
                unitType={'line-item/day'}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={'Start Date'}
                startDatePlaceholderText={'Start Date'}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={'End Date'}
                endDatePlaceholderText={'End Date'}
                focusedInput={focusedInput}
                onFocusedInputChange={onFocusChange}
                format={identity}
                chooseDayBlockOutSide={true}
                isDayBlocked={dayBlock}
                // timeSlots={[
                //   {
                //     type: 'timeSlot',
                //     attributes: {
                //       type: 'time-slot/day',
                //       seats: 0,
                //       start: new Date('2023-08-08T00:00:00.000Z'),
                //       end: new Date('2023-08-09T00:00:00.000Z'),
                //     },
                //   },
                // ]}
                onChange={handleDateChange}
                useMobileMargins
                // validate={composeValidators(
                //   required(requiredMessage),
                //   bookingDatesRequired(
                //     startDateErrorMessage,
                //     endDateErrorMessage
                //   )
                // )}
                disabled={fetchLineItemsInProgress}
              />
            )}
            {stockField.includes(pricingType) &&
            quantities.length > 0 &&
            listingQuantity > 0 ? (
              <>
                <FieldSelect
                  id={`${formId}.quantity`}
                  className={css.selectField}
                  name="quantity"
                  disabled={hasQuantityDisabled}
                  label={intl.formatMessage({
                    id: 'ProductOrderForm.quantityLabel',
                  })}
                  validate={numberAtLeast(quantityRequiredMsg, 1)}
                >
                  <option disabled hidden value="">
                    {intl.formatMessage({
                      id: 'ProductOrderForm.selectQuantityOption',
                    })}
                  </option>
                  {quantities.map(quantity => (
                    <option key={quantity} value={quantity}>
                      {quantity}
                    </option>
                  ))}
                  {availableStock > 10 && <option value="10+">10+</option>}
                </FieldSelect>
                {values.quantity === '10+' && (
                  <>
                    <FieldTextInput
                      id={`${formId}.typedQuantity`}
                      type="text"
                      className={classNames(css.inputs, css.bottomMarginInput, {
                        [css.invalidInputs]:
                          touched['typedQuantity'] && !!errors['typedQuantity'],
                        [css.fnNonEmptyInputs]:
                          !!values['typedQuantity'] ||
                          active === 'typedQuantity',
                      })}
                      name="typedQuantity"
                      validate={numberWithIn(
                        typedQuantityRequiredMsg,
                        11,
                        availableStock
                      )}
                      label="Type quantity"
                      onChange={e => {
                        let value = e.target.value;
                        const regex = /[0-9]+/;
                        if (regex.test(value)) {
                          value = parseInt(value);
                          form.change('typedQuantity', value);
                        } else {
                          form.change('typedQuantity', undefined);
                        }
                      }}
                    />
                    <div className={css.maxQuantityMsg}>
                      Max Quantity: {availableStock}
                    </div>
                  </>
                )}
              </>
            ) : isMultiSizeType ? (
              <div className={classNames(css.error, css.outOfStockWrapper)}>
                {outOfStockMsg}
              </div>
            ) : null}
          </React.Fragment>
        )}

        {breakdown}
        <div
          className={classNames(css.submitButton, {
            [css.disableBuy]: isOwnListing,
            [css.noMargin]: isBuyTypeListings || pricingType === 'perPerson',
          })}
        >
          {!isOwnListing && stockField.includes(pricingType) && (
            <React.Fragment>
              {!!cartAddError && (
                <p className={css.error}>
                  {intl.formatMessage({ id: 'ProductOrderForm.cartAddFailed' })}
                </p>
              )}
              <SecondaryButton
                type="button"
                className={classNames(css.addToCartBtn, {
                  // [css.cartBtnDisabled]:
                  //   isAlreadyAddToCart ,
                })}
                onClick={e => handleOnAddCart(e, isAlreadyAddToCart)}
                disabled={
                  submitDisabled ||
                  isAlreadyAddToCart ||
                  listingQuantity < 1 ||
                  (availableStock > 10 &&
                    values.quantity === '10+' &&
                    (values.typedQuantity === undefined
                      ? true
                      : parseInt(values.typedQuantity) < 11 ||
                        parseInt(values.typedQuantity) > availableStock
                      ? true
                      : false))
                }
                inProgress={cartAddInProgress}
              >
                <FormattedMessage
                  id={
                    isAlreadyAddToCart
                      ? 'ProductOrderForm.alreadyAddToCartButton'
                      : 'ProductOrderForm.addToCartButton'
                  }
                />
              </SecondaryButton>
            </React.Fragment>
          )}
          {isSubmitError && (
            <p className={css.bookRequestFailed}>
              <FormattedMessage id="BookingTimeForm.requestToBookFailed" />
            </p>
          )}
          <PrimaryButton
            type="submit"
            inProgress={submitInProgress || isSubmittingForm}
            disabled={
              submitDisabled ||
              isOwnListing ||
              (availableStock > 10 &&
                values.quantity === '10+' &&
                (values.typedQuantity === undefined
                  ? true
                  : parseInt(values.typedQuantity) < 11 ||
                    parseInt(values.typedQuantity) > availableStock
                  ? true
                  : false))
            }
          >
            {hasStock ? (
              productCategory === 'businessServices' ? (
                <FormattedMessage id="BookingPanel.rentBookButtonMessage" />
              ) : isBuyTypeListings ? (
                <FormattedMessage id="ProductOrderForm.ctaButton" />
              ) : (
                <FormattedMessage id="BookingPanel.rentBookButtonMessage" />
              )
            ) : (
              <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
            )}
          </PrimaryButton>
        </div>
        {isCustomQuoteAllowed && !isOwnListing && (
          <Button
            className={classNames(css.customQuoteBtn)}
            // disabled={isOwnListing}
            onClick={() => setRtqForm(true)}
            type="button"
          >
            Request for custom quote
          </Button>
        )}
        <Modal
          id="orderForm.rtqForm"
          isOpen={rtqForm}
          onClose={() => {
            setRtqForm(false);
            setQuoteSubmitInProgress(false);
            setQuoteSubmitReady(true);
            form.batch(() => {
              form.change('message', '');
              form.change('photos', []);
            });
          }}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.termsWrapper}>
            {/* <FieldTextInput
              type="textarea"
              id={'message'}
              name="message"
              autoComplete="message"
              label={'Message'}
            /> */}

            <div className={css.editor}>
              <Suspense fallback={<div>Loading...</div>}>
                <label>Message:</label>
                <TextEditor
                  name={`message`}
                  placeholder="Type your message here "
                  editorText={editorText}
                  setEditorText={setEditorText}
                  editorContent={editorContent}
                  setEditorContent={setEditorContent}
                  toolbarClassName={css.toolbarClassName}
                  wrapperClassName={css.wrapperClassName}
                  editorClassName={css.editorClassName}
                  onChange={data => formApi.change(`message`, data)}
                />
              </Suspense>
            </div>
            <p>Photos</p>

            <div
              className={css.photoDiv}
              onClick={() => {
                photoRef.current.click();
              }}
            >
              <Field
                id="photos"
                accept="image/*,video/*"
                name="photos"
                label={<span className={css.docWrapper}>+ Upload Photos</span>}
                type="file"
                form={null}
              >
                {fieldProps => {
                  const {
                    accept,
                    id,
                    input: { name, type },
                    label,
                    disabled,
                  } = fieldProps;

                  const onChange = e => {
                    const file = e.target.files[0];
                    form.change(`photos`, [...photos, file]);
                    form.blur(`photos`);

                    if (file != null) {
                      onUploadSingleDocument(file, form, name);
                      const tempId = `${file.name}_${Date.now()}`;

                      e.target.value = '';
                      if (!/safari/i.test(navigator.userAgent)) {
                        e.target.type = '';
                        e.target.type = 'file';
                      }
                    }
                  };

                  return (
                    <div className={css.uploadDocumentWrapper}>
                      <label className={css.labelDocument}>{label}</label>
                      <input
                        ref={photoRef}
                        accept={accept}
                        id={id}
                        name={name}
                        className={css.uploadAvatarInput}
                        disabled={disabled}
                        onChange={onChange}
                        type={type}
                      />
                    </div>
                  );
                }}
              </Field>
              {values?.photos?.length > 0 ? (
                <>
                  {photoAfterUpload?.map((m, indx) => {
                    return (
                      <>
                        <div key={indx} className={css.previewImageWrapper}>
                          <img src={m?.location} className={css.vesselImage} />
                          <button
                            type="button"
                            className={css.removeImg}
                            onClick={() => {
                              const filteredData = photoAfterUpload.filter(
                                f => f?.location !== m.location
                              );
                              setPhotoAfterUpload(filteredData);
                              const deletedData = photoAfterUpload.filter(
                                f => f?.key === m?.key
                              );
                              if (values?.deletedDocAndPhoto) {
                                values.deletedDocAndPhoto = [
                                  ...values?.deletedDocAndPhoto,
                                  ...deletedData,
                                ];
                              } else {
                                values.deletedDocAndPhoto = [...deletedData];
                              }

                              form.change(
                                `photos`,
                                multiplephotos?.length > 0
                                  ? [...filteredData, ...multiplephotos]
                                  : filteredData
                              );
                            }}
                          >
                            X
                          </button>
                        </div>
                      </>
                    );
                  })}
                  {multiplephotos?.map((m, indx) => {
                    return (
                      <>
                        <div key={indx} className={css.previewImageWrapper}>
                          <img
                            src={URL.createObjectURL(m)}
                            className={css.vesselImage}
                          />
                          <button
                            type="button"
                            className={css.removeImg}
                            onClick={() => {
                              const filteredData = multiplephotos.filter(
                                f => f.name !== m.name
                              );
                              setMultiplePhotos(filteredData);
                              form.change(
                                `photos`,
                                photoAfterUpload?.length > 0
                                  ? [...photoAfterUpload, ...filteredData]
                                  : filteredData
                              );
                            }}
                          >
                            X
                          </button>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {multiplephotos?.map((m, indx) => {
                    return (
                      <>
                        <div key={indx} className={css.previewImageWrapper}>
                          <img
                            src={URL.createObjectURL(m)}
                            className={css.vesselImage}
                          />
                          <button
                            type="button"
                            className={css.removeImg}
                            onClick={() => {
                              const filteredData = multiplephotos.filter(
                                f => f.name !== m.name
                              );
                              setMultiplePhotos(filteredData);
                              // form.change(`photos`, filteredData);
                            }}
                          >
                            X
                          </button>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>

            <Button
              className={css.submitBtn}
              type="button"
              inProgress={quoteSubmitInProgress}
              ready={quoteSubmitReady}
              disabled={
                quoteSubmitReady ||
                !values.message ||
                values.message === '<p></p>'
              }
              onClick={async () => {
                setQuoteSubmitInProgress(true);
                const { photos, message: msg } = values;

                const filteredPhoto = photos?.filter(f => f?.key === undefined);

                const documentArray = [{ photos: filteredPhoto }];
                const formData = new FormData();

                documentArray.forEach((doc, index) => {
                  const key = Object.keys(doc)[0];
                  const value = doc[key];
                  if (value) {
                    if (Array.isArray(value)) {
                      value.forEach((file, index) => {
                        formData.append(key, file);
                      });
                    } else {
                      formData.append(key, value);
                    }
                  }
                });
                const data =
                  documentArray.length > 0
                    ? axios.post(
                        `${apiBaseUrl()}/api/listings/rtq/upload`,
                        formData,
                        {
                          headers: {
                            'Content-Type': 'multipart/form-data',
                          },
                        }
                      )
                    : Promise.resolve({ data: [] });
                data.then(async res => {
                  const dataFromS3 = res?.data;
                  const photoFromS3 = dataFromS3.map(
                    ({ location }) => location
                  );
                  const result = await axios.post(
                    `${apiBaseUrl()}/api/rtq/create`,
                    {
                      listingId: listing.id.uuid,
                      msg,
                      photoLinks: photoFromS3,
                    },
                    { withCredentials: true }
                  );
                  setQuoteSubmitInProgress(false);
                  setQuoteSubmitReady(true);
                  setRtqSubmitSuccess(true);
                  setRtqForm(false);
                });
              }}
            >
              <FormattedMessage id="ProductOrderForm.rtqSubmit" />
            </Button>
          </div>
        </Modal>

        <Modal
          id="orderForm.rtqSuccessForm"
          isOpen={rtqSubmitSuccess}
          onClose={() => {
            setRtqSubmitSuccess(false);
            setQuoteSubmitReady(false);
          }}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.termsWrapper}>
            <div className={classNames(css.editor, css.successMsg)}>
              <h3 className={css.termsHeading}>
                <FormattedMessage id="ProductOrderForm.rtqSubmitSuccessHeading" />
              </h3>
              <FormattedMessage id="ProductOrderForm.rtqSubmitSuccessMessage" />
            </div>

            <Button
              className={classNames(css.submitBtn, css.okBtn)}
              type="button"
              onClick={() => {
                setRtqSubmitSuccess(false);
              }}
            >
              <FormattedMessage id="ProductOrderForm.rtqSubmitSuccess" />
            </Button>
          </div>
        </Modal>

        <p className={css.finePrint}>
          {isOwnListing ? (
            <FormattedMessage id="ProductOrderForm.finePrint" />
          ) : !hasStock && showContactUser ? (
            <FormattedMessage
              id="ProductOrderForm.finePrintNoStock"
              values={{ contactSellerLink }}
            />
          ) : null}
        </p>
      </Form>
      {showOneProviderModal && (
        <div className={css.oneProviderRoot}>
          <div className={css.oneProviderWrapper}>
            <button
              type="button"
              className={css.closeBtn}
              onClick={e => setShowOneProviderModal(false)}
            >
              <CrossIcon />
            </button>
            <h1 className={css.heading}>
              {intl.formatMessage({
                id: 'ProductOrderForm.oneProviderHeading',
              })}
            </h1>
            <p className={css.subHeading}>
              {intl.formatMessage({
                id: 'ProductOrderForm.oneProviderSubHeading1',
              })}
            </p>
            <p className={css.subHeading}>
              {intl.formatMessage({
                id: 'ProductOrderForm.oneProviderSubHeading2',
              })}
            </p>
            <button
              type="button"
              className={css.closeTextButton}
              onClick={e => setShowOneProviderModal(false)}
            >
              {intl.formatMessage({ id: 'ProductOrderForm.oneProviderButton' })}
            </button>
          </div>
        </div>
      )}
      {showOneShippingOptionModal && (
        <div className={css.oneProviderRoot}>
          <div className={css.oneProviderWrapper}>
            <button
              type="button"
              className={css.closeBtn}
              onClick={e => setShowOneShippingOptionModal(false)}
            >
              <CrossIcon />
            </button>
            <h1 className={css.heading}>
              {intl.formatMessage({
                id: 'ProductOrderForm.oneShippingOptionHeading',
              })}
            </h1>
            <p className={css.subHeading}>
              {intl.formatMessage({
                id: 'ProductOrderForm.oneShippingOptionSubHeading1',
              })}
            </p>
            <p className={css.subHeading}>
              {intl.formatMessage({
                id: 'ProductOrderForm.oneShippingOptionSubHeading2',
              })}
            </p>
            <button
              type="button"
              className={css.closeTextButton}
              onClick={e => setShowOneShippingOptionModal(false)}
            >
              {intl.formatMessage({ id: 'ProductOrderForm.oneProviderButton' })}
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const ProductOrderFormComponent = props => {
  const {
    price,
    currentStock,
    pickupEnabled,
    shippingEnabled,
    intl,
    listing,
    isClothingCategory,
    isShoeCategory,
    pricingType,
    isBuyTypeListings,
  } = props;

  const [showOneProviderModal, setShowOneProviderModal] = useState(false);
  const [showOneShippingOptionModal, setShowOneShippingOptionModal] = useState(
    false
  );
  const [focusedInput, setFocusedInput] = useState();

  const {
    attributes: {
      publicData: { availability },
    },
  } = listing;

  const [timeSlots, setTimeSlots] = useState([]);
  // useEffect(() => {
  //   let unMounted=false
  //   first

  //   return () => {
  //     second
  //   }
  // }, [])

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (!pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }

  const {
    attributes: {
      publicData: {
        listingType,
        deliveryMethod = [],
        internationalShippingCharge,
        shippingCharge,
        shippingType,
      },
    },
  } = listing;

  const isSingleItemType = listingType === oneItem;

  const hasValidShippingCharge = !!shippingCharge && !!shippingCharge.amount;
  const hasValidInternationalShippingCharge =
    !!internationalShippingCharge && !!internationalShippingCharge.amount;

  const filteredDeliveryMethod = deliveryMethod.filter(dm =>
    dm == deliveryMethodOptions.shipping.value ? hasValidShippingCharge : true
  );

  const unitTypeMaybe = [...textField, ...dateField].includes(pricingType)
    ? {}
    : { unit: pricingType };

  const validShippingTypes =
    hasValidInternationalShippingCharge && hasValidShippingCharge
      ? Object.keys(shippingTypes)
      : hasValidShippingCharge
      ? [shippingTypes.domestic.key]
      : [];

  const hasOneItemLeft = currentStock && currentStock === 1;
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const isMultiSizeType = isClothingCategory || isShoeCategory;

  // const singleDeliveryMethodAvailableMaybe =
  //   filteredDeliveryMethod.length === 0
  //     ? { deliveryMethod: deliveryMethodOptions.pickup.value }
  //     : filteredDeliveryMethod.length > 1
  //     ? {}
  //     : { deliveryMethod: filteredDeliveryMethod[0] };

  const singleDeliveryMethodAvailableMaybe = {
    deliveryMethod: deliveryMethodOptions.pickup.value,
  };

  const sizeMaybe = !isMultiSizeType ? { size: ONE_SIZE } : {};

  const maxAllowedQtyPerSize = getMaxQtyPersize(
    listing,
    isMultiSizeType,
    isClothingCategory,
    ONE_SIZE
  );

  const quantityMaybe = hasOneItemLeft
    ? { quantity: '1' }
    : isMultiSizeType
    ? {}
    : maxAllowedQtyPerSize[ONE_SIZE] === 1
    ? { quantity: '1' }
    : {};

  const initialDeliveryMethod =
    singleDeliveryMethodAvailableMaybe.deliveryMethod;

  const shippingTypesMaybe =
    !validShippingTypes.length ||
    (!!initialDeliveryMethod &&
      initialDeliveryMethod === deliveryMethodOptions.pickup.value)
      ? { shippingType: shippingTypes.domestic.key }
      : validShippingTypes.length === 1
      ? { shippingType: validShippingTypes[0] }
      : {};

  const shippingOption = {
    shippingOption:
      shippingType === deliveryMethodOptions.automaticShipping.value
        ? deliveryMethodOptions.automaticShipping.value
        : deliveryMethodOptions.manualShipping.value,
  };

  const initialValues = {
    ...quantityMaybe,
    ...singleDeliveryMethodAvailableMaybe,
    ...sizeMaybe,
    ...shippingTypesMaybe,
    ...unitTypeMaybe,
    ...shippingOption,
  };

  return (
    <FinalForm
      {...props}
      intl={intl}
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      deliveryMethods={filteredDeliveryMethod}
      shippingOptions={validShippingTypes}
      maxAllowedQtyPerSize={maxAllowedQtyPerSize}
      isMultiSizeType={isMultiSizeType}
      showOneProviderModal={showOneProviderModal}
      setShowOneProviderModal={setShowOneProviderModal}
      showOneShippingOptionModal={showOneShippingOptionModal}
      setShowOneShippingOptionModal={setShowOneShippingOptionModal}
      render={renderForm}
      isSingleItemTyp={isSingleItemType}
      setFocusedInput={setFocusedInput}
      focusedInput={focusedInput}
    />
  );
};

ProductOrderFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  lineItemLabel: {},
  fetchLineItemsError: null,
};

ProductOrderFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,

  // line items
  lineItems: array,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  lineItemLabel: object,

  // other
  onContactUser: func,
};

const ProductOrderForm = compose(
  withRouter,
  injectIntl
)(ProductOrderFormComponent);

export default ProductOrderForm;

// {values && size && listingQuantity == 1 ? (
//   <FieldTextInput
//     id={`${formId}.quantity`}
//     className={css.quantityField}
//     name="quantity"
//     type="hidden"
//     validate={numberAtLeast(quantityRequiredMsg, 1)}
//   />
// ) : (
//   <FieldSelect
//     id={`${formId}.quantity`}
//     className={css.quantityField}
//     name="quantity"
//     disabled={hasQuantityDisabled}
//     label={intl.formatMessage({
//       id: 'ProductOrderForm.quantityLabel',
//     })}
//     validate={numberAtLeast(quantityRequiredMsg, 1)}
//   >
//     <option disabled value="">
//       {intl.formatMessage({
//         id: 'ProductOrderForm.selectQuantityOption',
//       })}
//     </option>
//     {values.size &&
//       Array(listingQuantity)
//         .fill()
//         .map((_, i) => (
//           <option key={i} value={i + 1}>
//             {i + 1}
//           </option>
//         ))}
//   </FieldSelect>
//         )}

// {
//   /*hasNoStockLeft ? null : hasMultipleDeliveryMethods ? (
//       <FieldSelect
//         id={`${formId}.deliveryMethod`}
//         className={css.deliveryField}
//         name="deliveryMethod"
//         disabled={!hasStock}
//         label={intl.formatMessage({
//           id: 'ProductOrderForm.deliveryMethodLabel',
//         })}
//         validate={required(
//           intl.formatMessage({
//             id: 'ProductOrderForm.deliveryMethodRequired',
//           })
//         )}
//       >
//         <option disabled hidden value="">
//           {intl.formatMessage({
//             id: 'ProductOrderForm.selectDeliveryMethodOption',
//           })}
//         </option>
//         <option value={'pickup'}>
//           {intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
//         </option>
//         <option value={'shipping'}>
//           {intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })}
//         </option>
//       </FieldSelect>
//     ) : (
//       <div className={css.deliveryField}>
//         <label>
//           {intl.formatMessage({ id: 'ProductOrderForm.deliveryMethodLabel' })}
//         </label>
//         <p className={css.singleDeliveryMethodSelected}>
//           {values.deliveryMethod === 'shipping'
//             ? intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })
//             : intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
//         </p>
//         <FieldTextInput
//           id={`${formId}.deliveryMethod`}
//           className={css.deliveryField}
//           name="deliveryMethod"
//           type="hidden"
//         />
//       </div>
//     )*/
//}

// {/*<option value={'pickup'}>
//             {intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
//           </option>
//           <option value={'shipping'}>
//             {intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })}
//           </option>*/
//}
