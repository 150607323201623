import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.module.css';
import { ReactComponent as BellIcon } from '../../assets/NotificationOn.svg';
import { ReactComponent as WishlistIcon } from '../../assets/Wishlist.svg';
import { ReactComponent as CartIcon } from '../../assets/Cart.svg';
import { ReactComponent as ProfileIcon } from '../../assets/ProfileIcon.svg';
import CurrencyExchangeDropdown from '../CurrencyExchangeDropdown/CurrencyExchangeDropdown';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    history,
    cartCount,
    location,
  } = props;
  const [mounted, setMounted] = useState(false);
  const { search: locSearch, pathname, hash } = location;
  const currentLocation = `${pathname}${locSearch}${hash}`;

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      history={history}
    />
  );

  // const notificationDot =
  //   notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const notificationDot =
    notificationCount > 0 ? (
      <div className={css.notificationDot}>
        {notificationCount > 9 ? '9+' : notificationCount}
      </div>
    ) : null;
  const cartNotificationDot =
    cartCount > 0 ? (
      <React.Fragment>
        <div className={css.notificationDot}>
          {cartCount > 9 ? '9+' : cartCount}
        </div>
        {/* <div className={css.notificationDot} /> */}
      </React.Fragment>
    ) : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };
  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <React.Fragment>
      <NamedLink
        name="SignupPage"
        className={classNames(css.profileSettingsLink)}
      >
        {/* <span className={css.menuItemBorder} /> */}
        {/* <span className={css.signup}> */}
        <FormattedMessage id="TopbarDesktop.signup" />
        {/* </span> */}
      </NamedLink>
      <span className={css.hDivider} />
    </React.Fragment>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={classNames(css.profileSettingsLink)}>
      {/* <span className={css.menuItemBorder} /> */}
      {/* <span className={css.login}> */}
      <FormattedMessage id="TopbarDesktop.login" />
      {/* </span> */}
    </NamedLink>
  );
  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {/* profile */}
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
            name="ProfileSettingsPage"
          >
            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>

        {/* account */}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('AccountSettingsPage')
            )}
            name="AccountSettingsPage"
          >
            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        {/* message */}
        <MenuItem key="myMessagePage">
          <NamedLink
            // className={css.inboxLink}
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            {/* <span className={css.inbox}>
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDot}
            </span> */}
            <FormattedMessage id="TopbarDesktop.myMessage" />
            {notificationDot}
          </NamedLink>
        </MenuItem>

        {/* my Listings */}
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={css.profileSettingsLink}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="EditListingPage">
          <OwnListingLink
            listing={currentUserListing}
            listingFetched={currentUserListingFetched}
            className={css.profileSettingsLink}
          >
            <div>
            
              {currentUserListing ? (
                <FormattedMessage id="TopbarDesktop.editYourListingLink" />
              ) : (
                <FormattedMessage id="TopbarDesktop.addYourListingLink" />
              )}
            </div>
          </OwnListingLink>
        </MenuItem> */}

        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : (
    <Menu>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <ProfileIcon className={css.profileIcon} />
      </MenuLabel>
      <MenuContent className={css.notlogged}>
        <MenuItem key="signup">
          {/* {signupLink} */}
          <span>{signupLink}</span>
        </MenuItem>
        {/* <MenuItem>
          <span className={css.hDivider} />
        </MenuItem> */}
        <MenuItem key="login">
          {/* {loginLink} */}
          <span>{loginLink}</span>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const listingLink =
    authenticatedOnClientSide &&
    currentUserListingFetched &&
    currentUserListing ? (
      <ListingLink
        className={css.createListingLink}
        listing={currentUserListing}
        children={
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.viewListing" />
          </span>
        }
      />
    ) : null;

  // const createListingLink = isAuthenticatedOrJustHydrated && (
  // !(currentUserListingFetched && !currentUserListing) ? null : (
  const createListingLink = (
    <NamedLink className={css.createListingLink} name="NewListingPage">
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </NamedLink>
  );
  // );
  // );
  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}
      <CurrencyExchangeDropdown />
      {/* {listingLink} */}
      {createListingLink}
      <div className={css.rightIcons}>
        <NamedLink
          name="CartPage"
          className={css.cartIcon}
          to={{ state: { from: currentLocation } }}
        >
          <CartIcon className={css.svgIcons} />
          {cartNotificationDot}
        </NamedLink>

        {/* <BellIcon className={css.svgIcons} /> */}
        <NamedLink name="FavouritesPage" currentUser={currentUser}>
          <WishlistIcon className={css.svgIcons} />
        </NamedLink>
      </div>
      {/* {inboxLink} */}
      <div className={css.profileNotificationDot}>{notificationDot}</div>

      {profileMenu}
      {/* {signupLink}
      {loginLink} */}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
