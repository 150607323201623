import React, { useEffect, useRef, useState } from 'react';
// import { manageDisableScrolling } from '../../ducks/UI.duck';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bool, func, string } from 'prop-types';
import Modal from '../Modal/Modal';
import css from './InfoModal.module.css';
import classNames from 'classnames';

const parentPadding = 4;

function InfoModal(props) {
  const { isOpen, onClose, infoTitle, infoMsg, isError } = props;
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (isOpen && contentRef.current !== null) {
      setHeight(contentRef.current?.clientHeight ?? -1 * parentPadding);
    }
  }, [isOpen, contentRef.current, infoMsg, infoTitle]);

  return (
    <Modal
      id="generalInfoModal"
      onManageDisableScrolling={() => {}}
      isOpen={true}
      onClose={onClose}
      className={classNames(css.generalInfoModalMain, {
        [css.showGeneralInfo]: isOpen,
        [css.incorrectInfoModalMain]: isError,
      })}
      scrollLayerClassName={css.modalScrollLayer}
      containerClassName={css.modalContainer}
      contentClassName={css.modalContent}
      usePortal
      style={{ height: `${height + parentPadding}px` }}
    >
      <div className={css.content} ref={contentRef}>
        <span>
          {!!infoTitle && <span>{infoTitle}</span>}
          <span>{infoMsg}</span>
        </span>
        <button type="button" onClick={onClose}>
          &times;
        </button>
      </div>
    </Modal>
  );
}

InfoModal.propTypes = {
  onManageDisableScrolling: func.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  infoTitle: string.isRequired,
  infoMsg: string.isRequired,
  isError: bool.isRequired,
};

// const mapDispatchToProps = (dispatch) => ({
//   onManageDisableScrolling: (componentId, disableScrolling) =>
//     dispatch(manageDisableScrolling(componentId, disableScrolling)),
// });

export default InfoModal;
