import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { IconReviewUser, Modal } from '../../components';
import { ReviewForm } from '../../forms';

import css from './ReviewModal.module.css';

const ReviewModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmitReview,
    revieweeName,
    reviewSent,
    sendReviewInProgress,
    sendReviewError,
    isReviewMode,
    transaction,
    isReviewByCustomer,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ReviewModal.later' });
  const reviewee = <span className={css.reviewee}>{revieweeName}</span>;

  const handleOnClose = e => {
    e.preventDefault();
    if (sendReviewInProgress) return;
    onCloseModal(e);
  };

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={handleOnClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <IconReviewUser className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage
          id={isReviewMode ? 'ReviewModal.title' : 'ReviewModal.disputetitle'}
          values={{ revieweeName: reviewee }}
        />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage
          id={
            isReviewMode
              ? 'ReviewModal.description'
              : 'ReviewModal.disputeDescription'
          }
        />
      </p>
      {isOpen ? (
        <ReviewForm
          onSubmit={onSubmitReview}
          reviewSent={false}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
          isReviewMode={isReviewMode}
          transaction={transaction}
          isReviewByCustomer={isReviewByCustomer}
        />
      ) : null}
    </Modal>
  );
};

const { bool, string } = PropTypes;

ReviewModal.defaultProps = {
  className: null,
  rootClassName: null,
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
  isReviewMode: true,
  isReviewByCustomer: false,
};

ReviewModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
  isReviewMode: bool.isRequired,
  transaction: propTypes.transaction.isRequired,
  isReviewByCustomer: bool.isRequired,
};

export default injectIntl(ReviewModal);
