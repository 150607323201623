import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { types } from '../../util/sdkLoader';
import { fetchRtqNotifications } from '../../ducks/user.duck';

const { UUID } = types;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST =
  'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS =
  'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR =
  'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_QUOTE_REQUEST = 'app/InboxPage/FETCH_QUOTE_REQUEST';
export const FETCH_QUOTE_SUCCESS = 'app/InboxPage/FETCH_QUOTE_SUCCESS';
export const FETCH_QUOTE_ERROR = 'app/InboxPage/FETCH_QUOTE_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  quoteRequests: [],
  fetchQuoteError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchInProgress: false,
        fetchOrdersOrSalesError: payload,
      };

    case FETCH_QUOTE_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchQuoteDetailsError: null,
        pagination: null,
      };
    case FETCH_QUOTE_SUCCESS: {
      const quoteRequests = payload.data.data;
      return {
        ...state,
        fetchInProgress: false,
        quoteRequests,
        pagination: payload.data.meta,
      };
    }
    case FETCH_QUOTE_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchInProgress: false,
        fetchQuoteError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({
  type: FETCH_ORDERS_OR_SALES_REQUEST,
});
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchQuoteRequest = () => ({
  type: FETCH_QUOTE_REQUEST,
});
const fetchQuoteSuccess = response => ({
  type: FETCH_QUOTE_SUCCESS,
  payload: response,
});
const fetchQuoteError = e => ({
  type: FETCH_QUOTE_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
    quoteRequest: 'quoteRequest',
    customerQuoteRequest: 'customerQuoteRequest',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  const { page = 1 } = parse(search);

  if (
    tab === onlyFilterValues.quoteRequest ||
    tab === onlyFilterValues.customerQuoteRequest
  ) {
    dispatch(fetchQuoteRequest());
    try {
      const data = await axios.get(
        `${apiBaseUrl()}/api/rtq/tab/${onlyFilter}?page=${page}`,
        {
          withCredentials: true,
        }
      );
      const listingIds = data?.data?.data?.map(
        ({ listingId }) => new UUID(listingId)
      );

      const lisitngData = await sdk.listings.query({
        ids: listingIds,
        include: ['author', 'images', 'author.profileImage'],
        'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
        'fields.user': [
          'profile.displayName',
          'profile.abbreviatedName',
          'profile.publicData',
        ],
        'fields.image': [
          'variants.landscape-crop',
          'variants.landscape-crop2x',
          'variants.default',
        ],
        'limit.images': 1,
      });

      dispatch(addMarketplaceEntities(lisitngData));

      dispatch(fetchQuoteSuccess(data));
      dispatch(fetchRtqNotifications());

      return data;
    } catch (e) {
      dispatch(fetchQuoteError(storableError(e)));
      throw e;
    }
  }

  dispatch(fetchOrdersOrSalesRequest());

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: TRANSITIONS,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    'limit.images': 1,
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
