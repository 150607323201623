import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, SecondaryButton } from '../../components';
import { propTypes } from '../../util/types';

import css from './SendMessageForm.module.css';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { ReactComponent as UplaodImg } from '../../assets/Upload.svg';
import { IoWarning } from 'react-icons/io5';

import { marketplaceRegexPatterns } from '../../marketplace-custom-config';

const BLUR_TIMEOUT_MS = 100;
const MAX_IMAGE_SIZE = 20; //20 mb

const CloseBtnIcon = () => (
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-821.000000, -311.000000)">
        <g transform="translate(809.000000, 299.000000)">
          <path
            d="M21.5833333,16.5833333 L17.4166667,16.5833333 L17.4166667,12.4170833 C17.4166667,12.1866667 17.2391667,12 17.00875,12 C16.77875,12 16.5920833,12.18625 16.5920833,12.41625 L16.5883333,16.5833333 L12.4166667,16.5833333 C12.18625,16.5833333 12,16.7695833 12,17 C12,17.23 12.18625,17.4166667 12.4166667,17.4166667 L16.5875,17.4166667 L16.5833333,21.5829167 C16.5829167,21.8129167 16.7691667,21.9995833 16.9991667,22 L16.9995833,22 C17.2295833,22 17.41625,21.81375 17.4166667,21.58375 L17.4166667,17.4166667 L21.5833333,17.4166667 C21.8133333,17.4166667 22,17.23 22,17 C22,16.7695833 21.8133333,16.5833333 21.5833333,16.5833333"
            transform="translate(17.000000, 17.000000) rotate(-45.000000) translate(-17.000000, -17.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
);

const IconSendMessage = () => {
  return (
    <svg
      className={css.sendIcon}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        className={css.strokeMatter}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path d="M12.91 1L0 7.003l5.052 2.212z" />
        <path d="M10.75 11.686L5.042 9.222l7.928-8.198z" />
        <path d="M5.417 8.583v4.695l2.273-2.852" />
      </g>
    </svg>
  );
};

class SendMessageFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUploadLoading: false,
      showErrorMessage: null,
      showError: false,
      maxSizeExceed:false
    };
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    const { onSubmit } = this.props;
    const handleSubmit = (values, form) => {
      const { images = [], ...restValues } = values;
      const formData = new FormData();
      if (images.length > 0) {
        for (let img of images) {
          formData.append('files', img);
        }
      }
      this.setState({ imgUploadLoading: true });

      const imgUpload =
        images.length > 0
          ? axios.post(
              `${apiBaseUrl()}/api/listings/reviews/upload`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            )
          : Promise.resolve({ data: [] });
      imgUpload.then(resp => {
        if (images.length > 0) {
          const uploadedUrl = resp.data;
          if (values.message) {
            restValues.message = JSON.stringify({
              urls: uploadedUrl,
              text: values.message,
            });
          } else {
            restValues.message = JSON.stringify({
              urls: uploadedUrl,
            });
          }
        }
        onSubmit(restValues);
        form.change('message', '');
        form.change('images', '');
        this.setState({ imgUploadLoading: false });
        this.setState({maxSizeExceed:false});
      });
    };
    return (
      <FinalForm
        {...this.props}
        onSubmit={handleSubmit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
            formId,
            touched,
            errors,
            values,
            intl,
          } = formRenderProps;
          const { images = [] } = values;

          const invalidKeywordMsg = intl.formatMessage({
            id: 'SendMessageForm.invalidKeyWord',
          });

          const checkSize = () => {
            this.setState({maxSizeExceed:true});
            // const closeDiv = setTimeout(() => {
            //   this.setState({maxSizeExceed:false});
            // }, 2000);
            // return () => clearTimeout(closeDiv);
          };

          const selectFile = e => {
            const files = e.target && e.target.files && e.target.files;
            if (files && files.length) {
              const filesArr = Array.from(files);
              const reader = new FileReader();
              reader.onload = function() {
                const fileSize = (filesArr[0].size || 0) / (1024 * 1024);
                if (fileSize > MAX_IMAGE_SIZE){
                  checkSize();
                }
                else {
                  const imageFileArr = filesArr
                    .filter(file => /image\/.+/.test(file.type))
                    .map((file, indx) => {
                      const [_, imgExt] = file.type.split('/');
                      const regEx = new RegExp(`.${imgExt}`, 'gi');
                      const imgName = file.name.replace(regEx, '');
                      const newName = `${imgName}-${Date.now()}${indx}.${imgExt}`;
                      return new File([file], newName, {
                        type: file.type,
                        lastModified: file.lastModified,
                        lastModifiedDate: file.lastModifiedDate,
                      });
                    });
                  form.change('images', [...images, ...imageFileArr]);
                  // e.target.value = '';
                  if (!/safari/i.test(navigator.userAgent)) {
                    e.target.type = '';
                    e.target.type = 'file';
                  }
                }
              };
              reader.readAsDataURL(filesArr[0]);
            }
          };

          const handleKeyPress = e => {
            const inputValue = e.target.value;
            const regexPattern = marketplaceRegexPatterns;

            if (regexPattern.test(inputValue)) {
              this.setState({
                showErrorMessage: invalidKeywordMsg,
                showError: true,
              });
            } else if (!regexPattern.test(inputValue)) {
              this.setState({ showErrorMessage: null, showError: false });
            }
          };

          const handleRemoveFile = index => {
            const filteredImages = (images || []).filter(
              (_, indx) => indx !== index
            );
            form.change('images', filteredImages);
          };

          const classes = classNames(rootClassName || css.root, className);

          const submitInProgress = inProgress || this.state.imgUploadLoading;
          const submitDisabled = invalid || submitInProgress;
          return (
            <Form
              className={classes}
              onSubmit={values => handleSubmit(values, form)}
            >
              <FieldTextInput
                inputRootClass={css.textarea}
                // className={classNames(css.textInput, {
                //   [css.invalidInputs]: touched.message && !!errors.message,
                // })}
                onKeyUp={handleKeyPress}
                onKeyDown={handleKeyPress}
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                placeholder={messagePlaceholder}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              {this.state.showError && (
                <div className={css.invalidMessage}>
                  <div>
                    <IoWarning />
                  </div>
                  <div>{this.state.showErrorMessage}</div>
                </div>
              )}

              <div className={css.imageContainer}>
                {images &&
                  images?.map((file, i) => (
                    <div key={i} className={css.previewImageWrapper}>
                      <img
                        src={URL.createObjectURL(file)}
                        className={css.previewImage}
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(i)}
                        className={css.removeImage}
                      >
                        <CloseBtnIcon />
                      </button>
                    </div>
                  ))}
              </div>
              <div className={css.submitContainer}>
                <div className={css.errorContainer}>
                  {sendMessageError ? (
                    <p className={css.error}>
                      <FormattedMessage id="SendMessageForm.sendFailed" />
                    </p>
                  ) : this.state.maxSizeExceed?(
                    <p className={css.error}>
                      <FormattedMessage id="SendMessageForm.checkSize" />
                    </p>
                  ):null}
                </div>
                <div className={css.buttonContainer}>
                <div className={css.uploadButton}>
                  <UplaodImg />
                  <label
                    htmlFor={formId ? `${formId}.sendImg` : 'sendImg'}
                    className={css.uploadLabel}
                  >
                    Upload Image
                  </label>
                  <input
                    name="file"
                    type="file"
                    id={formId ? `${formId}.sendImg` : 'sendImg'}
                    accept="image/*"
                    onChange={selectFile}
                    multiple
                  />
                </div>
                <SecondaryButton
                  rootClassName={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                >
                  <IconSendMessage />
                  <FormattedMessage id="SendMessageForm.sendMessage" />
                </SecondaryButton>
                </div>
                
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SendMessageForm = compose(injectIntl)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
