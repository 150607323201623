import React, { Component, useEffect, useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import MediaQuery from 'react-responsive';
import css from '../SectionBeauty/SectionBeauty.module.css';

import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

// import css from './SectionPopular.module.css';
// import left from '../../../assets/boxLeft.svg';
// import right from '../../../assets/boxRight.svg';

// Update images by saving images to src/LandingPage/SeactionFilteredSearches/images directory.
// If those images have been saved with the same name, no need to make changes to these imports.

import { ReactComponent as Heart } from '../../assets/Wishlist.svg';
import { ReactComponent as ProfileIcon } from '../../assets/ProfileIcon.svg';

import Slider from 'react-slick';
import { PrimaryButton } from '../Button/Button';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { ListingCard } from '..';
import ReviewCard from '../ReviewCard/ReviewCard';
import MonoiProfilePic from '../../assets/MonoiProfilePic.jpg';
import MewMewProfilePic from '../../assets/mewmewProfilepic.jpg';
import LaelProfilePic from '../../assets/Lael_profilepic.jpg';
// Thumbnail image for the search "card"
class ThumbnailImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
// Load the image only if it's close to viewport (user has scrolled the page enough).
const LazyImage = lazyLoadWithDimensions(ThumbnailImage);

// Component that shows full-width section on LandingPage.
// Inside it shows 3 "cards" that link to SearchPage with specific filters applied.
const MonoiReview = (
  <>
    <p>
      Initially, I recall struggling at the time trying to build my own website.
      I was overwhelmed and impatient and just wanted the next milestone in our
      business growth to be ready. I needed to get my products and services
      without the drama. The Siuhuu family were very helpful and answered all my
      questions as they arose before I made the decision to get started.
    </p>
    <p>
      The signup process was self-explanatory, fill in your details and a
      profile so people know who they’re buying from together with the relevant
      bank account information.
    </p>
  </>
);
const DaesynReview = (
  <>
    <p>
      I recently signed up with Siuhuu, and I am thoroughly impressed with the
      platform! The sign-up process was incredibly smooth and user-friendly.
      From the moment I landed on the website, it was clear that Siuhuu
      prioritises a seamless user experience. The registration steps were
      straightforward, and I appreciated the intuitive interface that guided me
      through each stage without any confusion.
    </p>
    <p>
      Adding my listings was a breeze! The platform's design is clean and
      well-organised, making it easy to input details and upload images. The
      listing tools are comprehensive yet simple, allowing me to showcase my
      offerings effectively. I was particularly impressed with how quickly my
      listings went live, giving me immediate exposure.
    </p>
    <p>
      One of the standout advantages of Siuhuu is its focus on supporting the
      Pacific community. The platform provides a valuable space for Pacific
      businesses and individuals to connect and thrive on a global level. It's
      an excellent resource for promoting local products and services, helping
      to strengthen the economic fabric of our community. Siuhuu's dedication to
      this cause is evident in their user support and the tailored features that
      cater to our unique needs.
    </p>
    <p>
      Overall, my experience with Siuhuu has been exceptional. The ease of use,
      efficient listing process, and community focus make it a standout choice.
      I highly recommend Siuhuu to anyone looking to expand their reach and
      support the Pacific community. It's a platform that truly understands and
      caters to our needs.
    </p>
  </>
); 
const reviewDetails = [
  {
    name: 'Monoi Heaven',
    image: MonoiProfilePic,
    reviewModal: MonoiReview,
    reviewInitial: `Initially, I recall struggling at the time trying to build my own website. I was overwhelmed and impatient and just wanted the next milestone in our business growth to be ready. I needed to get my products and services without the drama. The Siuhuu family were very helpful and answered all my questions as they arose before I made the decision to get started.

    The signup process was self-explanatory, fill in your details and a profile so people know who they’re buying from together with the relevant bank account information.`,

  
  },
  {
    name: 'Mewmew Brand',
    image: MewMewProfilePic,
    reviewInitial: `Signing up with Siuhuu was an easy experience! The program they use for you to upload your product images and product details is very simple to use. The instructions are straightforward and easy to follow. If you have any trouble, the Siuhuu team are extremely helpful and ready to assist you.`,
 
  },
  {
    name: `La'ei Designs`,
    image: LaelProfilePic,
    reviewInitial: `The sign up was straightforward to work out. The website is good, and easy to navigate. Adding listings was also easy and straightforward. Overall it was a smooth process from sign up to publishing listings. Love it.`,
 
  },
  {
    name: `Daesyn Lee Art`,
    image: null,
    reviewModal:DaesynReview,
    reviewInitial: `I recently signed up with Siuhuu, and I am thoroughly impressed with the platform! The sign-up process was incredibly smooth and user-friendly. From the moment I landed on the website, it was clear that Siuhuu prioritises a seamless user experience. The registration steps were straightforward, and I appreciated the intuitive interface that guided me through each stage without any confusion.
      Adding my listings was a breeze! The platform's design is clean and well-organised, making it easy to input details and upload images. The listing tools are comprehensive yet simple, allowing me to showcase my offerings effectively. I was particularly impressed with how quickly my listings went live, giving me immediate exposure.
      One of the standout advantages of Siuhuu is its focus on supporting the Pacific community. The platform provides a valuable space for Pacific businesses and individuals to connect and thrive on a global level. It's an excellent resource for promoting local products and services, helping to strengthen the economic fabric of our community. Siuhuu's dedication to this cause is evident in their user support and the tailored features that cater to our unique needs.
      Overall, my experience with Siuhuu has been exceptional. The ease of use, efficient listing process, and community focus make it a standout choice. I highly recommend Siuhuu to anyone looking to expand their reach and support the Pacific community. It's a platform that truly understands and caters to our needs.`,
    
  },
];
const SectionPopular = props => {
  const {
    rootClassName,
    className,
    history,
    recentlyAdded,
    setActiveListing,
    onManageDisableScrolling,
  } = props;

  const [charLimit, setCharLimit] = useState(160);

  const classes = classNames(rootClassName || css.root, className);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    // slidesToShow: recentlyAdded.length < 4 ? recentlyAdded.length : 4,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      // {
      //   breakpoint: 2000,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     arrows: true,
      //     dots: false,
      //   },
      // },
      {
        breakpoint: 1268,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          dots: false,
        },
      },
      // {
      //   breakpoint: 1199,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     arrows: true,
      //     dots: false,
      //   },
      // },
      {
        breakpoint: 1026,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
          dots: false,
        },
      },
      // {
      //   breakpoint: 767,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     arrows: true,
      //     dots: false,
      //   },
      // },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
    ],
  };

  // FilterLink props:
  // - "name" is a string that defines what kind of search the link is going to make
  // - "image" is imported from images directory and you can update it by changing the file
  // - "link" should be copy-pasted URL from search page.
  //    The domain doesn't matter, but search query does. (I.e. "?pub_brand=nike")
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  function setCharacterLimit() {
    const screenWidth = window.innerWidth;
    // // Define your breakpoints and corresponding character limits
    // if (screenWidth < 576) {
    //   setCharLimit(160); // For small screens
    // } else if (screenWidth < 768) {
    //   setCharLimit(210); // For medium screens
    // } else if (screenWidth < 992) {
    //   setCharLimit(230); // For large screens
    // } else {
    //   setCharLimit(280); // For extra large screens
    // }
    const minLimit = 160;
    const maxLimit = 280;
    const minWidth = 320; // Minimum screen width considered
    const maxWidth = 1920; // Maximum screen width considered

    if (screenWidth >= maxWidth) {
      setCharLimit(maxLimit);
    } else {
      // Ensure the screen width is within the expected range
      const clampedWidth = Math.min(Math.max(screenWidth, minWidth), maxWidth);

      // Calculate the percentage of the way the current width is between minWidth and maxWidth
      const widthPercentage = (clampedWidth - minWidth) / (maxWidth - minWidth);

      // Interpolate between minLimit and maxLimit
      setCharLimit(
        Math.floor(minLimit + widthPercentage * (maxLimit - minLimit))
      );
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', setCharacterLimit);
      setCharacterLimit();
      return () => {
        window.removeEventListener('resize', setCharacterLimit);
      };
    }
  }, []);

  return (
    <div className={classes}>
      {/* <div className={css.title}>MOST POPULAR</div> */}
      <div className={css.title}>Seller Reviews</div>
      <div className={`${css.filteredSearches} filteredSearches reviewCards`}>
        <MediaQuery minWidth={576}>
          <Slider {...settings}>
            {/* {recentlyAdded?.map((l, indx) => {
              {
                return (
                  <ListingCard
                    className={css.listingCard}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes}
                    setActiveListing={setActiveListing}
                    history={history}
                  />
                );
              }
            })} */}
            {reviewDetails?.map((m, indx) => {
              return (
                <React.Fragment key={indx}>
                  <ReviewCard
                    onManageDisableScrolling={onManageDisableScrolling}
                    className={css.listingCard}
                    name={m?.name}
                    review={m?.reviewInitial}
                    reviewModalText={m?.reviewModal}
                    image={m?.image}
                    charLimit={charLimit}
                  />
                </React.Fragment>
              );
            })}
          </Slider>
        </MediaQuery>
        <MediaQuery maxWidth={575}>
          {/* {recentlyAdded.length > 4
            ? recentlyAdded
                .map(l => {
                  return (
                    <ListingCard
                      className={css.searchLink}
                      key={l.id.uuid}
                      listing={l}
                      renderSizes={cardRenderSizes}
                      setActiveListing={setActiveListing}
                      history={history}
                    />
                  );
                })
                .slice(0, 4)
            : recentlyAdded.map(l => {
                return (
                  <ListingCard
                    className={css.searchLink}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes}
                    setActiveListing={setActiveListing}
                    history={history}
                  />
                );
              })} */}
          {reviewDetails?.length > 4
            ? reviewDetails
                ?.map((m, indx) => {
                  return (
                    <ReviewCard
                      className={css.listingCard}
                      name={m?.name}
                      review={m?.reviewInitial}
                      reviewModalText={m?.reviewModal}
                      image={m?.image}
                      key={indx}
                      onManageDisableScrolling={onManageDisableScrolling}
                      charLimit={charLimit}
                    />
                  );
                })
                .slice(0, 4)
            : reviewDetails?.map((m, indx) => {
                return (
                  <ReviewCard
                    className={css.listingCard}
                    name={m?.name}
                    review={m?.reviewInitial}
                    reviewModalText={m?.reviewModal}
                    image={m?.image}
                    key={indx}
                    onManageDisableScrolling={onManageDisableScrolling}
                    charLimit={charLimit}
                  />
                );
              })}
        </MediaQuery>
      </div>
      {/* <div className={css.browseBtnDiv}>
        <PrimaryButton
          className={css.browseBtn}
          onClick={e => {
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {}
              )
            );
          }}
        >
          Browse All
        </PrimaryButton>
      </div> */}
    </div>
  );
};

SectionPopular.defaultProps = { rootClassName: null, className: null };

SectionPopular.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPopular;
