/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { CART_LINE_ITEMS, LINE_ITEMS, propTypes } from '../../util/types';
import { ReactComponent as RedirectIcon } from '../../assets/RedirectTo.svg';

import css from './BookingBreakdown.module.css';
import { PriceDataWithDifferentCurrency } from '../../util/PriceDataWithDifferentCurrency';
import NamedLink from '../NamedLink/NamedLink';
import classNames from 'classnames';
import { deliveryMethodOptions } from '../../marketplace-custom-config';
import {
  transitionIsLabelNotCreated,
  txIsPurchased,
  txIsPurchasedForAutoLabel,
} from '../../util/transaction';
import { SecondaryButton } from '../Button/Button';

const LineItemUnknownItemsMaybe = props => {
  const {
    transaction,
    isProvider,
    intl,
    selectedCurrencyExchangeCode,
    selectedCurrency,
    isProductType,
    onCreateLabel,
    onAddDeliveryDate,
    labelCreateInProgress,
    deliveryDateAddInProgress,
    isPlatformShippingDetailFilled,
    isManualShippingDetailFilled,
    isShippingLabelCreated,
  } = props;
  const knownLineItems = isProductType ? CART_LINE_ITEMS : LINE_ITEMS;
  const {
    attributes: {
      protectedData: { lineItemLabel = {}, bookingDetails } = {},
      lineItems,
      lastTransition,
    },
  } = transaction;
  const bookingDetailsObj = bookingDetails.reduce((acc, detail) => {
    acc[detail.listingId] = detail;
    return acc;
  }, {});
  // resolve unknown non-reversal line items
  const allItems = lineItems.filter(
    item => knownLineItems.indexOf(item.code) === -1 && !item.reversal
  );

  const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));

  const categoryItems = items.reduce(
    (acc, item) => {
      const encodedItemCode = encodeURIComponent(item.code);
      const encodedItem = lineItemLabel[encodedItemCode];
      if (encodedItem && encodedItem.id) {
        const bookingDetail = bookingDetailsObj[encodedItem.id];
        if (bookingDetail) {
          if (
            bookingDetail.metadata?.shippingOption ===
            deliveryMethodOptions.automaticShipping.value
          ) {
            acc.automatic.push(item);
          } else {
            acc.manual.push(item);
          }
        } else {
          acc.other.push(item);
        }
      } else {
        acc.other.push(item);
      }
      return acc;
    },
    {
      manual: [],
      automatic: [],
      other: [],
    }
  );

  const showActionButton = transitionIsLabelNotCreated(
    lastTransition,
    isProvider
  );

  const showManualActionButton =
    showActionButton && categoryItems.manual.length > 0;
  const showAutomaticActionButton =
    showActionButton && categoryItems.automatic.length > 0;

  const formatItem = (item, i) => {
    const quantity = item.quantity;

    const encodedItemCode = encodeURIComponent(item.code);

    const hasEncodedItem = lineItemLabel.hasOwnProperty(encodedItemCode);

    const encodedItem = lineItemLabel[encodedItemCode];

    const label = hasEncodedItem
      ? encodedItem.label
      : quantity && quantity > 1
      ? `${humanizeLineItemCode(item.code)} x ${quantity}`
      : humanizeLineItemCode(item.code);

    const { formattedPrice } =
      PriceDataWithDifferentCurrency(
        item.lineTotal,
        intl,
        selectedCurrency,
        selectedCurrencyExchangeCode
      ) || {};
    return (
      <div key={`${i}-item.code`} className={css.lineItem}>
        {hasEncodedItem && !!encodedItem.slug && !!encodedItem.id ? (
          <span>
            <NamedLink
              name="ListingPage"
              params={{ slug: encodedItem.slug, id: encodedItem.id }}
              target="_blank"
              className={classNames(css.itemLabel, {
                [css.linkItemLabel]: hasEncodedItem,
              })}
            >
              {label}
              <RedirectIcon className={css.icon} />
            </NamedLink>
          </span>
        ) : (
          <span className={css.itemLabel}>{label}</span>
        )}
        <span className={css.itemValue}>{formattedPrice}</span>
      </div>
    );
  };

  const handleCreateLabel = e => {
    e.preventDefault();
    if (labelCreateInProgress) return;
    if (typeof onCreateLabel === 'function') {
      onCreateLabel(e, transaction);
    }
  };

  const handleAddDeliveryDate = e => {
    e.preventDefault();
    if (deliveryDateAddInProgress) return;
    if (typeof onAddDeliveryDate === 'function') {
      onAddDeliveryDate(e, transaction);
    }
  };

  return items.length > 0 ? (
    <React.Fragment>
      <div>
        {isProvider && categoryItems.manual.length > 0 ? (
          <p className={css.cateHeading}>
            {deliveryMethodOptions.manualShipping.label}
          </p>
        ) : null}
        <div>{categoryItems.manual.map(formatItem)}</div>
        {showManualActionButton ? (
          <div className={css.actionBtnDiv}>
            <SecondaryButton
              className={css.shippingBtn}
              onClick={handleAddDeliveryDate}
              inProgress={deliveryDateAddInProgress}
            >
              <FormattedMessage
                id={
                  isManualShippingDetailFilled
                    ? 'TransactionPanel.deliveryDateSelectUpdate'
                    : 'TransactionPanel.addDeliveryDate'
                }
              />
            </SecondaryButton>
          </div>
        ) : null}
      </div>
      <div>
        {isProvider && categoryItems.automatic.length > 0 ? (
          <p className={css.cateHeading}>
            {deliveryMethodOptions.automaticShipping.label}
          </p>
        ) : null}
        <div>{categoryItems.automatic.map(formatItem)}</div>
        {showAutomaticActionButton ? (
          <div className={css.actionBtnDiv}>
            <SecondaryButton
              className={css.shippingBtn}
              onClick={handleCreateLabel}
              inProgress={labelCreateInProgress}
            >
              <FormattedMessage
                id={
                  isShippingLabelCreated
                    ? 'TransactionPanel.downloadLabel'
                    : 'TransactionPanel.createLabel'
                }
              />
            </SecondaryButton>
          </div>
        ) : null}
      </div>
      <div>
        <div>{categoryItems.other.map(formatItem)}</div>
      </div>
    </React.Fragment>
  ) : null;
};

LineItemUnknownItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
