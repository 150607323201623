import React from 'react';
import { string } from 'prop-types';
import { getTimeZoneNames } from '../../util/dates';
import { FieldSelect } from '../../components';
import css from './FieldTimeZoneSelect.module.css';
import { FormattedMessage } from 'react-intl';
const FieldTimeZoneSelect = props => {
  // IANA database contains irrelevant time zones too.
  const relevantZonesPattern = new RegExp(
    '^(Africa|America(?!/(Argentina/ComodRivadavia|Knox_IN|Nuuk))|Antarctica(?!/(DumontDUrville|McMurdo))|Asia(?!/Qostanay)|Atlantic|Australia(?!/(ACT|LHI|NSW))|Europe|Indian|Pacific)'
  );

  return (
    <div className={css.timezoneSelectDiv}>
      <label className={css.timezoneLabel}>
        <FormattedMessage id="EditListingAvailabilityPlanForm.timezonePickerTitle" />
      </label>
      <FieldSelect {...props} className={css.timezoneSelect}>
        <option disabled value="">
          Pick something...
        </option>
        {getTimeZoneNames(relevantZonesPattern).map(tz => (
          <option key={tz} value={tz}>
            {tz}
          </option>
        ))}
      </FieldSelect>
    </div>
  );
};

FieldTimeZoneSelect.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
};

FieldTimeZoneSelect.propTypes = {
  rootClassName: string,
  className: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,
  name: string.isRequired,
};

export default FieldTimeZoneSelect;
