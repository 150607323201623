import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import MediaQuery from 'react-responsive';
import css from '../SectionBeauty/SectionBeauty.module.css';

import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

// import css from './SectionTraditional.module.css';
// import left from '../../../assets/boxLeft.svg';
// import right from '../../../assets/boxRight.svg';

// Update images by saving images to src/LandingPage/SeactionFilteredSearches/images directory.
// If those images have been saved with the same name, no need to make changes to these imports.

import { ReactComponent as Heart } from '../../assets/Wishlist.svg';
import { ReactComponent as ProfileIcon } from '../../assets/ProfileIcon.svg';

import Slider from 'react-slick';
import { PrimaryButton } from '../Button/Button';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createSlug } from '../../util/urlHelpers';
import ListingCard from '../ListingCard/ListingCard';
const { Money } = sdkTypes;

// Thumbnail image for the search "card"
class ThumbnailImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
// Load the image only if it's close to viewport (user has scrolled the page enough).
const LazyImage = lazyLoadWithDimensions(ThumbnailImage);

// Component that shows full-width section on LandingPage.
// Inside it shows 3 "cards" that link to SearchPage with specific filters applied.
const SectionTraditional = props => {
  const {
    rootClassName,
    className,
    history,
    traditionalListings,
    intl,
    setActiveListing,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      traditionalListings.length < 4 ? traditionalListings.length : 4,

    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow:
            traditionalListings.length < 4 ? traditionalListings.length : 3,
          slidesToScroll: 3,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow:
            traditionalListings.length < 4 ? traditionalListings.length : 2,
          slidesToScroll: 2,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow:
            traditionalListings.length < 4 ? traditionalListings.length : 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
    ],
  };

  // FilterLink props:
  // - "name" is a string that defines what kind of search the link is going to make
  // - "image" is imported from images directory and you can update it by changing the file
  // - "link" should be copy-pasted URL from search page.
  //    The domain doesn't matter, but search query does. (I.e. "?pub_brand=nike")

  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  return (
    <div className={classes}>
      <div className={css.title}>Traditional items</div>
      <div className={`${css.filteredSearches} filteredSearches`}>
        <MediaQuery minWidth={768}>
          <Slider {...settings}>
            {traditionalListings?.map((l, indx) => {
              {
                return (
                  <ListingCard
                    className={css.listingCard}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes}
                    setActiveListing={setActiveListing}
                    history={history}
                  />
                );
              }
            })}
          </Slider>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          {traditionalListings.length > 4
            ? traditionalListings
                .map(l => {
                  return (
                    <ListingCard
                      className={css.searchLink}
                      key={l.id.uuid}
                      listing={l}
                      renderSizes={cardRenderSizes}
                      setActiveListing={setActiveListing}
                      history={history}
                    />
                  );
                })
                .slice(0, 4)
            : traditionalListings.map(l => {
                return (
                  <ListingCard
                    className={css.searchLink}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes}
                    setActiveListing={setActiveListing}
                    history={history}
                  />
                );
              })}
        </MediaQuery>
      </div>
      <div className={css.browseBtnDiv}>
        <PrimaryButton
          className={css.browseBtn}
          onClick={e => {
            const params = {
              pub_productCategory: 'traditional',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          Browse All
        </PrimaryButton>
      </div>
    </div>
  );
};

SectionTraditional.defaultProps = { rootClassName: null, className: null };

SectionTraditional.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTraditional;
