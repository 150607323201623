import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated 27/02/2024</p>
      <p>Here are our terms and conditions (“Siuhuu General Terms”)</p>
      <p>User Agreement: www.siuhuu.com</p>
      <p>
        Siuhuu Pty Ltd ACN 663 566 747 (“Siuhuu”, “we” or “us”) operates an
        online platform allowing users (“User” or “You”) to buy and sell
        products, rent accommodation and car, through Siuhuu’s platform
        including through our website at www.siuhuu.com or any other
        platform we may introduce in the future.
      </p>
      <p>
        PLEASE READ THESE TERMS and ALL SIUHUU POLICIES including the Siuhuu
        Privacy Policy carefully before using the services offered by Siuhuu.
      </p>
      <div>
        <div>
          <h2 className={css.heading}>Interpretation and Definitions</h2>
          <span className={css.subHeadings}>Interpretation</span>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions.
          </p>
          <p>
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or in plural.
          </p>
        </div>
        <div>
          <h2 className={css.heading}>Definitions</h2>
          <p>For the purposes of these Terms and Conditions:</p>
          <p>
            <b className={css.boldText}>Account</b> means a unique account
            created for You to access our Service or parts of our Service.
          </p>
          <p>
            <b className={css.boldText}>Content </b>refers to content such as
            text, images, or other information that can be posted, uploaded,
            linked to or otherwise made available by You, regardless of the form
            of that content.
          </p>
          <p>
            <b className={css.boldText}>Device </b>means any device that can
            access the Service such as a computer, a cellphone or a digital
            tablet.
          </p>
          <p>
            <b className={css.boldText}>Feedback</b> means feedback, innovations
            or suggestions sent by You regarding the attributes, performance or
            features of our Service.
          </p>
          <p>
            <b className={css.boldText}>Products</b> refer to the items offered
            for sale on the Service.
          </p>
          <p>
            <b className={css.boldText}>Orders</b> mean a request by You to
            purchase Goods from Us.
          </p>
          <p>
            <b className={css.boldText}>Service</b> refers to the Website.
          </p>
          <p>
            <b className={css.boldText}> Terms and Conditions </b>(also referred
            as "Terms") mean these Terms and Conditions that form the entire
            agreement between You and Siuhuu regarding the use of the Service.
          </p>
          <p>
            <b className={css.boldText}>Third-party Social Media Service</b>{' '}
            means any services or content (including data, information, products
            or services) provided by a third-party that may be displayed,
            included or made available by the Service.
          </p>
          <p>
            <b className={css.boldText}>Website</b> refers to Siuhuu, accessible
            from https://www.siuhuu.com/
          </p>
          <p>
            <b className={css.boldText}>You</b> means the individual accessing
            or using the Service, or Siuhuu or other legal entity on behalf of
            which such individual is accessing or using the Service, as
            applicable.
          </p>
        </div>
        <div>
          <h2 className={css.heading}>Acknowledgement</h2>
          <p>
            These are the Terms and Conditions governing the use of this Service
            and the agreement that operates between You and Siuhuu. These Terms
            and Conditions set out the rights and obligations of all users
            regarding the use of the Service.
          </p>
          <p>
            Your access to and use of the Service is conditioned on Your
            acceptance of and compliance with these Terms and Conditions. These
            Terms and Conditions apply to all visitors, users and others who
            access or use the Service.
          </p>
          <p>
            By accessing or using the Service You agree to be bound by these
            Terms and Conditions. If You disagree with any part of these Terms
            and Conditions then You may not access the Service.
          </p>
          <p>
            You represent that you are over the age of 18. Siuhuu does not
            permit those under 18 to use the Service.
          </p>
          <p>
            Your access to and use of the Service is also conditioned on Your
            acceptance of and compliance with the Privacy Policy of Siuhuu. Our
            Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your personal information when You
            use the Application or the Website and tells You about Your privacy
            rights and how the law protects You. Please read Our Privacy Policy
            carefully before using Our Service.
          </p>
        </div>

        <div>
          <h2 className={css.heading}>Placing Orders for Products</h2>
          <p>
            By placing an Order for Products through the Service, You warrant
            that You are legally capable of entering into binding contracts.
          </p>
          <div>
            {' '}
            <span className={css.subHeadings}>Your Information</span>
            <p>
              If You wish to place an Order for Products available on the
              Service, You may be asked to supply certain information relevant
              to Your Order including, without limitation, Your name, Your
              email, Your phone number, Your credit card number, the expiration
              date of Your credit card, Your billing address, and Your shipping
              information.
            </p>
            <p>
              You represent and warrant that: (i) You have the legal right to
              use any credit or debit card(s) or other payment method(s) in
              connection with any Order; and that (ii) the information You
              supply to us is true, correct and complete.
            </p>
            <p>
              By submitting such information, You grant us the right to provide
              the information to payment processing third parties for purposes
              of facilitating the completion of Your Order.
            </p>
          </div>
          <div>
            <span className={css.subHeadings}>Order Cancellation</span>
            <p>
              We reserve the right to refuse or cancel Your Order at any time
              for certain reasons including but not limited to:
            </p>

            <span className={css.boldTitle}>Products availability</span>

            <li className={css.noBulletList}>Errors in Your Order</li>
            <li className={css.noBulletList}>Errors in Your Order</li>
            <li className={css.noBulletList}>
              We reserve the right to refuse or cancel Your Order if fraud or an
              unauthorized or illegal transaction is suspected.
            </li>
          </div>
          <div>
            <span className={css.subHeadings}>
              Your Order Cancellation Rights
            </span>
            <p>
              Any Products you purchase can only be returned in accordance with
              these Terms and Conditions and Our Returns Policy.
            </p>
            <p>
              Our Returns Policy forms a part of these Terms and Conditions.
              Please read our Returns Policy to learn more about your right to
              cancel Your Order.
            </p>
            <p>
              Your right to cancel an Order only applies to Products that are
              returned in the same condition as You received them. You should
              also include all of the products instructions, documents and
              wrappings. Products that are damaged or not in the same condition
              as You received them or which are worn simply beyond opening the
              original packaging will not be refunded. You should therefore take
              reasonable care of the purchased Products while they are in Your
              possession.
            </p>
            <p>
              We will reimburse You no later than 14 days from the day on which
              We receive the returned Products. We will use the same means of
              payment as You used for the Order, and You will not incur any fees
              for such reimbursement.
            </p>
            <div>
              <span className={css.boldTitle}>
                You will not have any right to cancel an Order for the supply of
                any of the following Products:
              </span>
              <li className={css.noBulletList}>
                The supply of Products made to Your specifications or clearly
                personalized.
              </li>
              <li className={css.noBulletList}>
                The supply of Products which according to their nature are not
                suitable to be returned, deteriorate rapidly or where the date
                of expiry is over.
              </li>
              <li className={css.noBulletList}>
                The supply of Products which are not suitable for return due to
                health protection or hygiene reasons and were unsealed after
                delivery.
              </li>
              <li className={css.noBulletList}>
                The supply of Products which are, after delivery, according to
                their nature, inseparably mixed with other items.
              </li>
              <li className={css.noBulletList}>
                The supply of digital content which is not supplied on a
                tangible medium if the performance has begun with Your prior
                express consent and You have acknowledged Your loss of
                cancellation right.
              </li>
            </div>
          </div>
          <div>
            <span className={css.subHeadings}>
              Availability, Errors and Inaccuracies
            </span>
            <p>
              Availability, Errors and Inaccuracies We are constantly updating
              Our offerings of Products on the Service. The Products available
              on Our Service may be mispriced, described inaccurately, or
              unavailable, and We may experience delays in updating information
              regarding our Products on the Service and in Our advertising on
              other websites.
            </p>
            <p>
              {' '}
              We cannot and do not guarantee the accuracy or completeness of any
              information, including prices, product images, specifications,
              availability, and services. We reserve the right to change or
              update information and to correct errors, inaccuracies, or
              omissions at any time without prior notice.
            </p>
          </div>
          <div>
            <span className={css.subHeadings}>Prices Policy</span>
            <p>
              Siuhuu reserves the right to revise its prices at any time prior
              to accepting an Order.
            </p>
            <p>
              The prices quoted may be revised by Siuhuu subsequent to accepting
              an Order in the event of any occurrence affecting delivery caused
              by government action, variation in customs duties, increased
              shipping charges, higher foreign exchange costs and any other
              matter beyond the control of Siuhuu. In that event, You will have
              the right to cancel Your Order.
            </p>
          </div>
          <div>
            <span className={css.subHeadings}>Payments</span>
            <p>
              All Products purchased are subject to a one-time payment. Payment
              can be made through various payment methods we have available,
              such as Visa, MasterCard, American Express cards or online payment
              methods (Stripe, PayPal).
            </p>
            <p>
              Payment cards (credit cards or debit cards) are subject to
              validation checks and authorization by Your card issuer. If we do
              not receive the required authorization, We will not be liable for
              any delay or non-delivery of Your Order.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>User Accounts</h2>
            <p>
              When You create an account with Us, You must provide Us
              information that is accurate, complete, and current at all times.
              Failure to do so constitutes a breach of the Terms, which may
              result in immediate termination of Your account on Our Service.
            </p>
            <p>
              You are responsible for safeguarding the password that You use to
              access the Service and for any activities or actions under Your
              password, whether Your password is with Our Service or a
              Third-Party Social Media Service.
            </p>
            <p>
              You agree not to disclose Your password to any third party. You
              must notify Us immediately upon becoming aware of any breach of
              security or unauthorized use of Your account.
            </p>
            <p>
              You may not use as a username the name of another person or entity
              or that is not lawfully available for use, a name or trademark
              that is subject to any rights of another person or entity other
              than You without appropriate authorization, or a name that is
              otherwise offensive, vulgar or obscene.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>Content</h2>
            <span className={css.subHeadings}>Your Right to Post Content</span>
            <p>
              Our Service allows You to post Content. You are responsible for
              the Content that You post to the Service, including its legality,
              reliability, and appropriateness.
            </p>
            <p>
              By posting Content to the Service, You grant Us the right and
              license to use, modify, publicly perform, publicly display,
              reproduce, and distribute such Content on and through the Service.
              You retain any and all of Your rights to any Content You submit,
              post or display on or through the Service and You are responsible
              for protecting those rights. You agree that this license includes
              the right for Us to make Your Content available to other users of
              the Service, who may also use Your Content subject to these Terms.
            </p>
            <p>
              You represent and warrant that: (i) the Content is Yours (You own
              it) or You have the right to use it and grant Us the rights and
              license as provided in these Terms, and (ii) the posting of Your
              Content on or through the Service does not violate the privacy
              rights, publicity rights, copyrights, contract rights or any other
              rights of any person.
            </p>
            <div>
              <span className={css.subHeadings}>Content Restrictions</span>
              <p>
                Siuhuu is not responsible for the content of the Service's
                users. You expressly understand and agree that You are solely
                responsible for the Content and for all activity that occurs
                under your account, whether done so by You or any third person
                using Your account.
              </p>
              <p>
                You may not transmit any Content that is unlawful, offensive,
                upsetting, intended to disgust, threatening, libelous,
                defamatory, obscene or otherwise objectionable. Examples of such
                objectionable Content include, but are not limited to, the
                following:
              </p>
              <p>
                Unlawful or promoting unlawful activity. Defamatory,
                discriminatory, or mean-spirited content, including references
                or commentary about religion, race, sexual orientation, gender,
                national/ethnic origin, or other targeted groups. Spam, machine
                – or randomly – generated, constituting unauthorized or
                unsolicited advertising, chain letters, any other form of
                unauthorized solicitation, or any form of lottery or gambling.
                Containing or installing any viruses, worms, malware, trojan
                horses, or other content that is designed or intended to
                disrupt, damage, or limit the functioning of any software,
                hardware or telecommunications equipment or to damage or obtain
                unauthorized access to any data or other information of a third
                person.
              </p>
              <p>
                Infringing on any proprietary rights of any party, including
                patent, trademark, trade secret, copyright, right of publicity
                or other rights. Impersonating any person or entity including
                Siuhuu and its employees or representatives. Violating the
                privacy of any third person. False information and features.
              </p>
              <p>
                Siuhuu reserves the right, but not the obligation, to, in its
                sole discretion, determine whether or not any Content is
                appropriate and complies with this Terms, refuse or remove this
                Content. Siuhuu further reserves the right to make formatting
                and edits and change the manner any Content. Siuhuu can also
                limit or revoke the use of the Service if You post such
                objectionable Content. As Siuhuu cannot control all content
                posted by users and/or third parties on the Service, you agree
                to use the Service at your own risk. You understand that by
                using the Service You may be exposed to content that You may
                find offensive, indecent, incorrect or objectionable, and You
                agree that under no circumstances will Siuhuu be liable in any
                way for any content, including any errors or omissions in any
                content, or any loss or damage of any kind incurred as a result
                of your use of any content.
              </p>
            </div>
            <div>
              <span className={css.subHeadings}>Content Backups</span>
              <p>
                Although regular backups of Content are performed, Siuhuu do not
                guarantee there will be no loss or corruption of data.
              </p>
              <p>
                Corrupt or invalid backup points may be caused by, without
                limitation, Content that is corrupted prior to being backed up
                or that changes during the time a backup is performed.
              </p>
              <p>
                Siuhuu will provide support and attempt to troubleshoot any
                known or discovered issues that may affect the backups of
                Content. But You acknowledge that Siuhuu has no liability
                related to the integrity of Content or the failure to
                successfully restore Content to a usable state.
              </p>
              <p>
                You agree to maintain a complete and accurate copy of any
                Content in a location independent of the Service.
              </p>
            </div>
          </div>
          <div>
            <h2 className={css.heading}>Copyright Policy</h2>
            <div>
              <span className={css.subHeadings}>
                Intellectual Property Infringement
              </span>
              <p>
                We respect the intellectual property rights of others. It is Our
                policy to respond to any claim that Content posted on the
                Service infringes a copyright or other intellectual property
                infringement of any person.
              </p>
              <p>
                If You are a copyright owner, or authorized on behalf of one,
                and You believe that the copyrighted work has been copied in a
                way that constitutes copyright infringement that is taking place
                through the Service, You must submit Your notice in writing to
                the attention of our copyright agent via email at
                contact@siuhuu.com and include in Your notice a detailed
                description of the alleged infringement.
              </p>
              <p>
                You may be held accountable for damages (including costs and
                attorneys' fees) for misrepresenting that any Content is
                infringing Your copyright.
              </p>
            </div>
          </div>
          <div>
            <h2 className={css.heading}>Intellectual Property</h2>
            <p>
              The Service and its original content (excluding Content provided
              by You or other users), features and functionality are and will
              remain the exclusive property of Siuhuu and its licensors.
            </p>
            <p>
              The Service is protected by copyright, trademark, and other laws
              of both the Country and foreign countries.
            </p>
            <p>
              Our trademarks and trade dress may not be used in connection with
              any product or service without the prior written consent of
              Siuhuu.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>Your Feedback to Us</h2>
            <p>
              You assign all rights, title and interest in any Feedback You
              provide Siuhuu. If for any reason such assignment is ineffective,
              You agree to grant Siuhuu a non-exclusive, perpetual, irrevocable,
              royalty free, worldwide right and licence to use, reproduce,
              disclose, sub-licence, distribute, modify and exploit such
              Feedback without restriction.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>Termination</h2>
            <p>
              We may terminate or suspend Your Account immediately, without
              prior notice or liability, for any reason whatsoever, including
              without limitation if You breach these Terms and Conditions.
            </p>
            <p>
              Upon termination, Your right to use the Service will cease
              immediately. If You wish to terminate Your Account, You may simply
              discontinue using the Service.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>Limitation of Liability</h2>
            <p>
              Notwithstanding any damages that You might incur, the entire
              liability of Siuhuu and any of its suppliers under any provision
              of this Terms and Your exclusive remedy for all of the foregoing
              shall be limited to the amount actually paid by You through the
              Service or 100 USD if You haven't purchased anything through the
              Service.
            </p>
            <p>
              To the maximum extent permitted by applicable law, in no event
              shall Siuhuu or its suppliers be liable for any special,
              incidental, indirect, or consequential damages whatsoever
              (including, but not limited to, damages for loss of profits, loss
              of data or other information, for business interruption, for
              personal injury, loss of privacy arising out of or in any way
              related to the use of or inability to use the Service, third-party
              software and/or third-party hardware used with the Service, or
              otherwise in connection with any provision of this Terms), even if
              Siuhuu or any supplier has been advised of the possibility of such
              damages and even if the remedy fails of its essential purpose.
            </p>
            <p>
              Some states do not allow the exclusion of implied warranties or
              limitation of liability for incidental or consequential damages,
              which means that some of the above limitations may not apply. In
              these states, each party's liability will be limited to the
              greatest extent permitted by law.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>
              "AS IS" and "AS AVAILABLE" Disclaimer
            </h2>
            <p>
              The Service is provided to You "AS IS" and "AS AVAILABLE" and with
              all faults and defects without warranty of any kind. To the
              maximum extent permitted under applicable law, Siuhuu, on its own
              behalf and on behalf of its Affiliates and its and their
              respective licensors and service providers, expressly disclaims
              all warranties, whether express, implied, statutory or otherwise,
              with respect to the Service, including all implied warranties of
              merchantability, fitness for a particular purpose, title and
              non-infringement, and warranties that may arise out of course of
              dealing, course of performance, usage or trade practice. Without
              limitation to the foregoing, Siuhuu provides no warranty or
              undertaking, and makes no representation of any kind that the
              Service will meet Your requirements, achieve any intended results,
              be compatible or work with any other software, applications,
              systems or services, operate without interruption, meet any
              performance or reliability standards or be error free or that any
              errors or defects can or will be corrected.
            </p>
            <p>
              Without limiting the foregoing, neither Siuhuu nor any of Siuhuu's
              provider makes any representation or warranty of any kind, express
              or implied: (i) as to the operation or availability of the
              Service, or the information, content, and materials or products
              included thereon; (ii) that the Service will be uninterrupted or
              error-free; (iii) as to the accuracy, reliability, or currency of
              any information or content provided through the Service; or (iv)
              that the Service, its servers, the content, or e-mails sent from
              or on behalf of Siuhuu are free of viruses, scripts, trojan
              horses, worms, malware, timebombs or other harmful components.
            </p>
            <p>
              Some jurisdictions do not allow the exclusion of certain types of
              warranties or limitations on applicable statutory rights of a
              consumer, so some or all of the above exclusions and limitations
              may not apply to You. But in such a case the exclusions and
              limitations set forth in this section shall be applied to the
              greatest extent enforceable under applicable law.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>Governing Law</h2>
            <p>
              The laws of the Country, excluding its conflicts of law rules,
              shall govern this Terms and Your use of the Service. Your use of
              the Application may also be subject to other local, state,
              national, or international laws.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>Disputes Resolution</h2>
            <p>
              If You have any concern or dispute about the Service, You agree to
              first try to resolve the dispute informally by contacting Siuhuu.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>Severability and Waiver</h2>
            <div>
              <span className={css.subHeadings}>Severability</span>
              <p>
                If any provision of these Terms is held to be unenforceable or
                invalid, such provision will be changed and interpreted to
                accomplish the objectives of such provision to the greatest
                extent possible under applicable law and the remaining
                provisions will continue in full force and effect.
              </p>
              <span className={css.subHeadings}>Waiver</span>

              <p>
                Except as provided herein, the failure to exercise a right or to
                require performance of an obligation under this Terms shall not
                effect a party's ability to exercise such right or require such
                performance at any time thereafter nor shall be the waiver of a
                breach constitute a waiver of any subsequent breach.
              </p>
            </div>
          </div>
          <div>
            <h2 className={css.heading}>Translation Interpretation</h2>
            <p>
              These Terms and Conditions may have been translated if We have
              made them available to You on our Service. You agree that the
              original English text shall prevail in the case of a dispute.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>
              Changes to These Terms and Conditions
            </h2>
            <p>
              We reserve the right, at Our sole discretion, to modify or replace
              these Terms at any time. If a revision is material We will make
              reasonable efforts to provide at least 30 days' notice prior to
              any new terms taking effect. What constitutes a material change
              will be determined at Our sole discretion.
            </p>
            <p>
              By continuing to access or use Our Service after those revisions
              become effective, You agree to be bound by the revised terms. If
              You do not agree to the new terms, in whole or in part, please
              stop using the website and the Service.
            </p>
          </div>
          <div>
            <h2 className={css.heading}>Contact Us</h2>
            <p>
              If you have any questions about these Terms and Conditions, You
              can contact us by visiting our contact page.
            </p>
           
          </div>
          
        </div>
      </div>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
