import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  array,
  bool,
  func,
  node,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import {
  ModalInMobile,
  Button,
  InlineTextButton,
  NamedLink,
  Reviews,
} from '../../components';
import { BookingTimeForm, ProductOrderForm } from '../../forms';
import { ReactComponent as RedirectIcon } from '../../assets/RedirectTo.svg';

import css from './BookingPanel.module.css';
import SectionAvatar from '../../containers/ListingPage/SectionAvatar';
import { ReactComponent as StarSvg } from '../../assets/ReviewStar.svg';
import visaPng from '../../assets/Visa.png';
import MasterCardPng from '../../assets/Mastercard.png';
import AmericanExpressPng from '../../assets/American.png';
import PaypalPng from '../../assets/Paypal.png';
import AfterPay from '../../assets/afterpay.png';

import {
  allowedPricingTypeOptions,
  productTypeCategories,
} from '../../marketplace-custom-config';
import { PriceDataWithDifferentCurrency } from '../../util/PriceDataWithDifferentCurrency';
import { connect } from 'react-redux';
import SectionReviews from '../../containers/ListingPage/SectionReviews';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { types } from '../../util/sdkLoader';
const { Money } = types;

// import { ReactComponent as VisaSvg } from '../../assets/VisaLogo.svg';
// import { ReactComponent as MasterCardSvg } from '../../assets/Mastercard.svg';
// import { ReactComponent as AmericanExpressSvg } from '../../assets/American-Express.svg';
// import { ReactComponent as PaypalSvg } from '../../assets/PayPal.svg';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

// const priceData = (price, intl) => {
//   if (price && price.currency === config.currency) {
//     const formattedPrice = formatMoney(intl, price);
//     return { formattedPrice, priceTitle: formattedPrice };
//   } else if (price) {
//     return {
//       formattedPrice: `(${price.currency})`,
//       priceTitle: `Unsupported currency (${price.currency})`,
//     };
//   }
//   return {};
// };

const dateFormattingOptions = {
  month: 'short',
  day: 'numeric',
  weekday: 'short',
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onFetchProductTransactionLineItems,
    lineItems,
    lineItemLabel,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    currentAuthor,
    params,
    showContactUser,
    onContactUser,
    cartAddInProgress,
    cartAddError,
    onAddCart,
    currentUser,
    selectedCurrency,
    selectedCurrencyExchangeCode,
    reviews,
    fetchReviewsError,
    isSubmittingForm,
    isSubmitError,
    showSellerInfo,
    showCompanyName,
  } = props;
  // console.log(454, listing);
  const [isBook, setIsBook] = useState(!!parse(location.search).book);
  const listingAuthorId = listing?.author?.id?.uuid;
  const listingAuthorUserName =
    listing?.author?.attributes?.profile?.publicData?.userName;
  const pricingType = listing?.attributes?.publicData?.pricingType;
  const price = listing.attributes.price;

  const timeZone =
    listing.attributes.availabilityPlan &&
    listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed =
    hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;

  const publicData =
    listing && listing.attributes && listing.attributes.publicData;
  const isProductType = productTypeCategories.includes(
    publicData.productCategory
  );
  const showClosedListingHelpText = listing.id && isClosed;
  // const { formattedPrice, priceTitle } = priceData(price, intl);
  const { formattedPrice, priceTitle } =
    PriceDataWithDifferentCurrency(
      price,
      intl,
      selectedCurrency,
      selectedCurrencyExchangeCode
    ) || {};

  //special Price
  const specialPrice = listing?.attributes?.publicData?.specialPrice;
  const formattedSpecialPrice =
    specialPrice && specialPrice.amount && specialPrice.currency
      ? PriceDataWithDifferentCurrency(
          new Money(specialPrice.amount, specialPrice.currency),
          intl,
          selectedCurrency,
          selectedCurrencyExchangeCode
        )?.formattedPrice
      : null;

  // const isBook = !!parse(location.search).book;

  const currentStock = listing.currentStock?.attributes?.quantity;
  // console.log(898, listing, currentStock);
  const isOutOfStock =
    config.listingManagementType === 'stock' && currentStock === 0;
  // console.log(787, isOutOfStock);
  const productCategory = publicData && publicData.productCategory;
  const isClothingCategory = productCategory === 'clothing';
  const isShoeCategory = productCategory === 'shoes';
  const clothingSize = publicData && publicData.clothingSize;
  const clothingStockPerSize = publicData && publicData.clothingStockPerSize;
  const shoeSize = publicData && publicData.shoeSize;
  const shoeStockPerSize = publicData && publicData.shoeStockPerSize;

  const showForm = hasListingState && !isClosed;
  const showProductOrderForm = true;

  const { pickupEnabled = true, shippingEnabled } =
    listing?.attributes?.publicData || {};

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);
  const authorDisplayName =
    listing?.author?.attributes?.profile?.publicData?.companyName;

  const defalutDisplayName = listing?.author?.attributes?.profile?.displayName;

  const pricingUnit = listing?.attributes?.publicData?.pricingType;

  const isBuyTypeListings =
    pricingUnit === 'perItem' ||
    pricingUnit === 'perKg' ||
    pricingUnit === 'perUnit';

  const openBookModal = (isOwnListing, isClosed, history, location) => {
    if (isOwnListing || isClosed) {
      window.scrollTo(0, 0);
    } else {
      const { pathname, search, state } = location;
      const searchString = `?${stringify({ ...parse(search), book: true })}`;
      window.history.replaceState(state, '', `${pathname}${searchString}`);
      setIsBook(true);
    }
  };

  const closeBookModal = (history, location) => {
    const { pathname, search, state } = location;
    const searchParams = omit(parse(search), 'book');
    const searchString = `?${stringify(searchParams)}`;
    window.history.replaceState(state, '', `${pathname}${searchString}`);
    setIsBook(false);
  };

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>
        {showCompanyName && (listingAuthorUserName || listingAuthorId) && (
          <NamedLink
            name={listingAuthorUserName ? 'UserProfilePage' : 'ProfilePage'}
            className={css.topLine}
            params={
              listingAuthorUserName
                ? {
                    slug: listingAuthorUserName,
                  }
                : {
                    id: listingAuthorId,
                  }
            }
            target="_blank"
          >
            {authorDisplayName ? authorDisplayName : defalutDisplayName}
            <RedirectIcon className={css.icon} />
          </NamedLink>
        )}

        {/* <span
          className={css.topLine}
          onClick={() => {
            const params = { id: listingAuthorId };
            history.push(
              createResourceLocatorString(
                'ProfilePage',
                routeConfiguration(),
                params,
                {}
              )
            );
          }}
        >
          {authorDisplayName ? authorDisplayName : defalutDisplayName}
        </span> */}

        <div className={css.bookingHeading}>
          <div className={css.bookingHeadingContainer}>
            <h2 className={titleClasses}>{title}</h2>
            {subTitleText ? (
              <div className={css.bookingHelp}>{subTitleText}</div>
            ) : null}
          </div>
          {(reviews || []).length > 0 ? (
            <div className={css.ratingDiv}>
              <SectionReviews
                reviews={reviews}
                fetchReviewsError={fetchReviewsError}
                avgReviewSection={true}
                fromBookingPanelListingReview={true}
              />
            </div>
          ) : null}

          {!!formattedSpecialPrice &&
          typeof formattedSpecialPrice === 'string' ? (
            <div className={css.desktopSpecialPriceContainer}>
              <div className={css.strikedOutPrice}>{formattedPrice}</div>
              <div className={css.priceRow}>
                <div className={css.desktopPriceValue} title={priceTitle}>
                  {formattedSpecialPrice}
                </div>
                <div className={css.desktopPerUnit}>
                  {
                    allowedPricingTypeOptions.find(
                      ({ value }) => value === pricingType
                    )?.unit
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className={css.desktopPriceContainer}>
              <div className={css.desktopPriceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.desktopPerUnit}>
                {
                  allowedPricingTypeOptions.find(
                    ({ value }) => value === pricingType
                  )?.unit
                }
              </div>
            </div>
          )}
        </div>
        {showForm ? (
          showProductOrderForm ? (
            <div
              className={classNames(css.productForm, {
                [css.noMargin]:
                  isBuyTypeListings || pricingType === 'perPerson',
              })}
            >
              {' '}
              <ProductOrderForm
                formId="OrderPanelProductOrderForm"
                onSubmit={onSubmit}
                price={price}
                currentStock={currentStock}
                pickupEnabled={pickupEnabled}
                shippingEnabled={shippingEnabled}
                listingId={listing.id}
                pricingType={pricingType}
                isOwnListing={isOwnListing}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                onContactUser={onContactUser}
                lineItems={lineItems}
                lineItemLabel={lineItemLabel}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                clothingStockPerSize={clothingStockPerSize}
                clothingSize={clothingSize}
                isClothingCategory={isClothingCategory}
                isShoeCategory={isShoeCategory}
                shoeStockPerSize={shoeStockPerSize}
                shoeSize={shoeSize}
                cartAddInProgress={cartAddInProgress}
                cartAddError={cartAddError}
                onAddCart={onAddCart}
                currentUser={currentUser}
                listing={listing}
                onFetchProductTransactionLineItems={
                  onFetchProductTransactionLineItems
                }
                isSubmittingForm={isSubmittingForm}
                isSubmitError={isSubmitError}
                timeZone={timeZone}
                isBuyTypeListings={isBuyTypeListings}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            </div>
          ) : (
            <BookingTimeForm
              className={css.bookingForm}
              formId="BookingPanel"
              submitButtonWrapperClassName={css.submitButtonWrapper}
              unitType={unitType}
              onSubmit={onSubmit}
              price={price}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              startDatePlaceholder={intl.formatDate(
                TODAY,
                dateFormattingOptions
              )}
              endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              timeZone={timeZone}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              lineItemLabel={lineItemLabel}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              isSubmittingForm={isSubmittingForm}
              isSubmitError={isSubmitError}
            />
          )
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {!!formattedSpecialPrice &&
            typeof formattedSpecialPrice === 'string' ? (
              <div className={css.sPriceRowMobile}>
                <div className={css.strikedOutPrice}>{formattedPrice}</div>
                <div className={css.priceRowMobile}>
                  <span>{formattedSpecialPrice}</span>
                  {
                    allowedPricingTypeOptions.find(
                      ({ value }) => value === pricingType
                    )?.unit
                  }
                </div>
              </div>
            ) : (
              <div className={css.priceRowMobile}>
                <span>{formattedPrice}</span>

                {
                  allowedPricingTypeOptions.find(
                    ({ value }) => value === pricingType
                  )?.unit
                }
              </div>
            )}
          </div>
          {/* <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div> */}
        </div>

        {showForm ? (
          <Button
            rootClassName={classNames(css.bookButton, {
              [css.disableBuy]: isOwnListing,
            })}
            onClick={() =>
              openBookModal(isOwnListing, isClosed, history, location)
            }
          >
            {isBuyTypeListings ? (
              <FormattedMessage id="BookingPanel.ctaButtonMessage" />
            ) : (
              <FormattedMessage id="BookingPanel.rentBookButtonMessage" />
            )}
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
      <div className={css.poweredByDiv}>
        <div className={css.logoDiv}>
          <img src={visaPng} alt="visaPng" className={css.cardImg} />
          <img
            src={MasterCardPng}
            alt="MasterCardPng"
            className={css.cardImg}
          />
          <img
            src={AmericanExpressPng}
            alt="AmericanExpressPng"
            className={css.cardImg}
          />
          <img src={PaypalPng} alt="PaypalPng" className={css.cardImg} />
          <img src={AfterPay} alt="AfterPay" className={css.APcardImg} />
        </div>

        <span>Powered by Stripe & Paypal</span>
      </div>
      {showSellerInfo && (
        <React.Fragment>
          <span className={css.hDivider} />

          <div className={css.providerInfoDiv}>
            <span>About this seller</span>
            <div className={css.imgNameDiv}>
              <SectionAvatar user={currentAuthor} params={params} />
              <div className={css.reviewDetails}>
                <span className={css.nameText}>
                  {authorDisplayName ? authorDisplayName : defalutDisplayName}
                </span>
                {/* <span className={css.reviewStar}>
              <StarSvg /> 5.0/5
            </span> */}
                {/* <span className={css.reviewStar}>22 reviews received</span> */}
                {listingAuthorId || listingAuthorUserName || showContactUser ? (
                  <div className={css.authorContactDiv}>
                    {listingAuthorUserName ? (
                      <NamedLink
                        className={css.contactLink}
                        name="UserProfilePage"
                        params={{ slug: listingAuthorUserName }}
                      >
                        <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                      </NamedLink>
                    ) : listingAuthorId ? (
                      <NamedLink
                        className={css.contactLink}
                        name="ProfilePage"
                        params={{ id: listingAuthorId }}
                      >
                        <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                      </NamedLink>
                    ) : null}
                    {showContactUser ? (
                      <React.Fragment>
                        <div className={css.seperatorDot}></div>
                        <InlineTextButton
                          rootClassName={css.contactLink}
                          onClick={onContactUser}
                          enforcePagePreloadFor="SignupPage"
                        >
                          <FormattedMessage id="ListingPage.contactUser" />
                        </InlineTextButton>
                      </React.Fragment>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  showSellerInfo: true,
  showCompanyName: true,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onFetchProductTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  showSellerInfo: bool.isRequired,
  showCompanyName: bool.isRequired,
};

const mapStateToProps = state => {
  const {
    selectedCurrencyExchangeCode,
    selectedCurrency,
  } = state.currencyExchangeCode;
  return {
    selectedCurrency,
    selectedCurrencyExchangeCode,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(BookingPanel);
