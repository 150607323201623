import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput } from '../../components';
import css from './EditListingPricingForm.module.css';
import {
  categoriesWithListingType,
  priceUnit,
} from '../../marketplace-custom-config';
import Select, { components } from 'react-select';
import { ReactComponent as ArrowDown } from '../../assets/ArrowDown.svg';

import { ReactComponent as ArrowUp } from '../../assets/ArrowUp.svg';
const { Money } = sdkTypes;
export const DropdownIndicator = ({ children, ...props }) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? (
      <ArrowUp
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    ) : (
      <ArrowDown
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    )}
  </components.DropdownIndicator>
);

export const ClearIndicator = ({ children, ...props }) => (
  <components.ClearIndicator {...props}>
    <IoMdClose className={css.selectClearBtn} />
  </components.ClearIndicator>
);
export const Menu = ({ children, ...props }) => (
  <components.Menu
    {...props}
    className={classNames(css.menu, {
      // [css.noValueMenu]: props.selectProps.id === 'country' || !props.hasValue,
    })}
  >
    {children}
  </components.Menu>
);
export const getValueContainer = (
  placeholder,
  defaultPlaceholder,
  callback,
  className,
  valueSpanClassName
) => ({ children, ...props }) => {
  if (typeof callback === 'function') callback(props.selectProps.menuIsOpen);
  return (
    <components.ValueContainer {...props}>
      <div
        className={classNames(css.valueContainer, className, {
          [css.multiValueContainer]: props.isMulti,
        })}
      >
        <span
          className={classNames(css.placeholder, {
            [css.selectedPlaceholder]:
              props.hasValue || props.selectProps.menuIsOpen,
            // [css.selectedSCPlaceholder]:
            //   props.hasValue || props.selectProps.menuIsOpen || values.productSubCategory,

            [css.emptyPlaceholder]: props.isMulti && !props.hasValue,
            [valueSpanClassName]:
              !!valueSpanClassName &&
              (props.hasValue || props.selectProps.menuIsOpen),
          })}
        >
          {!props.hasValue ? defaultPlaceholder : placeholder}
        </span>
        <div
          className={classNames(css.value, {
            [css.nonEmptyValue]: props.hasValue,
            [css.blurredValue]: props.hasValue && props.selectProps.menuIsOpen,
          })}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};
export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listing,
        values,
        form,
        touched,
        errors,
        active,
      } = formRenderProps;
      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(
        config.listingMinimumPriceSubUnits,
        config.currency
      );
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      //price unit conditions
      const productCategory = listing.attributes.publicData.productCategory;
      const productListingType = listing.attributes.publicData.listingType;

      const perItemPersonDayNightUnit = categoriesWithListingType.includes(
        productCategory
      )
        ? productListingType === 'buffet'
          ? 'Person'
          : 'Item'
        : productCategory === 'accommodation'
        ? 'Night'
        : productCategory === 'carRentals'
        ? 'Day'
        : '';
      const additionalItems = listing?.attributes?.publicData?.additionalItems;
      const businessServicesPriceUnitOptions =
        productCategory === 'businessServices'
          ? additionalItems?.amount > 0
            ? priceUnit.filter(
                o =>
                  o.value === 'person' || o.value === 'kg' || o.value === 'item'
              )
            : priceUnit.filter(
                o =>
                  o.value === 'hour' ||
                  o.value === 'day' ||
                  o.value === 'night' ||
                  o.value === 'week' ||
                  o.value === 'month'
              )
          : null;
      const priceUnitValue = priceUnit.find(
        f => f.value === values.priceUnitType
      );
      // console.log(
      //   3233,
      //   categoriesWithListingType.includes(productCategory),
      //   perItemPersonDayNightUnit,

      //   values.priceUnitType
      // );
      const hasPerItemPersonDayNightUnit = perItemPersonDayNightUnit
        ? `Per ${perItemPersonDayNightUnit}*`
        : '';
      return (
        <Form
          onSubmit={e => {
            e.preventDefault();
            const priceUnitType =
              productCategory === 'businessServices'
                ? values.priceUnitType
                : perItemPersonDayNightUnit;
            props.onSubmit({ price: values.price, priceUnitType });
          }}
          className={classes}
        >
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            // className={css.priceInput}
            className={classNames(css.inputs, {
              [css.invalidInputs]: touched.price && !!errors.price,
              [css.fnNonEmptyInputs]: !!values.price || active === 'price',
            })}
            autoFocus
            // label={pricePerUnitMessage}
            label={` Product Price ${hasPerItemPersonDayNightUnit}`}
            // placeholder={pricePlaceholderMessage}
            placeholder={' '}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
          {businessServicesPriceUnitOptions?.length > 0 ? (
            <Select
              className={classNames(
                css.categoryReactSelect,
                css.cateSelect,
                {}
              )}
              isSearchable={false}
              name="priceUnit"
              id="priceUnit"
              options={businessServicesPriceUnitOptions}
              // isClearable={true}
              onChange={e => {
                form.change('priceUnitType', e.value);
              }}
              value={priceUnitValue}
              placeholder={null}
              hideSelectedOptions={true}
              components={{
                DropdownIndicator,
                // ClearIndicator,
                Menu,
                ValueContainer: getValueContainer(
                  'Choose Unit',
                  'Choose Unit'
                  // setIsCategoryMenuOpen
                ),
              }}
            />
          ) : null}
          <div className={css.lowerDiv}>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
