import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';

import css from './TransactionPanel.module.css';
import { ReactComponent as RedirectIcon } from '../../assets/RedirectTo.svg';
import NamedLink from '../NamedLink/NamedLink';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    listingTitle,
    subTitle,
    location,
    geolocation,
    showAddress,
    provider,
    sellerName,
  } = props;
  const providerUserName = provider?.attributes?.profile?.publicData?.userName;
  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      <span className={css.soldBy}> Sold by</span>
      <NamedLink
        name={providerUserName ? 'UserProfilePage' : 'ProfilePage'}
        className={css.topLine}
        params={
          providerUserName
            ? {
                slug: providerUserName,
              }
            : {
                id: provider?.id?.uuid,
              }
        }
        target="_blank"
      >
        <h2 className={css.detailCardTitle}>{sellerName}</h2>
        <RedirectIcon className={css.icon} />
      </NamedLink>

      {/* <p className={css.detailCardSubtitle}>{subTitle}</p> */}
      {/* <AddressLinkMaybe
        location={location}
        geolocation={geolocation}
        showAddress={showAddress}
      /> */}
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
