import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_PICKUP_FEE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import { PriceDataWithDifferentCurrency } from '../../util/PriceDataWithDifferentCurrency';

const LineItemPickupFeeMaybe = props => {
  const {
    lineItems,
    intl,
    selectedCurrencyExchangeCode,
    selectedCurrency,
  } = props;

  const pickupFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_PICKUP_FEE && !item.reversal
  );

  return pickupFeeLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.pickupFee" />
      </span>
      <span className={css.itemValue}>
        {
          PriceDataWithDifferentCurrency(
            pickupFeeLineItem.lineTotal,
            intl,
            selectedCurrency,
            selectedCurrencyExchangeCode
          )?.formattedPrice
        }
      </span>
    </div>
  ) : null;
};

LineItemPickupFeeMaybe.propTypes = {
  // lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemPickupFeeMaybe;
