import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';
import { PriceDataWithDifferentCurrency } from '../../util/PriceDataWithDifferentCurrency';
import { array, object } from 'prop-types';
import { isEmpty } from 'lodash';

const LineItemUnitPriceMaybe = props => {
  const {
    transaction,
    unitType,
    intl,
    selectedCurrencyExchangeCode,
    selectedCurrency,
    lineItem = {},
  } = props;
  const {
    attributes: { protectedData: { lineItemLabel = {} } = {} },
  } = transaction;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const encodedItemCode = encodeURIComponent(lineItem.code);
  const hasEncodedItem = lineItemLabel.hasOwnProperty(encodedItemCode);
  const encodedItem = lineItemLabel[encodedItemCode];
  const takeFromEncodeLineItem = !isEmpty(lineItem) && hasEncodedItem;
  const translationKey = isNightly
    ? 'BookingBreakdown.pricePerNight'
    : isDaily
    ? 'BookingBreakdown.pricePerDay'
    : 'BookingBreakdown.pricePerQuantity';

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const formattedUnitPrice = unitPurchase
    ? PriceDataWithDifferentCurrency(
        unitPurchase.unitPrice,
        intl,
        selectedCurrency,
        selectedCurrencyExchangeCode
      )?.formattedPrice
    : null;

  return formattedUnitPrice ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {takeFromEncodeLineItem ? (
          encodedItem.unitLabel
        ) : (
          <FormattedMessage id={translationKey} />
        )}
      </span>
      <span className={css.itemValue}>{formattedUnitPrice}</span>
    </div>
  ) : null;
};

LineItemUnitPriceMaybe.defaultProps = {
  lineItem: {},
};

LineItemUnitPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
  lineItem: object.isRequired,
};

export default LineItemUnitPriceMaybe;
