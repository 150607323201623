import React, { useEffect, useState } from 'react';
import CustomThumbnailWrapper from '../customThumbnailWrapper/customThumbnailWrapper';
import {ThumbnailWrapper} from '../AddImages/AddImages'
import css from './draggableComponent.module.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  PointerSensor,
  useSensor,
  useSensors,
  KeyboardSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { isEqual } from 'lodash';

const SortableItem = props => {
  const {
    image,
    index,
    id,
    thumbnailClassName,
    savedImageAltText,
    onRemoveImage,
  } = props;
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: id,
  });
  const style = {
    transition: transition || undefined,
    // transform: transform ? CSS.Transform.toString(transform) : undefined,
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    opacity: isDragging ? 0.5 : 1,
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      {...attributes}
      {...listeners}
      className={css.dragDiv}
    >
      {/* {image.id.uuid || image.id} */}
      <CustomThumbnailWrapper
        image={image}
        index={index}
        key={image.id.uuid || image.id}
        id={image.id.uuid || image.id}
        className={thumbnailClassName}
        savedImageAltText={savedImageAltText}
        onRemoveImage={onRemoveImage}
        previewIndex={index}
      />
      {/* <ThumbnailWrapper
        image={image}
        index={index}
        key={image.id.uuid || image.id}
        id={image.id.uuid || image.id}
        className={thumbnailClassName}
        savedImageAltText={savedImageAltText}
        onRemoveImage={onRemoveImage}
        previewIndex={index}
      /> */}
    </div>
  );
};

const DraggableComponent = props => {
  const {
    images,
    thumbnailClassName,
    savedImageAltText,
    onRemoveImage,
    history,
    alterImages,
  } = props;
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 2,
      },
    })
  );

  const [previewImages, setPreviewImages] = useState([]);
  const [sortedpreviewImages, setSortedPreviewImages] = useState([]);

  useEffect(() => {
    setPreviewImages(images);
  }, [images]);

  const handleDragEnd = image => {
    const { active, over } = image;
    const sourceIndex = previewImages?.findIndex(
      item => (item.id.uuid || item.id) === active?.id
    );
    const destinationIndex = previewImages?.findIndex(
      item => (item.id.uuid || item.id) === over?.id
    );
    if (
      sourceIndex !== destinationIndex &&
      sourceIndex !== -1 &&
      destinationIndex !== -1
    ) {
      setPreviewImages(prevImages =>
        arrayMove(prevImages, sourceIndex, destinationIndex)
      );
      alterImages(sourceIndex, destinationIndex);
    }
  };

  return (
    <>
      <DndContext
        // autoScroll={false}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        data-no-dnd="true"
      >
        <SortableContext
          items={previewImages?.map(image => image.id.uuid || image.id)}
          strategy={rectSortingStrategy}
        >
          {/* <div> */}
          {previewImages?.map((image, index) => {
            return (
              <SortableItem
                key={image.id.uuid || image.id}
                id={image.id.uuid || image.id}
                index={index}
                image={image}
                thumbnailClassName={thumbnailClassName}
                savedImageAltText={savedImageAltText}
                onRemoveImage={onRemoveImage}
              />
            );
          })}
          {/* </div> */}
        </SortableContext>
      </DndContext>
    </>
  );
};

export default compose(withRouter)(DraggableComponent);
