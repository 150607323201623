import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  NamedLink,
} from '../../components';
import * as validators from '../../util/validators';
import { ReactComponent as View } from '../../assets/Eye.svg';
import { ReactComponent as Hide } from '../../assets/EyeClose.svg';
import css from './LoginForm.module.css';

const LoginFormComponent = props => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  const toggleShow = () => {
    setIsPasswordHidden(isPasswordHidden => !isPasswordHidden);
  };
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          touched,
          errors,
          values,
          active,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'LoginForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'LoginForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'LoginForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'LoginForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'LoginForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'LoginForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'LoginForm.passwordRequired',
        });
        const passwordRequired = validators.requiredStringNoTrim(
          passwordRequiredMessage
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const passwordRecoveryLink = (
          <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
            <FormattedMessage id="LoginForm.forgotPassword" />
          </NamedLink>
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <span className={css.titleDiv}>Log in with email</span>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                // placeholder={emailPlaceholder}
                validate={validators.composeValidators(
                  emailRequired,
                  emailValid
                )}
                // className={classNames(
                //   css.inputs,
                //   css.interLightSemiSmallBlack,
                //   {
                //     [css.invalidInputs]: touched.email && !!errors.email,
                //     [css.fnNonEmptyInputs]:
                //       !!values.email || active === 'email',
                //   }
                // )}
                className={css.emailRoot}
              />
              <div className={css.passwordDiv}>
                <FieldTextInput
                  // className={css.password}
                  // className={classNames(
                  //   css.inputs,
                  //   css.interLightSemiSmallBlack,
                  //   {
                  //     [css.invalidInputs]:
                  //       touched.password && !!errors.password,
                  //     [css.fnNonEmptyInputs]:
                  //       !!values.password || active === 'password',
                  //   }
                  // )}
                  className={css.emailRoot}
                  type={!isPasswordHidden ? 'text' : 'password'}
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="current-password"
                  label={passwordLabel}
                  // placeholder={passwordPlaceholder}
                  validate={passwordRequired}
                />
                {isPasswordHidden ? (
                  <Hide className={css.hide} onClick={toggleShow} />
                ) : (
                  <View className={css.view} onClick={toggleShow} />
                )}
              </div>
            </div>
            <div className={css.bottomWrapper}>
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="LoginForm.logIn" />
              </PrimaryButton>

              <div className={css.bottomDiv}>
                <NamedLink name="PasswordRecoveryPage" className={css.tabLink}>
                  Forgot Password
                </NamedLink>
              </div>

              <div className={css.bottomDiv}>
                <NamedLink name="SignupPage" className={css.tabLink}>
                  Create a new account
                </NamedLink>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
