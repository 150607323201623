import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { fetchRtqNotifications } from '../../ducks/user.duck';

const { UUID } = types;

export const INITIAL_LOAD_REQUEST = 'app/InboxPage/ INITIAL_LOAD_REQUEST';

export const FETCH_QUOTE_REQUEST = 'app/InboxPage/FETCH_QUOTE_REQUEST';
export const FETCH_QUOTE_SUCCESS = 'app/InboxPage/FETCH_QUOTE_SUCCESS';
export const FETCH_QUOTE_ERROR = 'app/InboxPage/FETCH_QUOTE_ERROR';

export const MESSAGE_SEND_REQUEST = 'app/InboxPage/MESSAGE_SEND_REQUEST';
export const MESSAGE_SEND_SUCCESS = 'app/InboxPage/MESSAGE_SEND_SUCCESS';
export const MESSAGE_SEND_ERROR = 'app/InboxPage/MESSAGE_SEND_ERROR';

const initialState = {
  initialLoad: true,
  fetchInProgress: false,
  pagination: null,
  quoteDetails: {},
  fetchQuoteDetailsError: null,
  messageSendInProgress: false,
  messageSendError: null,
  messageSendSuccess: false,
};

export default function rtqDetailsPageReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case INITIAL_LOAD_REQUEST:
      return { ...state, initialLoad: true };
    case FETCH_QUOTE_REQUEST:
      return { ...state, fetchInProgress: true, fetchQuoteDetailsError: null };
    case FETCH_QUOTE_SUCCESS: {
      const quoteDetails = payload.data.data;
      return {
        ...state,
        initialLoad: false,
        fetchInProgress: false,
        quoteDetails,
        pagination: payload.data.meta,
      };
    }
    case FETCH_QUOTE_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchInProgress: false,
        fetchQuoteDetailsError: payload,
        initialLoadRequest: false,
      };

    case MESSAGE_SEND_REQUEST: {
      return {
        ...state,
        messageSendInProgress: true,
        messageSendSuccess: false,
        messageSendError: null,
      };
    }
    case MESSAGE_SEND_SUCCESS: {
      return {
        ...state,
        messageSendInProgress: false,
        messageSendSuccess: true,
      };
    }
    case MESSAGE_SEND_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        messageSendInProgress: false,
        messageSendSuccess: false,
        messageSendError: payload,
      };

    default:
      return state;
  }
}

const initialLoadRequest = () => ({
  type: INITIAL_LOAD_REQUEST,
});

const fetchQuoteDetailsRequest = () => ({
  type: FETCH_QUOTE_REQUEST,
});
const fetchQuoteDetailsSuccess = response => ({
  type: FETCH_QUOTE_SUCCESS,
  payload: response,
});
const fetchQuoteDetailsError = e => ({
  type: FETCH_QUOTE_ERROR,
  error: true,
  payload: e,
});

const messageSendRequest = () => ({
  type: MESSAGE_SEND_REQUEST,
});
const messageSendSuccess = response => ({
  type: MESSAGE_SEND_SUCCESS,
  payload: response,
});
const messageSendError = e => ({
  type: MESSAGE_SEND_ERROR,
  error: true,
  payload: e,
});

export const sendMessage = (msgData = {}, requestId) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(messageSendRequest());
  try {
    const data = await axios.post(
      `${apiBaseUrl()}/api/rtq/sendMessage/${requestId}`,
      { ...msgData },
      { withCredentials: true }
    );
    dispatch(messageSendSuccess());
    dispatch(onFetchQuoteRequest(requestId));
    return data;
  } catch (error) {
    dispatch(messageSendError(error));
  }
};

const onFetchQuoteRequest = rtqId => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchQuoteDetailsRequest());
    const data = await axios.get(`${apiBaseUrl()}/api/rtq/${rtqId}`, {
      withCredentials: true,
    });
    const listingIds = [new UUID(data?.data?.data?.listingId)];

    const lisitngData = await sdk.listings.query({
      ids: listingIds,
      include: ['author', 'images', 'author.profileImage'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': [
        'profile.displayName',
        'profile.abbreviatedName',
        'profile.publicData',
      ],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.default',
        'variants.square-small',
        'variants.square-small2x',
        'variants.facebook',
        'variants.twitter',
      ],
      'limit.images': 1,
    });

    dispatch(addMarketplaceEntities(lisitngData));

    dispatch(fetchQuoteDetailsSuccess(data));
    dispatch(fetchRtqNotifications());

    return data;
  } catch (e) {
    dispatch(fetchQuoteDetailsError(storableError(e)));
    throw e;
  }
};

export const loadData = params => (dispatch, getState, sdk) => {
  const rtqId = params.id;
  dispatch(initialLoadRequest());

  // return onFetchQuoteRequest(rtqId);
  return Promise.all([dispatch(onFetchQuoteRequest(rtqId))]);
  // dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  // return Promise.all();
};
