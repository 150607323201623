import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ExternalLink, ListingLink, Modal } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling,
  } = props;
  const [isprocessingFeeModalOpen, setIsProcessingFeeModalOpen] = useState(
    false
  );
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price } = currentListing.attributes;
  const { priceUnitType } = currentListing?.attributes?.publicData;

  // console.log(555, currentListing?.attributes?.publicData, priceUnitType);
  const isPublished =
    currentListing.id &&
    currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingPricingPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid =
    price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{ price, priceUnitType }}
      onSubmit={values => {
        const { price, priceUnitType } = values;
        const updatedValues = { price: price, publicData: { priceUnitType } };
        onSubmit(updatedValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      listing={listing}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );
  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      {isPublished ? (
        <div className={css.loginTitle}>
          <span className={css.titleBlue}>Edit</span>
          <span className={css.titleBlack}> Product Price</span>
        </div>
      ) : (
        <div className={css.loginTitle}>
          <span className={css.titleBlack}>Product</span>
          <span className={css.titleBlue}> Price</span>
        </div>
      )}
      <span className={css.hDivider} />
      <p className={css.tip}>
        Once someone makes an order, you’ll receive this amount minus{' '}
        <span
          className={css.processingFee}
          onClick={() => setIsProcessingFeeModalOpen(!isprocessingFeeModalOpen)}
        >
          Siuhuu fee and a payment processing fee.
        </span>
      </p>
      {form}
      {isprocessingFeeModalOpen ? (
        <Modal
          id="processingFeeModal"
          isOpen={isprocessingFeeModalOpen}
          onClose={() => setIsProcessingFeeModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <h2>Commission and Payment processing fee</h2>
          <p>
            Payments in Siuhuu are processed by the payment gateway listed
            below. Siuhuu will charge a commission depending on the payment
            gateway. The payment gateway may also charge a fee for each payment,
            as described below.
          </p>

          <h3>Stripe</h3>
          <p>
            For Stripe payments, Siuhuu charges a commission of 5%. The minimum
            commission per transaction is $0. Stripe's fees are included in this
            commission.
          </p>

          <h3>PayPal</h3>
          <p>
            For PayPal payments, Siuhuu charges a commission of 5%. The minimum
            transaction fee per transaction is $0. PayPal's fees are not
            included in this commission, so in addition to Siuhuu commission,
            you will also pay PayPal's fees for each. PayPal's fee is between 2%
            and 5% of the total sales price, depending on your monthly sales
            volume and the country of residence of the buyer. In general,
            domestic purchases have lower fees than international ones, and
            higher monthly sales give you a discount on fees.
            <br />
            You can see the exact fees by logging in to your PayPal account and
            going{' '}
            <ExternalLink href="https://www.paypal.com/au/webapps/mpp/merchant-fees">
              here
            </ExternalLink>
            . After each purchase you will get a receipt displaying the exact
            fee.
          </p>
        </Modal>
      ) : null}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
