import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATE, DATE_TYPE_DATETIME } from '../../util/types';
import { ensureListing } from '../../util/data';
import { BookingBreakdown } from '../../components';

import css from './TransactionPanel.module.css';
import OrderBreakdown from '../BookingBreakdown/OrderBreakdown';
import { productTypeCategories } from '../../marketplace-custom-config';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    onCreateLabel,
    onAddDeliveryDate,
    labelCreateInProgress,
    deliveryDateAddInProgress,
    isPlatformShippingDetailFilled,
    isManualShippingDetailFilled,
    showHeading,
    isShippingLabelCreated,
  } = props;
  const loaded =
    transaction &&
    transaction.id &&
    transaction.booking &&
    transaction.booking.id;
  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';

  //const productCategory = listingAttributes?.publicData?.productCategory;

  //const isProductType = productTypeCategories.includes(productCategory);

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  return (
    <div className={classes}>
      <OrderBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={config.bookingUnitType}
        transaction={transaction}
        booking={transaction.booking}
        dateType={DATE_TYPE_DATE}
        timeZone={timeZone}
        onCreateLabel={onCreateLabel}
        onAddDeliveryDate={onAddDeliveryDate}
        labelCreateInProgress={labelCreateInProgress}
        deliveryDateAddInProgress={deliveryDateAddInProgress}
        isPlatformShippingDetailFilled={isPlatformShippingDetailFilled}
        isManualShippingDetailFilled={isManualShippingDetailFilled}
        showHeading={showHeading}
        isShippingLabelCreated={isShippingLabelCreated}
      />
      {/*isProductType ? (
        <OrderBreakdown
          className={css.receipt}
          userRole={transactionRole}
          unitType={config.bookingUnitType}
          transaction={transaction}
          booking={transaction.booking}
          dateType={DATE_TYPE_DATE}
          timeZone={timeZone}
        />
      ) : (
        // <>jksgadjhags</>
        <BookingBreakdown
          className={breakdownClasses}
          userRole={transactionRole}
          unitType={config.bookingUnitType}
          transaction={transaction}
          booking={transaction.booking}
          dateType={DATE_TYPE_DATETIME}
          timeZone={timeZone}
        />
      )*/}
    </div>
  );
};

export default BreakdownMaybe;
