import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';
// import { ReactComponent as FooterLogo } from '../../assets/footerLogo.svg';

//Social Media Icons
import { ReactComponent as Facebook } from '../../assets/Facebook.svg';
import { ReactComponent as Instagram } from '../../assets/Instagram.svg';
import { ReactComponent as Twitter } from '../../assets/twitter.svg';
import { ReactComponent as Youtube } from '../../assets/youtube.svg';
import { ReactComponent as Linkedin } from '../../assets/linkedin.svg';
import { ReactComponent as Tiktok } from '../../assets/tiktok.svg';
import { TbBrandLinkedin } from 'react-icons/tb';
const renderSocialMediaLinks = intl => {
  const {
    siteFacebookPage,
    siteInstagramPage,
    // siteTwitterHandle,
    // siteYoutubeChannel,
    siteLinkedinChannel,
    siteTiktok,
  } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  // const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={css.icon}
      title={goToFb}
    >
      <Facebook className={css.sMediaIcon} />
    </ExternalLink>
  ) : null;
  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      {/* <IconSocialMediaInstagram /> */}
      <Instagram className={css.sMediaIcon} />
    </ExternalLink>
  ) : null;

  // const twitterLink = siteTwitterPage ? (
  //   <ExternalLink
  //     key="linkToTwitter"
  //     href={siteTwitterPage}
  //     className={css.icon}
  //     title={goToTwitter}
  //   >
  //     <Twitter className={css.sMediaIcon} />
  //   </ExternalLink>
  // ) : null;

  // const youtubeLink = siteYoutubeChannel ? (
  //   <ExternalLink
  //     key="linkToYoutube"
  //     href={siteYoutubeChannel}
  //     className={css.icon}
  //     title={'Go to Youtube page'}
  //   >
  //     <Youtube className={css.sMediaIcon} />
  //   </ExternalLink>
  // ) : null;

  const linkedinLink = siteLinkedinChannel ? (
    <ExternalLink
      key="linkToLinkedIn"
      href={siteLinkedinChannel}
      className={css.icon}
      title={'Go to LinkedIn page'}
    >
      <TbBrandLinkedin className={css.linkdeinIcon} />
    </ExternalLink>
  ) : null;

  const tiktokLink = siteTiktok ? (
    <ExternalLink
      key="linkToTiktok"
      href={siteTiktok}
      className={css.icon}
      title={'Go to Tiktok page'}
    >
      <Tiktok className={css.sMediaIcon} />
    </ExternalLink>
  ) : null;

  return [
    fbLink,
    instragramLink,
    tiktokLink,
    // twitterLink,
    // youtubeLink,
    linkedinLink,
  ].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.serviceAbout}>
              <div className={css.infoLinks}>
                <span className={css.footerTitle}>Customer Service</span>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="HowItWorksPage" className={css.link}>
                      How it works?
                    </NamedLink>
                  </li>
                  {/* <li className={css.listItem}>
                    <NamedLink name="AboutPage" className={css.link}>
                      Your account
                    </NamedLink>
                  </li> */}
                  {/* <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      FAQ
                    </NamedLink>
                  </li> */}
                  <li className={css.listItem}>
                    <NamedLink
                      name="ContactUsPage"
                      // to={{ hash: '#contact' }}
                      className={css.link}
                    >
                      Contact Us
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="FaqPage" className={css.link}>
                      FAQ
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.infoLinks}>
                <span className={css.footerTitle}>About</span>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="AboutPage" className={css.link}>
                      {/* About Company */}
                      About Us
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="TermsOfServicePage" className={css.link}>
                      Terms and Use
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="PrivacyPolicyPage" className={css.link}>
                      Privacy Policy
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.infoLinks}>
                <span className={css.footerTitle}>Updates</span>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    {/* <ExternalLink
                      href="https://updates.siuhuu.com/blogs"
                      className={css.link}
                    >
                      {' '}
                      Blog
                    </ExternalLink> */}
                    <a
                      href="https://updates.siuhuu.com/blogs"
                      className={css.link}
                      target="_self"
                    >
                      Blog
                    </a>
                  </li>
                  <li className={css.listItem}>
                    {/* <ExternalLink
                      href="https://updates.siuhuu.com"
                      className={css.link}
                    >
                      {' '}
                      News
                    </ExternalLink> */}
                    <a
                      href="https://updates.siuhuu.com"
                      className={css.link}
                      target="_self"
                    >
                      News
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className={css.linksDiv}>
              <div className={css.extraLinks}>
                <span className={css.footerTitle}>FOLLOW US</span>
                <div className={css.someLinks}>{socialMediaLinks}</div>
              </div>
              <div>
                <NamedLink
                  name="LandingPage"
                  className={classNames(css.logoLink, css.FooterLogoLink)}
                >
                  <Logo format="desktop" className={css.logo} />
                </NamedLink>
              </div>
            </div>
          </div>
        </div>
        <p className={css.organizationCopyright}>
          <NamedLink name="LandingPage" className={css.copyrightLink}>
            <FormattedMessage id="Footer.copyright" />
          </NamedLink>
        </p>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
