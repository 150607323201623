import React, { useMemo, useState } from 'react';
import { apiBaseUrl } from '../../util/api';
import { hsCodeRequired } from '../../util/validators';
import classNames from 'classnames';
import { IoMdInformationCircle } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import IconSpinner from '../IconSpinner/IconSpinner';
import css from './productHscode.module.css';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import axios from 'axios';
import Select, { components } from 'react-select';
import { itemCategoriesHsCode } from '../../marketplace-custom-config';
import { ReactComponent as ArrowDown } from '../../assets/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/ArrowUp.svg';

const DropdownIndicator = ({ children, ...props }) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? (
      <ArrowUp
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    ) : (
      <ArrowDown
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    )}
  </components.DropdownIndicator>
);

const ClearIndicator = ({ children, ...props }) => (
  <components.ClearIndicator {...props}>
    <IoMdClose className={css.selectClearBtn} />
  </components.ClearIndicator>
);
const Menu = ({ children, ...props }) => (
  <components.Menu
    {...props}
    className={classNames(css.menu, {
      // [css.noValueMenu]: props.selectProps.id === 'country' || !props.hasValue,
    })}
  >
    {children}
  </components.Menu>
);

const getValueContainer = (
  placeholder,
  defaultPlaceholder,
  callback,
  className,
  valueSpanClassName
) => ({ children, ...props }) => {
  if (typeof callback === 'function') callback(props.selectProps.menuIsOpen);

  return (
    <components.ValueContainer {...props}>
      <div
        className={classNames(css.valueContainer, className, {
          [css.multiValueContainer]: props.isMulti,
        })}
      >
        <span
          className={classNames(css.placeholder, {
            [css.selectedPlaceholder]:
              props.hasValue || props.selectProps.menuIsOpen,
            // [css.selectedSCPlaceholder]:
            //   props.hasValue || props.selectProps.menuIsOpen || values.productSubCategory,

            [css.emptyPlaceholder]: props.isMulti && !props.hasValue,
            [valueSpanClassName]:
              !!valueSpanClassName &&
              (props.hasValue || props.selectProps.menuIsOpen),
          })}
        >
          {!props.hasValue ? defaultPlaceholder : placeholder}
        </span>
        <div className={classNames(css.value, css.valueWrapper)}>
          <div
            className={classNames(css.value, {
              [css.nonEmptyValue]: props.hasValue,
              [css.blurredValue]:
                props.hasValue && props.selectProps.menuIsOpen,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </components.ValueContainer>
  );
};
const delayFuncCall = (func, delayTime = 1000) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
      clearTimeout(timeoutId);
    }, delayTime);
  };
};

const getNestedKeyValue = (key, values) => {
  const keys = key.split('.');
  return keys.reduce((acc, key) => {
    return acc[key];
  }, values);
};

const itemsHsCode = itemCategoriesHsCode.map(item => ({
  value: item.hs_code,
  label: item.name,
  key: item.hs_code,
}));

itemsHsCode.push({
  value: -1,
  label: 'Other',
  key: -1,
});

const ProductHsCode = props => {
  const {
    values,
    intl,
    form,
    formId,
    placeholderId = 'EditListingDescriptionForm.hsCodePlaceholder',
  } = props;
  const [fetchStatus, setFetchStatus] = useState({
    isLoading: false,
    data: [],
    error: null,
  });
  const [showInputForm, setShowInputForm] = useState(false);
  const [showHsCodeList, setShowHsCodeList] = useState(false);
  const { productHscode } = formId
    ? getNestedKeyValue(formId, values) || {}
    : values;
  const descriptionName = !formId
    ? 'productHscode.desc'
    : `${formId}.productHscode.desc`;
  const descriptionCode = !formId
    ? 'productHscode.code'
    : `${formId}.productHscode.code`;

  const fetchHsCodeBy = async desc => {
    if (!desc) return;
    try {
      setFetchStatus({ isLoading: true, data: [], error: null });

      const url = `${apiBaseUrl()}/api/platformshipping/product/hs-code?desc=${desc}`;
      const resp = await axios.get(url);
      const data = resp.data;
      if (data.length <= 0) {
        data.push({
          code: '-1',
          description: intl.formatMessage({
            id: 'EditListingDescriptionForm.noHsCode',
          }),
        });
      }
      setFetchStatus({ isLoading: false, data });
    } catch (e) {
      setFetchStatus({ isLoading: false, error: e });
    }
  };

  const delayFetch = useMemo(() => delayFuncCall(fetchHsCodeBy), []);

  const hsCodeRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.hsCodeRequired',
  });

  const hsCodeRequiredValidator = hsCodeRequired(
    hsCodeRequiredMessage,
    productHscode
  );

  const hsCodeLabelSelect = intl.formatMessage({
    id: 'EditListingDescriptionForm.hsCodeLabelSelect',
  });

  const hsCodeLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.hsCodeLabel',
  });

  const hsCodePlaceholder = intl.formatMessage({
    id: placeholderId,
  });

  const handleHsCodeChange = e => {
    const value = e.target.value;
    form.change(descriptionName, value);
    form.change(descriptionCode, undefined);
    if (!fetchStatus.isLoading) {
      setFetchStatus({ isLoading: true, data: [], error: null });
    }
    setShowHsCodeList(true);

    delayFetch(value);
  };
  const handleDivBlur = e => {
    if (!e.relatedTarget) {
      setShowHsCodeList(false);
    }
  };

  const handleClick = (e, data) => {
    e.preventDefault();
    const { code, description } = data;
    if (parseInt(code) < 0) {
      return;
    }
    form.change(descriptionCode, code);
    setTimeout(() => {
      form.change(descriptionName, description);
      setShowHsCodeList(false);
      setShowInputForm(false);
    }, 100);
  };

  const handleSelectChnage = e => {
    if (e === null) {
      return;
    }
    const { value, label } = e;
    if (value === -1) {
      setShowInputForm(true);
      return;
    }
    form.batch(() => {
      form.change(descriptionName, label);
      form.change(descriptionCode, value);
    });
  };
  const selectHsCodeValue =
    productHscode && productHscode.desc && productHscode.code
      ? { value: productHscode.code, label: productHscode.desc }
      : undefined;

  return (
    <div className={css.hsCodeContainer} tabIndex={'0'} onBlur={handleDivBlur}>
      {showInputForm ? (
        <React.Fragment>
          <FieldTextInput
            id={descriptionName}
            name={descriptionName}
            className={classNames(css.textInput)}
            type="text"
            label={hsCodeLabel}
            placeholder={hsCodePlaceholder}
            validate={hsCodeRequiredValidator}
            onChange={handleHsCodeChange}
          />
          {showHsCodeList && productHscode && productHscode.desc ? (
            <div className={css.hscodeList}>
              {fetchStatus.isLoading ? (
                <IconSpinner className={css.spinner} />
              ) : fetchStatus.error ? (
                <div className={css.error}>
                  <FormattedMessage id="EditListingDescriptionForm.hsCodeFetchFailed" />
                </div>
              ) : (
                <ul>
                  {fetchStatus.data.map(d => (
                    <li
                      key={d.code}
                      onClick={e => handleClick(e, d)}
                      className={classNames({
                        [css.noHsCode]: parseInt(d.code) < 0,
                      })}
                    >
                      {d.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <Select
          className={classNames(css.categoryReactSelect)}
          isSearchable={false}
          name={descriptionName}
          id={descriptionName}
          options={itemsHsCode}
          // isClearable={true}
          placeholder={null}
          onChange={handleSelectChnage}
          value={selectHsCodeValue}
          hideSelectedOptions={true}
          components={{
            DropdownIndicator,
            Menu,
            ValueContainer: getValueContainer(
              hsCodeLabelSelect,
              hsCodeLabelSelect,
              () => {},
              css.SCselectedPlaceholder
            ),
          }}
        />
      )}

      <div className={css.tooltipInfo}>
        <span>
          <IoMdInformationCircle />
          <FormattedMessage id="EditListingDescriptionForm.hsCodeLabel" />
        </span>{' '}
        <FormattedMessage id="EditListingDescriptionForm.hsCodeInfo" />
      </div>
    </div>
  );
};

export default ProductHsCode;
