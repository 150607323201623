import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'sydney',
    predictionPlace: {
      address: 'Sydney, Australia',
      bounds: new LatLngBounds(
        new LatLng(-33.57814094522021, 151.3430209458699),
        new LatLng(-34.11834699888443, 150.5209286026224)
      ),
      origin: new LatLng(-33.8688197, 151.2092955),
    },
  },
  {
    id: 'melbourne',
    predictionPlace: {
      address: 'Melbourne, Australia',
      bounds: new LatLngBounds(
        new LatLng(-37.5112736581378, 145.512528780816),
        new LatLng(-38.43385934606901, 144.5937417793496)
      ),
      origin: new LatLng(-37.8136276, 144.9630576),
    },
  },

  {
    id: 'auckland',
    predictionPlace: {
      address: 'Auckland, New Zealand',
      bounds: new LatLngBounds(
        new LatLng(-36.66060184426172, 174.9684260722261),
        new LatLng(-37.05901990116617, 174.4438160493033)
      ),
      origin: new LatLng(-36.859454, 174.5660389),
    },
  },
  {
    id: 'suva',
    predictionPlace: {
      address: 'Suva, Fiji',
      bounds: new LatLngBounds(
        new LatLng(-18.08364734044991, 178.5062885561634),
        new LatLng(-18.16359066874811, 178.4196881689107)
      ),
      origin: new LatLng(-18.1235343, 178.427969, 13),
    },
  },
  {
    id: 'perth',
    predictionPlace: {
      address: 'Perth, Australia',
      bounds: new LatLngBounds(
        new LatLng(-31.62448547765104, 116.2390230470838),
        new LatLng(-32.4556424377135, 115.6840483478314)
      ),
      origin: new LatLng(-31.9523123, 115.861309),
    },
  },
  // {
  //   id: 'canberra',
  //   predictionPlace: {
  //     address: 'Canberra ACT, Australia',
  //     bounds: new LatLngBounds(
  //       new LatLng(-35.14769912406125, 149.2636434410156),
  //       new LatLng(-35.48026035458241, 148.9959216441171)
  //     ),
  //     origin: new LatLng(-35.2801846, 149.1310324),
  //   },
  // },
];
export default defaultLocations;
