import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, Modal, NamedLink } from '../../components';
import { EditListingLocationForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

const { Money } = sdkTypes;

import css from './EditListingLocationPanel.module.css';
import {
  deliveryMethodOptions,
  shippingTypes,
} from '../../marketplace-custom-config';

class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData &&
      publicData.location &&
      publicData.location.address &&
      geolocation;
    const location =
      publicData && publicData.location ? publicData.location : {};
    const { address, building } = location;
    const deliveryMethod = publicData.deliveryMethod ?? [];
    // const shippingCharge =
    //   publicData && publicData.shippingCharge
    //     ? publicData.shippingCharge
    //     : null;
    // const shippingChargeAsMoney = shippingCharge
    //   ? new Money(shippingCharge.amount, shippingCharge.currency)
    //   : null;
    // const additionalItems =
    //   publicData && publicData?.additionalItems
    //     ? publicData?.additionalItems
    //     : null;
    // const additionalItemsAsMoney = additionalItems
    //   ? new Money(additionalItems.amount, additionalItems.currency)
    //   : null;
    const pickupAddress =
      publicData && publicData.pickupAddress ? publicData.pickupAddress : null;
    return {
      building,
      deliveryMethod,
      // shippingCharge: shippingChargeAsMoney,
      // additionalItems: additionalItemsAsMoney,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation },
          }
        : null,
      pickupAddress,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      myListingLink,
      currentUser,
      onManageDisableScrolling,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const geolocation =
      currentListing &&
      currentListing.attributes &&
      currentListing.attributes.geolocation;

    const isPublished =
      currentListing.id &&
      currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const { title } = currentListing.attributes;
    const isAutomaticShippingType =
      currentListing.attributes?.publicData?.shippingType ===
      deliveryMethodOptions.automaticShipping.value;

    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              {/* <FormattedMessage id="EditListingLocationPanel.listingTitle" /> */}
              {title}
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingLocationPanel.createListingTitle" />
    );

    const pickupAddress =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile.protectedData &&
      currentUser.attributes.profile.protectedData.pickupAddress &&
      currentUser.attributes.profile.protectedData.selectedPickupAddress &&
      Array.isArray(
        currentUser.attributes.profile.protectedData.pickupAddress
      ) &&
      currentUser.attributes.profile.protectedData.pickupAddress.find(
        address =>
          address.id ===
          currentUser.attributes.profile.protectedData.selectedPickupAddress
      );
    const hasValidPickupAddress = isAutomaticShippingType
      ? !!pickupAddress
      : true;

    return (
      <div className={classes}>
        <div className={css.titleDiv}>
          <h1 className={css.title}>{panelTitle}</h1>
          {myListingLink}
        </div>

        {/* {isPublished ? (
          <div className={css.loginTitle}>
            <span className={css.titleBlue}>Edit</span>
            <span className={css.titleBlack}> Delivery & Location</span>
          </div>
        ) : (
          <div className={css.loginTitle}>
            <span className={css.titleBlue}>Delivery </span>
            <span className={css.titleBlack}>& </span>
            <span className={css.titleBlue}>Location</span>
          </div>
        )} */}
        <span className={css.hDivider} />

        <EditListingLocationForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={values => {
            const {
              building = '',
              location,
              deliveryMethod,
              // shippingCharge,
              // additionalItems,
              // shippingAddress,
              pickupAddress,
            } = values;

            const {
              selectedPlace: { address, origin },
            } = location;
            const updateValues = {
              geolocation: origin,
              publicData: {
                location: { address, building },
                deliveryMethod,
                // shippingCharge: shippingCharge
                //   ? {
                //       amount: shippingCharge.amount,
                //       currency: config.currency,
                //     }
                //   : null,
                // additionalItems: additionalItems
                //   ? {
                //       amount: additionalItems?.amount,
                //       currency: config.currency,
                //     }
                //   : null,
                pickupAddress,
              },
            };
            this.setState({
              initialValues: {
                building,
                location: {
                  search: address,
                  selectedPlace: { address, origin },
                },
                deliveryMethod,
                // shippingCharge,
                // additionalItems,
                pickupAddress,
              },
            });
            onSubmit(updateValues);
          }}
          geolocation={geolocation}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          listing={listing}
          isPublished={isPublished}
        />

        <Modal
          id="EditlistingLocationPanel.nopickupAddress"
          onManageDisableScrolling={onManageDisableScrolling}
          onClose={() => {}}
          isOpen={!hasValidPickupAddress}
          usePortal={true}
          containerClassName={css.modalContainer}
          contentClassName={css.modalContent}
          scrollLayerClassName={css.modalScroll}
        >
          <div className={css.addressModalWrapper}>
            <h4>
              <FormattedMessage id="EditListingLocationPanel.noPickupAddress" />
            </h4>
            <NamedLink name="DeliveryAddressPage" className={css.link}>
              <FormattedMessage id="EditListingLocationPanel.addPickupAddress" />
            </NamedLink>
          </div>
        </Modal>
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationPanel;
