import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.module.css';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    currentUser,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  //favorites

  const [currentUserFavourites, setCurrentUserFavourites] = useState(null);

  const getCurrentUserFavourites = async userId => {
    const currentUserFavourites = await axios.post(
      `${apiBaseUrl()}/api/currentuser-favourite-listings`,
      { userId },
      {
        withCredentials: true,
      }
    );
    return currentUserFavourites;
  };

  useEffect(() => {
    !!currentUser &&
      getCurrentUserFavourites(currentUser.id.uuid)
        .then(res => {
          setCurrentUserFavourites(res.data);
        })
        .catch(err => console.error(err));
  }, [currentUser]);

  const toggleFavouritesButton = async (userId, listingId) => {
    const isFavouriteListing = await axios.post(
      `${apiBaseUrl()}/api/toggle-favourites`,
      {
        userId,
        listingId,
      },
      {
        withCredentials: true,
      }
    );
    const data = await getCurrentUserFavourites(currentUser.id.uuid);
    // .then(res => {
    setCurrentUserFavourites(data.data);
    // });
    return isFavouriteListing;
  };
  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {listings.map(l => {
          return (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              currentUser={currentUser}
              toggleFavouritesButton={toggleFavouritesButton}
              // isFavourite={currentUserFavourites?.includes(l.id.uuid)}
              isFavourite={
                _.isArray(currentUserFavourites) &&
                currentUserFavourites?.includes(l.id.uuid)
              }
              // toggleIsFavorite={toggleIsFavorite(l.id.uuid)}
            />
          );
        })}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
