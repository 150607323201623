import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCheckbox,
  NamedLink,
} from '../../components';
import { ReactComponent as View } from '../../assets/Eye.svg';
import { ReactComponent as Hide } from '../../assets/EyeClose.svg';
import { ReactComponent as BoxUnchecked } from '../../assets/BoxUnchecked.svg';
import { ReactComponent as BoxChecked } from '../../assets/BoxChecked.svg';

import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true);
  const [term1, setTerm1] = useState(false);
  const [term2, setTerm2] = useState(false);

  const toggleShow = () => {
    setIsPasswordHidden(isPasswordHidden => !isPasswordHidden);
  };
  const toggleConfirmPassword = () => {
    setIsConfirmPasswordHidden(
      isConfirmPasswordHidden => !isConfirmPasswordHidden
    );
  };
  const toggleTerm1 = () => {
    setTerm1(term1 => !term1);
  };
  const toggleTerm2 = () => {
    setTerm2(term2 => !term2);
  };
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
          touched,
          errors,
          values,
          active,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'SignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'SignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'SignupForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(
          passwordRequiredMessage
        );
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );
        const passwordNotMatchingMessage = intl.formatMessage({
          id: 'SignupForm.passwordNotMatching',
        });
        const passwordMatch = validators.hasEqual(
          passwordNotMatchingMessage,
          values.password
        );
        const confirmPasswordRequired = validators.requiredStringNoTrim(
          'Confirm password required.'
        );
        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'SignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'SignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);
        const confirmPasswordLabel = intl.formatMessage({
          id: 'SignupForm.confirmPasswordLabel',
        });
        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress || !term1 || !term2;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </span>
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <span className={css.titleDiv}>Sign up with email</span>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                // placeholder={emailPlaceholder}
                validate={validators.composeValidators(
                  emailRequired,
                  emailValid
                )}
                className={classNames(
                  css.inputs,
                  css.interLightSemiSmallBlack,
                  {
                    [css.invalidInputs]: touched.email && !!errors.email,
                    [css.fnNonEmptyInputs]:
                      !!values.email || active === 'email',
                  }
                )}
              />
              <div className={css.name}>
                <FieldTextInput
                  // className={css.firstNameRoot}
                  className={classNames(
                    css.inputs,
                    css.interLightSemiSmallBlack,
                    {
                      [css.invalidInputs]: touched.fname && !!errors.fname,
                      [css.fnNonEmptyInputs]:
                        !!values.fname || active === 'fname',
                    }
                  )}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  // placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  // className={css.lastNameRoot}
                  className={classNames(
                    css.inputs,
                    css.interLightSemiSmallBlack,
                    {
                      [css.invalidInputs]: touched.lname && !!errors.lname,
                      [css.fnNonEmptyInputs]:
                        !!values.lname || active === 'lname',
                    }
                  )}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  // placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>
              <div className={css.passwordDiv}>
                <FieldTextInput
                  // className={css.password}
                  className={classNames(
                    css.inputs,
                    css.interLightSemiSmallBlack,
                    {
                      [css.invalidInputs]:
                        touched.password && !!errors.password,
                      [css.fnNonEmptyInputs]:
                        !!values.password || active === 'password',
                    }
                  )}
                  type={!isPasswordHidden ? 'text' : 'password'}
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  // placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                />
                {isPasswordHidden ? (
                  <Hide className={css.hide} onClick={toggleShow} />
                ) : (
                  <View className={css.view} onClick={toggleShow} />
                )}
              </div>
              <div className={css.passwordDiv}>
                <FieldTextInput
                  className={classNames(css.inputs, {
                    [css.invalidInputs]:
                      touched.confirmPassword && !!errors.confirmPassword,
                    [css.fnNonEmptyInputs]:
                      !!values.confirmPassword || active === 'confirmPassword',
                  })}
                  type={!isConfirmPasswordHidden ? 'text' : 'password'}
                  id={'confirmPassword'}
                  name="confirmPassword"
                  // autoComplete="new-password"
                  label={confirmPasswordLabel}
                  // placeholder={passwordPlaceholder}
                  validate={validators.composeValidators(
                    confirmPasswordRequired,
                    passwordMinLength,
                    passwordMaxLength,
                    passwordMatch
                  )}
                />
                {isConfirmPasswordHidden ? (
                  <Hide className={css.hide} onClick={toggleConfirmPassword} />
                ) : (
                  <View className={css.view} onClick={toggleConfirmPassword} />
                )}
              </div>
            </div>
            <div className={css.termsText}>
              <label
                htmlFor="termsAndServices"
                onClick={toggleTerm1}
                className={css.termLabel}
              >
                {term1 ? (
                  <BoxChecked className={css.checkBox} />
                ) : (
                  <BoxUnchecked className={css.checkBox} />
                )}
                I accept the{' '}
                <NamedLink name="TermsOfServicePage" className={css.link}>
                  {' '}
                  Terms of Service{' '}
                </NamedLink>
                {/* <span className={css.termsAndServices} >
                  {' '}
                  Terms of Service{' '}
                </span>{' '} */}
                and Privacy Policy.
              </label>
              <FieldCheckbox
                id="termsAndServices"
                name="termsAndServices"
                value="term1"
              />

              <label
                htmlFor="rcvEmails"
                onClick={toggleTerm2}
                className={css.termLabel}
              >
                {term2 ? (
                  <BoxChecked className={css.checkBox} />
                ) : (
                  <BoxUnchecked className={css.checkBox} />
                )}
                I agree to receive occasional emails from the Siuhuu team and
                understand that I can change my mind at any time.
              </label>
              <FieldCheckbox id="rcvEmails" name="rcvEmails" value="term2" />
            </div>

            <div className={css.bottomWrapper}>
              {/* <p className={css.bottomWrapperText}>
                <span className={css.termsText}>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ termsLink }}
                  />
                </span>
              </p> */}
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
            <div className={css.bottomDiv}>
              <span>Already have an account?</span>
              <NamedLink name="LoginPage" className={css.tabLink}>
                Log in
              </NamedLink>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
