import classNames from 'classnames';
import { func, number, string, bool } from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import Select, { components } from 'react-select';

import * as validators from '../../util/validators';
import config from '../../config';
import getCountryCodes from '../../translations/countryCodes';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import FieldSelect from '../FieldSelect/FieldSelect';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import Form from '../Form/Form';
import css from './UserDeliveryAddress.module.css';
import { ensureCurrentUser } from '../../util/data';
import { ReactComponent as RadioChecked } from '../../assets/RadioChecked.svg';
import { ReactComponent as RadioUnchecked } from '../../assets/RadioUnchecked.svg';
import { ReactComponent as AddIcon } from '../../assets/Add.svg';
import { ReactComponent as CrossIcon } from '../../assets/Cross.svg';
import { maxDeliveryAddressAllowed } from '../../marketplace-custom-config';
import { connect } from 'react-redux';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { compose } from 'redux';
import { PrimaryButton } from '../Button/Button';
import { ReactComponent as ArrowDown } from '../../assets/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/ArrowUp.svg';
const countryCodes = getCountryCodes(config.locale);
const countryOPtions = countryCodes.map(country => ({
  key: country.code,
  value: country.code,
  label: country.name,
}));
import { ReactComponent as IconEdit } from '../../assets/Edit.svg';
import FieldPhoneNumberInput from '../FieldPhoneNumberInput/FieldPhoneNumberInput';

const addressNotAllowed = /(po box|locked bag|parcel locker)/gi;

const formatAddress = (
  selectedAddress,
  handleOnchange,
  handleOnEdit,
  isDisabled,
  address
) => {
  const { addressLine1, city, state, countryName, postal, id } = address;
  return (
    <label
      htmlFor={id}
      key={id}
      className={classNames(css.userAddress, {
        [css.disabledUserAddress]: isDisabled,
        [css.selectedAddress]: selectedAddress === id,
      })}
    >
      <div className={css.labelBtnDiv}>
        <input
          type="radio"
          name="deliveryAddress"
          value={id}
          checked={selectedAddress === id}
          id={id}
          onChange={handleOnchange.bind(null, address)}
          disabled={isDisabled}
        />
        {selectedAddress === id ? (
          <RadioChecked className={css.icon} />
        ) : (
          <RadioUnchecked className={css.icon} />
        )}
        <span className={css.text}>
          {addressLine1}, {city}, {state}, {countryName} - {postal}
        </span>
      </div>

      <button
        type="button"
        onClick={handleOnEdit.bind(null, address)}
        className={css.editBtn}
      >
        <IconEdit className={css.editPlanIcon} /> Edit
      </button>
    </label>
  );
};
export const DropdownIndicator = ({ children, ...props }) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? (
      <ArrowUp
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    ) : (
      <ArrowDown
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    )}
  </components.DropdownIndicator>
);

export const ClearIndicator = ({ children, ...props }) => (
  <components.ClearIndicator {...props}>
    <IoMdClose className={css.selectClearBtn} />
  </components.ClearIndicator>
);
export const Menu = ({ children, ...props }) => (
  <components.Menu
    {...props}
    className={classNames(css.menu, {
      // [css.noValueMenu]: props.selectProps.id === 'country' || !props.hasValue,
    })}
  >
    {children}
  </components.Menu>
);
export const getValueContainer = (
  placeholder,
  defaultPlaceholder,
  callback,
  className,
  valueSpanClassName
) => ({ children, ...props }) => {
  if (typeof callback === 'function') callback(props.selectProps.menuIsOpen);

  return (
    <components.ValueContainer {...props}>
      <div
        className={classNames(css.valueContainer, className, {
          [css.multiValueContainer]: props.isMulti,
        })}
      >
        <span
          className={classNames(css.placeholder, {
            [css.selectedPlaceholder]:
              props.hasValue || props.selectProps.menuIsOpen,
            // [css.selectedSCPlaceholder]:
            //   props.hasValue || props.selectProps.menuIsOpen || values.productSubCategory,

            [css.emptyPlaceholder]: props.isMulti && !props.hasValue,
            [valueSpanClassName]:
              !!valueSpanClassName &&
              (props.hasValue || props.selectProps.menuIsOpen),
          })}
        >
          {!props.hasValue ? defaultPlaceholder : placeholder}
        </span>
        <div
          className={classNames(css.value, {
            [css.nonEmptyValue]: props.hasValue,
            [css.blurredValue]: props.hasValue && props.selectProps.menuIsOpen,
          })}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};
const AddressForm = props => {
  const { handleSubmit, ...rest } = props;
  const formAPi = useRef(null);

  const onSubmit = values => {
    const { addressLine1 } = values;
    if (addressNotAllowed.test(addressLine1)) {
      if (formAPi.current) {
        formAPi.current.change(
          'addressNotAllowedMsg',
          'Sorry,  address containing PO boxes, locked bags, or parcel lockers are not permitted. '
        );
      }
      return;
    } else {
      if (formAPi.current) {
        formAPi.current.change('addressNotAllowedMsg', undefined);
      }
    }
    const { addressNotAllowedMsg, ...restValues } = values;
    handleSubmit(restValues);
  };
  return (
    <FinalForm
      onSubmit={onSubmit}
      {...rest}
      render={formRenderProps => {
        const {
          className,
          rootClassName,
          inProgress: submitInProgress,
          updateProfileError,
          formId,
          intl,
          invalid,
          handleSubmit,
          values,
          form,
          onClose,
          showCloseButton,
          touched,
          errors,
          initialValues,
          active,
        } = formRenderProps;
        formAPi.current = form;
        const { countryName, country, addressNotAllowedMsg } = values;
        const classes = classNames(css.formRoot || rootClassName, className);
        const submitDisabled =
          invalid ||
          submitInProgress ||
          !!updateProfileError ||
          !countryName ||
          !country;
        const handleCountryChange = country => {
          if (!country) return;
          form.change('countryName', country.label);
          form.change('country', country.value);
        };

        const addressLine1Placeholder = intl.formatMessage({
          id: 'UserDeliveryAddress.addressLine1Placeholder',
        });
        const addressLine1Required = validators.required(
          intl.formatMessage({
            id: 'UserDeliveryAddress.addressLine1Required',
          })
        );
        const nameRequired = validators.required(
          intl.formatMessage({
            id: 'UserDeliveryAddress.nameRequired',
          })
        );
        const phoneNumberRequired = validators.required(
          intl.formatMessage({
            id: 'UserDeliveryAddress.phoneNumberRequired',
          })
        );

        const postalCodePlaceholder = intl.formatMessage({
          id: 'UserDeliveryAddress.postalCodePlaceholder',
        });
        const postalCodeRequired = validators.required(
          intl.formatMessage({
            id: 'UserDeliveryAddress.postalCodeRequired',
          })
        );

        const cityPlaceholder = intl.formatMessage({
          id: 'UserDeliveryAddress.cityPlaceholder',
        });
        const cityRequired = validators.required(
          intl.formatMessage({
            id: 'UserDeliveryAddress.cityRequired',
          })
        );

        const statePlaceholder = intl.formatMessage({
          id: 'UserDeliveryAddress.statePlaceholder',
        });
        const stateRequired = validators.required(
          intl.formatMessage({
            id: 'UserDeliveryAddress.stateRequired',
          })
        );

        const countryPlaceholder = intl.formatMessage({
          id: 'UserDeliveryAddress.countryPlaceholder',
        });
        const countryRequired = validators.required(
          intl.formatMessage({
            id: 'UserDeliveryAddress.countryRequired',
          })
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {showCloseButton && (
              <button type="button" onClick={onClose} className={css.closeBtn}>
                <CrossIcon /> Cancel
              </button>
            )}
            <FieldTextInput
              id={`${formId}.name`}
              name="name"
              // className={css.field}
              className={classNames(css.inputs, {
                [css.invalidInputs]: touched.name && !!errors.name,
                [css.fnNonEmptyInputs]: !!values.name || active === 'name',
              })}
              type="text"
              label="Name*"
              // placeholder={addressLine1Placeholder}
              validate={nameRequired}
            />
            <FieldPhoneNumberInput
              // className={css.phone}
              className={classNames(css.inputs, {
                [css.invalidInputs]:
                  touched.phoneNumber && !!errors.phoneNumber,
                [css.fnNonEmptyInputs]:
                  !!values.phoneNumber || active === 'phoneNumber',
              })}
              name="phoneNumber"
              id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
              label="Phone"
              // placeholder={phonePlaceholder}
              // validate={phoneNumberRequired}
            />
            <FieldTextInput
              id={`${formId}.addressLine1`}
              name="addressLine1"
              // className={css.field}
              className={classNames(css.inputs, {
                [css.invalidInputs]:
                  touched.addressLine1 && !!errors.addressLine1,
                [css.fnNonEmptyInputs]:
                  !!values.addressLine1 || active === 'addressLine1',
              })}
              type="text"
              autoComplete="billing address-line1"
              label={addressLine1Placeholder}
              // placeholder={addressLine1Placeholder}
              validate={addressLine1Required}
            />

            <FieldTextInput
              id={`${formId}.city`}
              name="city"
              // className={css.field}
              className={classNames(css.inputs, {
                [css.invalidInputs]: touched.city && !!errors.city,
                [css.fnNonEmptyInputs]: !!values.city || active === 'city',
              })}
              type="text"
              autoComplete="billing address-level2"
              label={cityPlaceholder}
              validate={cityRequired}
            />
            <FieldTextInput
              id={`${formId}.state`}
              name="state"
              // className={css.field}
              className={classNames(css.inputs, {
                [css.invalidInputs]: touched.state && !!errors.state,
                [css.fnNonEmptyInputs]: !!values.state || active === 'state',
              })}
              type="text"
              autoComplete="billing address-level1"
              label={statePlaceholder}
              validate={stateRequired}
            />
            <Select
              className={classNames(
                css.categoryReactSelect,
                css.cateSelect,
                {}
              )}
              // menuIsOpen={true}
              isSearchable={true}
              name="country"
              id="country"
              options={countryOPtions}
              placeholder={null}
              hideSelectedOptions={true}
              components={{
                DropdownIndicator,
                Menu,
                ValueContainer: getValueContainer(
                  countryPlaceholder,
                  countryPlaceholder,
                  () => {}
                  // css.catSelectedPlaceholder
                ),
              }}
              validate={countryRequired}
              onChange={handleCountryChange}
              value={{ key: country, value: country, label: countryName }}
            />
            {/* <FieldSelect
              id={`${formId}.country`}
              name="country"
              className={css.field}
              validate={countryRequired}
              onChange={handleCountryChange}
            >
              <option hidden value="">
                {countryPlaceholder}
              </option>
              {countryCodes.map(country => {
                return (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                );
              })}
            </FieldSelect> */}
            <FieldTextInput
              id={`${formId}.postalCode`}
              name="postal"
              // className={css.field}
              className={classNames(css.inputs, {
                [css.invalidInputs]: touched.postal && !!errors.postal,
                [css.fnNonEmptyInputs]: !!values.postal || active === 'postal',
              })}
              type="text"
              autoComplete="billing postal-code"
              // placeholder={postalCodePlaceholder}
              validate={postalCodeRequired}
              label={postalCodePlaceholder}
            />
            <div className={css.submitContainer}>
              {!!updateProfileError && (
                <p className={css.error}>
                  {intl.formatMessage({
                    id: 'UserDeliveryAddress.AddressAddFailed',
                  })}
                </p>
              )}
              {!!addressNotAllowedMsg ? (
                <p className={css.error}>{addressNotAllowedMsg}</p>
              ) : null}
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                {intl.formatMessage({ id: 'UserDeliveryAddress.saveAddress' })}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

function UserDeliveryAddress(props) {
  const {
    currentUser,
    intl,
    rootClassName,
    addressStep: initAddressStep,
    updateInProgress,
    updateProfileError,
    onUpdateProfile,
    onContinue,
    headingId,
    continueInProgress,
    showSubHeading = true,
    fromAccountSettings,
    updateKeyName,
    selectedAddressKeyName,
  } = props;
  const [addressStep, setAddressStep] = useState(initAddressStep);
  const classess = classNames(css.root, rootClassName);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const displayName = ensuredCurrentUser?.attributes?.profile?.displayName;
  const phoneNumber =
    ensuredCurrentUser?.attributes?.profile?.protectedData?.phoneNumber;

  const initialAddressValues = useRef({
    name: displayName,
    phoneNumber: phoneNumber,
    country: '',
    countryName: '',
  });

  const {
    attributes: { profile },
  } = ensuredCurrentUser;
  const { protectedData = {} } = profile;
  const savedAddress =
    (typeof updateKeyName === 'string' && !!updateKeyName
      ? protectedData[updateKeyName]
      : protectedData.savedAddress) || [];

  const selectedAddress =
    (typeof selectedAddressKeyName === 'string' && !!selectedAddressKeyName
      ? protectedData[selectedAddressKeyName]
      : protectedData.selectedAddress) || 0;

  const getSelectedDeliveryAddress = savedAddress?.find(
    address => address.id === selectedAddress
  );

  // const submitDisable =
  //   getSelectedDeliveryAddress.phoneNumber !== undefined ? false : true;

  const handleAddressChange = (address, event) => {
    const selectedAddressUpdateKeyName =
      typeof selectedAddressKeyName === 'string' && !!selectedAddressKeyName
        ? selectedAddressKeyName
        : 'selectedAddress';
    const payload = {
      protectedData: { [selectedAddressUpdateKeyName]: address.id },
    };
    onUpdateProfile(payload);
  };
  const handleAddAddress = e => {
    initialAddressValues.current = {
      name: displayName,
      phoneNumber: phoneNumber,
    };
    setAddressStep(1);
  };
  const handleAddressEdit = (address, event) => {
    initialAddressValues.current = { ...address };
    setAddressStep(1);
  };
  const handleFormSubmit = values => {
    const isOldAddress = typeof values.id !== 'undefined';
    const newAddress = isOldAddress
      ? { ...values }
      : { ...values, id: Date.now() };

    const updatedSavedAddress =
      addressStep === 1 && isOldAddress
        ? savedAddress.map(address => {
            const { id } = address;
            if (id === newAddress.id) {
              return newAddress;
            }
            return address;
          })
        : [...savedAddress, newAddress];

    const keyName =
      typeof updateKeyName === 'string' && !!updateKeyName
        ? updateKeyName
        : 'savedAddress';

    const selectedAddressUpdateKeyName =
      typeof selectedAddressKeyName === 'string' && !!selectedAddressKeyName
        ? selectedAddressKeyName
        : 'selectedAddress';

    const payload = {
      protectedData: {
        [keyName]: updatedSavedAddress,
        ...(!savedAddress.length && {
          [selectedAddressUpdateKeyName]: newAddress.id,
        }),
      },
    };
    onUpdateProfile(payload).then(_ => {
      if (!updateProfileError) {
        setAddressStep(0);
      }
    });
  };

  useEffect(() => {
    setAddressStep(savedAddress.length > 0 ? 0 : 1);
  }, [savedAddress.length]);

  if (!ensuredCurrentUser.id) return null;
  return (
    <div className={classess}>
      {fromAccountSettings ? (
        <h3 className={css.title}>
          {intl.formatMessage({
            id: headingId ?? 'UserDeliveryAddress.heading',
          })}
        </h3>
      ) : (
        <h3 className={css.heading}>
          {intl.formatMessage({
            id: headingId ?? 'UserDeliveryAddress.heading',
          })}
        </h3>
      )}

      {showSubHeading && (
        <p className={css.subHeading}>
          {intl.formatMessage({ id: 'UserDeliveryAddress.subHeading' })}
        </p>
      )}

      <div className={css.addressWrapper}>
        {addressStep === 0 ? (
          <form method="/" onSubmit={e => e.preventDefault()}>
            {savedAddress.map(
              formatAddress.bind(
                null,
                selectedAddress,
                handleAddressChange,
                handleAddressEdit,
                updateInProgress
              )
            )}
            {savedAddress.length < maxDeliveryAddressAllowed && (
              <button
                type="button"
                onClick={handleAddAddress}
                className={css.addressAddBtn}
              >
                <AddIcon className={css.icon} />{' '}
                {intl.formatMessage({
                  id: 'UserDeliveryAddress.addNewAddress',
                })}
              </button>
            )}
            {/* {submitDisable && (
              <div className={css.warningMsg}>
                Please add required fields in selected address
              </div>
            )} */}
            {typeof onContinue === 'function' && (
              <PrimaryButton
                type="button"
                onClick={onContinue}
                className={css.continueBtn}
                disabled={continueInProgress || updateInProgress}
              >
                {intl.formatMessage({
                  id: 'UserDeliveryAddress.continue',
                })}
              </PrimaryButton>
            )}
          </form>
        ) : (
          <AddressForm
            handleSubmit={handleFormSubmit}
            initialValues={{ ...initialAddressValues.current }}
            inProgress={updateInProgress}
            updateProfileError={updateProfileError}
            intl={intl}
            showCloseButton={savedAddress.length > 0}
            onClose={() => setAddressStep(0)}
            formId="userAddress"
          />
        )}
      </div>
    </div>
  );
}

UserDeliveryAddress.defaultProps = {
  addressStep: 0,
  onContinue: null,
  rootClassName: null,
  updateKeyName: null,
  selectedAddressKeyName: null,
  continueInProgress: false,
};

UserDeliveryAddress.propTypes = {
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  rootClassName: string,
  addressStep: number.isRequired,
  onContinue: func,
  continueInProgress: bool.isRequired,
  updateKeyName: string,
  selectedAddressKeyName: string,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { updateInProgress, updateProfileError } = state.ProfileSettingsPage;
  return {
    currentUser,
    updateInProgress,
    updateProfileError,
  };
};
const mapDispatchToProps = dispatch => ({
  onUpdateProfile: payload => dispatch(updateProfile(payload)),
});

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(UserDeliveryAddress);
