import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconLogo from './IconLogo';
import css from './Logo.module.css';
import config from '../../config';
import LogoImage from './SiuhuuLogoNew.png';
const Logo = props => {
  const { className, format, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);

  // If you want to use image instead of svg as a logo you can use the following code.
  // Also, remember to import the image as LogoImage here.

  return (
    <>
      <img
        className={css.logoImg}
        src={LogoImage}
        alt={config.siteTitle}
        {...rest}
      />
      {/* <IconLogo
        className={format === 'desktop' ? className : mobileClasses}
        format={format}
        {...rest}
      /> */}
    </>
  );
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
