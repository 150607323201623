import classNames from 'classnames';
import { number, string } from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from '../my-lottie.json';
import css from './CartLoader.module.css';

function CartLoader(props) {
  const { width, height, className } = props;
  const classes = classNames(css.loadingIcon, className);
  return (
    <Lottie
      loop
      className={classes}
      animationData={lottieJson}
      play
      style={{ width, height }}
    />
  );
}

CartLoader.defaultProps = {
  width: 200,
  height: 200,
  className: null,
};

CartLoader.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  className: string,
};

export default CartLoader;
