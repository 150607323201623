import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import {
  isTransactionsTransitionAlreadyReviewed,
  storableError,
} from '../../util/errors';
import { propTypes } from '../../util/types';
import { ReactComponent as UplaodImg } from '../../assets/Upload.svg';

import {
  composeValidators,
  maxLength,
  minLength,
  required,
} from '../../util/validators';
import {
  FieldReviewRating,
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
} from '../../components';

import css from './ReviewForm.module.css';
import {
  DISPUTE_DESCRIPTION_MAX_LENGTH,
  DISPUTE_DESCRIPTION_MIN_LENGTH,
} from '../../marketplace-custom-config';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';

const CloseBtnIcon = () => (
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-821.000000, -311.000000)">
        <g transform="translate(809.000000, 299.000000)">
          <path
            d="M21.5833333,16.5833333 L17.4166667,16.5833333 L17.4166667,12.4170833 C17.4166667,12.1866667 17.2391667,12 17.00875,12 C16.77875,12 16.5920833,12.18625 16.5920833,12.41625 L16.5883333,16.5833333 L12.4166667,16.5833333 C12.18625,16.5833333 12,16.7695833 12,17 C12,17.23 12.18625,17.4166667 12.4166667,17.4166667 L16.5875,17.4166667 L16.5833333,21.5829167 C16.5829167,21.8129167 16.7691667,21.9995833 16.9991667,22 L16.9995833,22 C17.2295833,22 17.41625,21.81375 17.4166667,21.58375 L17.4166667,17.4166667 L21.5833333,17.4166667 C21.8133333,17.4166667 22,17.23 22,17 C22,16.7695833 21.8133333,16.5833333 21.5833333,16.5833333"
            transform="translate(17.000000, 17.000000) rotate(-45.000000) translate(-17.000000, -17.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
);

const ReviewFormComponent = props => {
  const { onSubmit, sendReviewInProgress, sendReviewError, ...rest } = props;
  const [imgUploadStatus, setImgUploadStatus] = useState({
    loading: false,
    error: null,
  });

  const handleSubmit = values => {
    const { images = [], ...restValues } = values;
    const formData = new FormData();
    if (images.length > 0) {
      for (let img of images) {
        formData.append('files', img);
      }
    }
    setImgUploadStatus({ loading: true, error: null });
    const imgUpload =
      images.length > 0
        ? axios.post(`${apiBaseUrl()}/api/listings/reviews/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        : Promise.resolve({ data: [] });
    imgUpload
      .then(resp => {
        const uploadedUrl = resp.data;
        const reviewContent = restValues.reviewContent;
        restValues.reviewContent = JSON.stringify({
          content: reviewContent,
          urls: uploadedUrl,
        });
        onSubmit(restValues);
        setImgUploadStatus({ loading: false, error: null });
      })
      .catch(err => {
        setImgUploadStatus({ loading: false, error: storableError(err) });
      });
  };

  return (
    <FinalForm
      {...rest}
      onSubmit={handleSubmit}
      sendReviewInProgress={sendReviewInProgress || imgUploadStatus.loading}
      sendReviewError={sendReviewError || imgUploadStatus.error}
      render={fieldRenderProps => {
        const {
          className,
          rootClassName,
          disabled,
          handleSubmit,
          intl,
          formId,
          invalid,
          reviewSent,
          sendReviewError,
          sendReviewInProgress,
          isReviewMode,
          touched,
          errors,
          transaction,
          values,
          form,
          isReviewByCustomer,
        } = fieldRenderProps;
        const { ratingFor, disputeFor, images = [] } = values;

        const {
          attributes: { protectedData },
        } = transaction;
        const {
          lineItemLabel = {},
          reviewedItemLabel = {},
          disputedItemLabel = {},
        } = protectedData;

        const remainingItemLabel =
          isReviewByCustomer && isReviewMode
            ? Object.keys(lineItemLabel).reduce((pre, curnt) => {
                if (!reviewedItemLabel.hasOwnProperty(curnt)) {
                  pre.push({ value: curnt, label: lineItemLabel[curnt].label });
                }
                return pre;
              }, [])
            : [];

        const remainingDisputedItemLabel =
          isReviewByCustomer && !isReviewMode
            ? Object.keys(lineItemLabel).reduce((pre, curnt) => {
                if (!disputedItemLabel.hasOwnProperty(curnt)) {
                  pre.push({ value: curnt, label: lineItemLabel[curnt].label });
                }
                return pre;
              }, [])
            : [];

        if (!ratingFor && isReviewMode && remainingItemLabel.length > 0) {
          form.change('ratingFor', remainingItemLabel[0].value);
        }

        if (
          !disputeFor &&
          !isReviewMode &&
          remainingDisputedItemLabel.length > 0
        ) {
          form.change('disputeFor', remainingDisputedItemLabel[0].value);
        }

        const checkAndCallHandleSubmit = e => {
          e.preventDefault();
          e.stopPropagation();
          const { ratingFor, disputeFor } = values;
          const value = isReviewMode ? ratingFor : disputeFor;
          const isValidLineItemLabel = isReviewByCustomer
            ? (isReviewMode
                ? remainingItemLabel
                : remainingDisputedItemLabel
              ).find(lineItem => lineItem.value === value)
            : true;
          if (!isValidLineItemLabel) return;
          handleSubmit(values);
        };

        const listingSelectComp = isReviewByCustomer ? (
          <FieldSelect
            id={isReviewMode ? 'ratingFor' : 'disputeFor'}
            name={isReviewMode ? 'ratingFor' : 'disputeFor'}
            className={css.ratingSelect}
            disabled={
              isReviewMode
                ? remainingItemLabel.length < 2
                : remainingDisputedItemLabel.length < 2
            }
          >
            {(isReviewMode
              ? remainingItemLabel
              : remainingDisputedItemLabel
            ).map(lineItem => {
              return (
                <option value={lineItem.value} key={lineItem.value}>
                  {lineItem.label}
                </option>
              );
            })}
          </FieldSelect>
        ) : null;

        const reviewRating = (
          <div className={css.reviewWrapper}>
            <span className={css.ratingLabel}>
              {intl.formatMessage({
                id: isReviewByCustomer
                  ? 'ReviewForm.reviewRatingLabel'
                  : 'ReviewForm.providerReviewRatingLabel',
              })}
            </span>
            {isReviewByCustomer ? listingSelectComp : null}
          </div>
        );

        const disputeContent =
          !isReviewMode && isReviewByCustomer ? (
            <div className={css.disputeContentWrapper}>
              <h3 className={classNames(css.ratingLabel, css.disputeLabel)}>
                {intl.formatMessage({ id: 'ReviewForm.dipsuteLabel' })}
              </h3>
              {listingSelectComp}
            </div>
          ) : null;

        // const reviewRating = ;
        const reviewRatingRequiredMessage = intl.formatMessage({
          id: 'ReviewForm.reviewRatingRequired',
        });

        const reviewContent = intl.formatMessage({
          id: isReviewMode
            ? 'ReviewForm.reviewContentLabel'
            : 'ReviewForm.disputeContentLabel',
        });
        const reviewContentPlaceholderMessage = intl.formatMessage({
          id: 'ReviewForm.reviewContentPlaceholder',
        });
        const reviewContentRequiredMessage = intl.formatMessage({
          id: isReviewMode
            ? 'ReviewForm.reviewContentRequired'
            : 'ReviewForm.disputeContentRequired',
        });

        const errorMessage = isTransactionsTransitionAlreadyReviewed(
          sendReviewError
        ) ? (
          <p className={css.error}>
            <FormattedMessage id="ReviewForm.reviewSubmitAlreadySent" />
          </p>
        ) : (
          <p className={css.error}>
            <FormattedMessage
              id={
                isReviewMode
                  ? 'ReviewForm.reviewSubmitFailed'
                  : 'ReviewForm.disputeSubmitFailed'
              }
            />
          </p>
        );
        const errorArea = sendReviewError ? (
          errorMessage
        ) : (
          <p className={css.errorPlaceholder} />
        );

        const reviewSubmitMessage = intl.formatMessage({
          id: isReviewMode
            ? 'ReviewForm.reviewSubmit'
            : 'ReviewForm.disputeSubmit',
        });

        const maxLength200Message = intl.formatMessage(
          { id: 'ReviewForm.disputeMaxContentLength' },
          { maxLength: DISPUTE_DESCRIPTION_MAX_LENGTH }
        );

        const minLength50Message = intl.formatMessage(
          { id: 'ReviewForm.disputeMinContentLength' },
          { minLength: DISPUTE_DESCRIPTION_MIN_LENGTH }
        );

        const maxLength200Validator = maxLength(
          maxLength200Message,
          DISPUTE_DESCRIPTION_MAX_LENGTH
        );
        const minLength50Validator = minLength(
          minLength50Message,
          DISPUTE_DESCRIPTION_MIN_LENGTH
        );

        const descriptionValidator = isReviewMode
          ? required(reviewContentRequiredMessage)
          : composeValidators(
              required(reviewContentRequiredMessage),
              maxLength200Validator,
              minLength50Validator
            );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = sendReviewInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const selectFile = e => {
          const files = e.target && e.target.files && e.target.files;
          if (files && files.length) {
            const filesArr = Array.from(files);
            const imageFileArr = filesArr
              .filter(file => /image\/.+/.test(file.type))
              .map((file, indx) => {
                const [_, imgExt] = file.type.split('/');
                const regEx = new RegExp(`.${imgExt}`, 'gi');
                const imgName = file.name.replace(regEx, '');
                const newName = `${imgName}-${Date.now()}${indx}.${imgExt}`;
                return new File([file], newName, {
                  type: file.type,
                  lastModified: file.lastModified,
                  lastModifiedDate: file.lastModifiedDate,
                });
              });
            form.change('images', [...images, ...imageFileArr]);
            e.target.value = '';
            if (!/safari/i.test(navigator.userAgent)) {
              e.target.type = '';
              e.target.type = 'file';
            }
          }
        };

        const handleRemoveFile = index => {
          const filteredImages = (images || []).filter(
            (_, indx) => indx !== index
          );
          form.change('images', filteredImages);
        };

        const imgUploadLabel = isReviewMode ? (
          <div className={css.imgUploadWrapper}>
            <label htmlFor={formId ? `${formId}.reviewImg` : 'reviewImg'}>
              <span className={css.innerLabel}>
                <UplaodImg />
                {intl.formatMessage({ id: 'ReviewForm.chooseImage' })}
              </span>
            </label>
            <input
              name="file"
              type="file"
              id={formId ? `${formId}.reviewImg` : 'reviewImg'}
              accept="image/*"
              onChange={selectFile}
              multiple
            />
            {images.map((file, i) => (
              <div key={i} className={css.previewImageWrapper}>
                <img
                  src={URL.createObjectURL(file)}
                  className={css.previewImage}
                />
                <button
                  onClick={() => handleRemoveFile(i)}
                  className={css.removeImage}
                >
                  <CloseBtnIcon />
                </button>
              </div>
            ))}
          </div>
        ) : null;

        return (
          <Form className={classes} onSubmit={checkAndCallHandleSubmit}>
            {isReviewMode ? (
              <FieldReviewRating
                className={css.reviewRating}
                id={formId ? `${formId}.starRating` : 'starRating'}
                name="reviewRating"
                label={reviewRating}
                imgUploadLabel={imgUploadLabel}
                validate={required(reviewRatingRequiredMessage)}
              />
            ) : (
              disputeContent
            )}

            <FieldTextInput
              // className={css.reviewContent}
              className={classNames(css.textInput, {
                [css.invalidInputs]:
                  touched.reviewContent && !!errors.reviewContent,
              })}
              type="textarea"
              id={formId ? `${formId}.reviewContent` : 'reviewContent'}
              name="reviewContent"
              label={reviewContent}
              // placeholder={reviewContentPlaceholderMessage}
              validate={descriptionValidator}
            />

            {/* <p>File Upload</p> */}

            {/* <button type="button">Upload</button> */}
            {errorArea}
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={reviewSent}
            >
              {reviewSubmitMessage}
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

ReviewFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  sendReviewError: null,
  isReviewMode: true,
  isReviewByCustomer: false,
};

const { bool, func, string } = PropTypes;

ReviewFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  reviewSent: bool.isRequired,
  sendReviewError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  isReviewMode: bool.isRequired,
  transaction: propTypes.transaction.isRequired,
  isReviewByCustomer: bool.isRequired,
};

const ReviewForm = compose(injectIntl)(ReviewFormComponent);
ReviewForm.displayName = 'ReviewForm';

export default ReviewForm;
