/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { bool, oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  CART_LINE_ITEMS,
  BOOKING_STATE_PENDING,
} from '../../util/types';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';

import css from './OrderBreakdown.module.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import LineItemUnitPriceMaybe from './LineItemUnitPriceMaybe';
import { dateField } from '../../marketplace-custom-config';
import { types as sdkTypes } from '../../util/sdkLoader';
import moment from 'moment';
import {
  calculateQuantityFromDays,
  getCorrectBookingEnd,
} from '../../util/dates';
import { ensureTransaction } from '../../util/data';

const { UUID } = sdkTypes;

export const OrderBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    transaction: tx,
    intl,
    selectedCurrencyExchangeCode,
    selectedCurrency,
    isProductType,
    timeZone,
    dateType,
    onCreateLabel,
    onAddDeliveryDate,
    labelCreateInProgress,
    deliveryDateAddInProgress,
    isPlatformShippingDetailFilled,
    isManualShippingDetailFilled,
    showHeading,
    isShippingLabelCreated,
  } = props;
  const transaction = ensureTransaction(tx);

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const lineItems = transaction.attributes.lineItems || [];
  const {
    attributes: { protectedData: { bookingDetails = [] } = {} },
  } = transaction;

  const hasCommissionLineItem = lineItems.find(item => {
    const hasCustomerCommission =
      isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission =
      isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const actLineItemsCount = lineItems.filter(
    item => !CART_LINE_ITEMS.includes(item.code)
  ).length;

  const firstBookingDetails = bookingDetails[0];
  const isValidDate =
    !!firstBookingDetails &&
    firstBookingDetails.hasOwnProperty('unitType') &&
    !!firstBookingDetails.bookingStart &&
    !!firstBookingDetails.bookingEnd &&
    dateField.includes(firstBookingDetails.unitType);

  const booking = isValidDate
    ? {
        id: new UUID(firstBookingDetails.listingId),
        attributes: {
          start: new Date(firstBookingDetails.bookingStart),
          displayStart: new Date(firstBookingDetails.bookingStart),
          end: getCorrectBookingEnd(firstBookingDetails),
          displayEnd: getCorrectBookingEnd(firstBookingDetails),
          state: BOOKING_STATE_PENDING,
        },
        type: 'booking',
      }
    : null;

  const classes = classNames(rootClassName || css.root, className);
  const isBooking = !!booking && typeof booking === 'object';
  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit (Not used with FTW-product).
   *
   * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
   * additional items into it).
   *
   * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   * This line item is not used by default in the OrderBreakdown.
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  return (
    <div className={classes}>
      {showHeading ? (
        <p className={css.titleHeading}>
          <FormattedMessage
            id={
              isBooking
                ? 'OrderBreakdown.rentalTitle'
                : 'OrderBreakdown.orderTitle'
            }
          />
        </p>
      ) : null}

      {/* <LineItemBookingPeriod
        booking={booking}
        unitType={unitType}
        dateType={dateType}
      /> */}
      {isBooking && (
        <LineItemBookingPeriod
          booking={booking}
          unitType={unitType}
          dateType={dateType}
          timeZone={timeZone}
          isProductType={isProductType}
        />
      )}

      {/* {actLineItemsCount > 0 && actLineItemsCount <= 1 && (
        <LineItemUnitPriceMaybe
          transaction={transaction}
          unitType={unitType}
          intl={intl}
          selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
          selectedCurrency={selectedCurrency}
          isProductType={isProductType}
          lineItem={lineItems[0]}
        />
      )} */}
      {/*<LineItemBasePriceMaybe
          lineItems={lineItems}
          unitType={unitType}
          intl={intl}
          transaction={transaction}
          selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
          selectedCurrency={selectedCurrency}
          isProductType={isProductType}
      />*/}

      <LineItemUnknownItemsMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        intl={intl}
        transaction={transaction}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        isProductType={isProductType}
        onCreateLabel={onCreateLabel}
        onAddDeliveryDate={onAddDeliveryDate}
        labelCreateInProgress={labelCreateInProgress}
        deliveryDateAddInProgress={deliveryDateAddInProgress}
        isPlatformShippingDetailFilled={isPlatformShippingDetailFilled}
        isManualShippingDetailFilled={isManualShippingDetailFilled}
        isShippingLabelCreated={isShippingLabelCreated}
      />

      <LineItemSubTotalMaybe
        lineItems={lineItems}
        unitType={unitType}
        userRole={userRole}
        intl={intl}
        transaction={transaction}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        isProductType={isProductType}
      />
      <LineItemShippingFeeMaybe
        isProvider={isProvider}
        lineItems={lineItems}
        intl={intl}
        transaction={transaction}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        isProductType={isProductType}
      />
      <LineItemPickupFeeMaybe
        lineItems={lineItems}
        intl={intl}
        transaction={transaction}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        isProductType={isProductType}
      />
      <LineItemRefundMaybe
        lineItems={lineItems}
        intl={intl}
        transaction={transaction}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        isProductType={isProductType}
      />

      <LineItemCustomerCommissionMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        intl={intl}
        transaction={transaction}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        isProductType={isProductType}
      />
      <LineItemCustomerCommissionRefundMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        intl={intl}
        transaction={transaction}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        isProductType={isProductType}
      />

      <LineItemProviderCommissionMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        intl={intl}
        transaction={transaction}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        isProductType={isProductType}
      />
      <LineItemProviderCommissionRefundMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        intl={intl}
        transaction={transaction}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        isProductType={isProductType}
      />

      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        isProductType={isProductType}
      />

      {/* {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
        </span>
      ) : null} */}
    </div>
  );
};

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
  isProductType: true,
};

OrderBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  // unitType: propTypes.lineItemUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
  isProductType: bool.isRequired,
};

const mapStateToProps = state => {
  const {
    selectedCurrencyExchangeCode,
    selectedCurrency,
  } = state.currencyExchangeCode;
  return {
    selectedCurrency,
    selectedCurrencyExchangeCode,
  };
};

const OrderBreakdown = compose(
  injectIntl,
  connect(mapStateToProps)
)(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;
